<!--====== HEADER PART START ======-->
<header class="header-area-2 header-area-3">

  <div class="header-nav">
    <div class="navigation">
      <nav class="navbar navbar-expand-lg navbar-light ">
        <a class="navbar-brand" routerLink='/'><img src="assets/images/logo-3.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <!--<li class="nav-item active">
              <a class="nav-link" href="#">Inicio</a>
              <ul class="sub-menu">
              <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
              <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
              <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
              <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
              </ul>  sub menu
            </li>-->
            <li class="nav-item">
              <a class="nav-link" routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nosotros</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Clientes</a>
              <ul class="sub-menu">
                <li><a routerLink='/case-study' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">case study</a></li>
                <li><a routerLink='/case-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Details</a></li>
                <li><a routerLink='/coming-soon' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">coming soon</a></li>
                <li><a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">faq</a></li>
                <li><a routerLink='/pricing' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">pricing</a></li>
                <li><a routerLink='/shop' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop</a></li>
                <li><a routerLink='/shop-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop Details</a></li>
                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>
              </ul> <!-- sub menu -->
            </li>

            <li class="nav-item dropdown has-megamenu">
              <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Portafolio y Servicios</a>
              <div class="back-area dropdown-menu megamenu" role="menu">
                <div class="row">
                  <div class="col-md-3">
                    <div class="col-megamenu">
                      <h6 class="title">Portfolio Grid</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                  <div class="col-md-3">
                    <div class="col-megamenu">
                      <h6 class="title">Portfolio Fluid</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                  <div class="col-md-3">
                    <div class="col-megamenu">
                      <h6 class="title">Portfolio Masonry</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div>
                  <div class="col-md-3">
                    <div class="col-megamenu">
                      <h6 class="title">Project details</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                        <li><a class="megamenu-item" href="#">Style 1 (2 columns)</a></li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                </div><!-- end row -->
              </div> <!-- dropdown-mega-menu.// -->
            </li>
            <!--
            <li class="nav-item dropdown submenu mega_menu">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Portfolio
                      </a>
            -->
            <!--<li class="nav-item dropdown submenu mega_menu">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Portafolio de Servicios</a>
              <div class="mega_menu_inner">
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Portfolio Grid</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-grid-2col.html" class="nav-link">Style 1 (2 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-3columns.html" class="nav-link">Style 1 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-4columns.html" class="nav-link">Style 1 (4 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-2col-2.html" class="nav-link">Style 2 (2 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-2col-3.html" class="nav-link">Style 2 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-2col-4.html" class="nav-link">Style 2 (4 columns)</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Portfolio Fluid</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-fullwidth-3columns.html" class="nav-link">Style 1 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-fullwidth-4columns.html" class="nav-link">Style 1 (4 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-fullwidth1.html" class="nav-link">Style 2 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-fullwidth2.html" class="nav-link">Style 2 (4 columns)</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Portfolio Masonry</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-mas-2col.html" class="nav-link">Style 1 (2 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-3col.html" class="nav-link">Style 1 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-4col.html" class="nav-link">Style 1 (4 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-3col-2.html" class="nav-link">Style 2 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-4col-2.html" class="nav-link">Style 2 (4 columns)</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Project details</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-details-1.html" class="nav-link">Style 1</a></li>
                      <li class="nav-item"><a href="portfolio-details-2.html" class="nav-link">Style 2</a></li>
                      <li class="nav-item"><a href="portfolio-details-3.html" class="nav-link">Style 3</a></li>
                    </ul>
                  </li>
                </ul>
              </div>-->
            <!--<ul class="sub-menu">
                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portafolio de Servicios</a></li>
                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
            </ul>  -->
            <!--</li>-->

            <li class="nav-item">
              <a class="nav-link" href="#">Blog</a>
              <ul class="sub-menu">
                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
              </ul>  <!--sub menu -->
            </li>
            <!--<li class="nav-item">
                <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
            </li>-->
          </ul>
        </div> <!-- navbar collapse
        <div class="navbar-btn mr-100">
          <a class="main-btn" routerLink='/contact-us'>Solicita un demo</a>
        </div>-->

        <!--<div class="country-flag d-none d-lg-block">
          <a href="#"><img src="assets/images/flag-1.jpg" alt="flag"></a>
        </div><div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">Home</a>
              <ul class="sub-menu">
                <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
                <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
                <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
                <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Services</a>
              <ul class="sub-menu">
                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Pages</a>
              <ul class="sub-menu">
                <li><a routerLink='/case-study' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">case study</a></li>
                <li><a routerLink='/case-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Details</a></li>
                <li><a routerLink='/coming-soon' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">coming soon</a></li>
                <li><a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">faq</a></li>
                <li><a routerLink='/pricing' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">pricing</a></li>
                <li><a routerLink='/shop' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop</a></li>
                <li><a routerLink='/shop-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop Details</a></li>
                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Blog</a>
              <ul class="sub-menu">
                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
            </li>
          </ul>
        </div>
        <div class="navbar-btn mr-100">
          <a class="main-btn" routerLink='/contact-us'>Get A Quote</a>
        </div>
        <div class="country-flag d-none d-lg-block">
          <a href="#"><img src="assets/images/flag-1.jpg" alt="flag"></a>
        </div>-->
      </nav>
    </div>
  </div>
  <!--<div class="header-nav">
        <div class="navigation">
            <nav class="navbar navbar-expand-lg navbar-light ">
                <a class="navbar-brand" routerLink='/'><img src="assets/images/logo-3.png" alt=""></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Home</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
                                <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
                                <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
                                <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Services</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Pages</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/case-study' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">case study</a></li>
                                <li><a routerLink='/case-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Details</a></li>
                                <li><a routerLink='/coming-soon' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">coming soon</a></li>
                                <li><a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">faq</a></li>
                                <li><a routerLink='/pricing' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">pricing</a></li>
                                <li><a routerLink='/shop' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop</a></li>
                                <li><a routerLink='/shop-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop Details</a></li>
                                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Blog</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>
                </div>
                <div class="navbar-btn mr-100">
                    <a class="main-btn" routerLink='/contact-us'>Get A Quote</a>
                </div>
                <div class="country-flag d-none d-lg-block">
                    <a href="#"><img src="assets/images/flag-1.jpg" alt="flag"></a>
                </div>
            </nav>
        </div>
    </div>-->
</header>
<!--====== HEADER PART ENDS ======-->
<!--====== HEADER PART START ======-->
<!--<header class="header-area header-area-2">
    <div class="header-top pl-30 pr-30">
        <div class="row align-items-center">
            <div class="col-md-6 col-sm-7">
                <div class="header-left-side text-center text-sm-left">
                    <ul>
                        <li><a href="#"><i class="fal fa-envelope"></i> info@webmail.com</a></li>
                        <li><a href="#"><i class="fal fa-phone"></i> 786 875 864 75 7</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-sm-5">
                <div class="header-right-social text-center text-sm-right">
                    <ul>
                      <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                      <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="header-nav">
        <div class="navigation">
            <nav class="navbar navbar-expand-lg navbar-light ">
                <a class="navbar-brand" routerLink='/'><img src="assets/images/logo-2.png" alt=""></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Home</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
                                <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
                                <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
                                <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Services</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Pages</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/case-study' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">case study</a></li>
                                <li><a routerLink='/case-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Details</a></li>
                                <li><a routerLink='/coming-soon' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">coming soon</a></li>
                                <li><a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">faq</a></li>
                                <li><a routerLink='/pricing' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">pricing</a></li>
                                <li><a routerLink='/shop' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop</a></li>
                                <li><a routerLink='/shop-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop Details</a></li>
                                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Blog</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>
                </div>
                <div class="navbar-btn mr-100">
                    <a class="main-btn" routerLink='/contact-us'>Get A Quote</a>
                </div>
            </nav>
        </div>
    </div>
</header>-->
<!--====== HEADER PART ENDS ======-->
