import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header4',
  templateUrl: './header4.component.html',
  styleUrls: ['./header4.component.css']
})
export class Header4Component implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }
  switchLanguage(lang: string){
    this.translate.use(lang);
  }
}
