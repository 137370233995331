<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area" style="background-image: url(assets/Pancarta/DemoPersonalizado.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">¿Sabes como puedes rentabilizar tus datos?</h2>
                  <br>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <!--<li class="breadcrumb-item active" aria-current="page"><a routerLink='/'>Home </a></li>-->
                          <li class="breadcrumb-item" style="font-size: 35px;">Creamos un piloto, sin costo, acoplado a la realidad de tu empresa.</li>
                      </ol>
                      <h4 class="text-center text-white">Contáctenos <strong> ↓ </strong></h4>
                  </nav>
              </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== CONTACT DETAILS PART START ======-->
<div class="contact-details-area pt-90 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-info mr-30 mt-30">
                    <div class="contact-item-1">
                        <div class="contact-info-item text-center">
                            <i class="fal fa-phone"></i>
                            <h5 class="title">Teléfono</h5>
                            <p>+593 (4) 293 8500</p>
                        </div>
                        <div class="contact-info-item text-center">
                            <i class="fal fa-envelope"></i>
                            <h5 class="title">Dirección de correo</h5>
                            <p>info@royalticgroup.com</p>
                        </div>
                    </div>
                    <div class="contact-item-1">
                        <div class="contact-info-item text-center">
                            <i class="fal fa-map"></i>
                            <h5 class="title">Dirección</h5>
                            <p>Víctor Emilio Estrada 1246 y Costanera, Edif. Santa Rosa piso 2 Oficina 3.</p>
                        </div>
                        <div class="contact-info-item text-center">
                            <i class="fal fa-globe"></i>
                            <h5 class="title">Red social</h5>
                            <p>fb.com/RoyalticGroupEC</p>
                        </div>
                    </div>
                </div> <!-- contact info -->
            </div>
            <div class="col-lg-6">
                <div class="map-area mt-30">
                  <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7496149.95373021!2d85.84621250756469!3d23.452185887261447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30adaaed80e18ba7%3A0xf2d28e0c4e1fc6b!2sBangladesh!5e0!3m2!1sen!2sbd!4v1569913375800!5m2!1sen!2sbd" width="600" height="450" style="border:0;" allowfullscreen=""></iframe>-->
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.980022057889!2d-79.92013255013397!3d-2.161303898416852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6d8814c1292d%3A0x5367f9a1942ab55e!2sRoyalticgroup!5e0!3m2!1ses!2sec!4v1598648664666!5m2!1ses!2sec" width="600" height="450" style="border:0;" allowfullscreen=""></iframe>
                </div> <!-- map area -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== CONTACT DETAILS PART ENDS ======-->
<!--====== GET IN TOUCH PART START ======-->
<div class="get-in-touch-area get-in-touch-area-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title text-left">
                    <span>Ponerse en contacto</span>
                    <h2 class="title">Estimación para sus proyectos.</h2>
                </div> <!-- section title -->
                <div class="form-area">
                    <form id="contact-form" action="assets/contact.php" method="post">
                        <div class="input-box mt-45">
                            <input type="text" name="name" placeholder="Ingrese su nombre">
                            <i class="fal fa-user"></i>
                        </div>
                        <div class="input-box mt-20">
                            <input type="email" name="email" placeholder="Ingrese su correo">
                            <i class="fal fa-envelope"></i>
                        </div>
                        <div class="input-box mt-20">
                            <textarea name="message" id="#" cols="30" rows="10" placeholder="Ingrese su mensaje"></textarea>
                            <i class="fal fa-edit"></i>

                          <!--<re-captcha name="recaptcha" siteKey="6LeitvsmAAAAAOaK3VRnh07zX6oJ9htxIHtfVk3T">
                          </re-captcha>-->

                            <button class="main-btn" type="submit">Enviar ahora</button>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="get-map d-none d-lg-block mt-40">
                    <img src="assets/images/contact-thumb.jpg" alt="video play">
                    <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== GET IN TOUCH PART ENDS ======-->
<app-footer4></app-footer4>
