<!--====== footer PART START ======-->
<footer class="footer-area footer-area-2 footer-area-3">
    <div class="footer-overlay">
        <div class="container position-relative">
            <div class="row">
                <div class="col-lg-4 col-md-7">
                    <div class="widget-item-1 mt-30">
                        <img src="assets/images/logo-1.1.png" alt="">
                        <p>Como Royaltic estamos gustosos de solventar tus inquietudes sobre nuestros productos y/o Servicios, a continuación, encontraras la información necesaria para que nuestro equipo de Servicio al Cliente tome contacto contigo.</p>
                        <p><i>¡Tu negocio necesita de nuestras soluciones!</i></p>
                    </div> <!-- widget item 1 -->
                </div>
                <div class="col-lg-7 offset-lg-1 col-md-5">
                    <div class="widget-item-2 mt-30">
                        <h4 class="title">Páginas</h4>
                        <div class="footer-list">
                            <ul>
                                <li><a routerLink='/analytics'><i class="fal fa-angle-right"></i> Data Analytics</a></li>
                                <li><a routerLink='/datascience'><i class="fal fa-angle-right"></i> Ciencia de datos</a></li>
                                <li><a routerLink='/machinelearning'><i class="fal fa-angle-right"></i> Machine Learning</a></li>
                                <li><a routerLink='/bigdata'><i class="fal fa-angle-right"></i> Big Data</a></li>
                                <!--<li><a routerLink='/team-detail'><i class="fal fa-angle-right"></i> Comercial</a></li>-->
                                <li><a routerLink='/inteligencia'><i class="fal fa-angle-right"></i> Inteligencia Artificial</a></li>
                              <!--<li><a routerLink='/team-detail'><i class="fal fa-angle-right"></i> Producción</a></li>-->
                            </ul>
                            <ul>
                                <li><a routerLink='/pwa'><i class="fal fa-angle-right"></i> Aplicaciones PWA</a></li>
                                <li><a routerLink='/web'><i class="fal fa-angle-right"></i> Aplicaciones Web</a></li>
                                <li><a routerLink='/apl'><i class="fal fa-angle-right"></i> Aplicaciones Móviles</a></li>
                                <!--<li><a routerLink='/auraquantic'><i class="fal fa-angle-right"></i> AuraQuantic</a></li>
                              <li><a routerLink='/automate'><i class="fal fa-angle-right"></i> Automate RPA</a></li>
                              <li><a routerLink='/executions'><i class="fal fa-angle-right"></i> Executions</a></li>-->
                            </ul>
                        </div>
                    </div> <!-- widget item 2 -->
                </div>
              <!-- <div class="col-lg-4 col-md-6">
                  <div class="widget-item-2 widget-item-3 mt-30">
                      <h4 class="title">Horarios</h4>
                      <ul>
                          <li>Lunes - Viernes: 8:30 - 17:30</li>
                          <li>Saturday: 7:00 - 12:00</li>
                          <li>Sunday and holidays: 8:00 - 10:00</li>
                      </ul>
                      <p><span>Durante más de 30 años,</span> Royaltic ha sido un socio confiable en el campo de la inteligencia de negocios.</p>
                      <a href="#"><i class="fal fa-angle-right"></i>Saber más</a>
                  </div>
              </div>-->
            </div> <!-- row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright">
                        <p>Todos los derechos reservados. © <span>Royaltic</span> - {{ anio }}</p>
                    </div> <!-- footer copyright -->
                </div>
            </div> <!-- row -->
            <div class="shape-1"></div>
            <div class="shape-2"></div>
            <div class="shape-3"></div>
        </div> <!-- container -->
    </div>
</footer>
<!--====== footer PART ENDS ======-->
<!--====== BACK TO TOP ======-->
<div class="back-to-top back-to-top-2">
    <a href="">
        <i class="fas fa-arrow-up"></i>
    </a>
</div>
<!--====== BACK TO TOP ======-->
<!--  Start Style switcher  -->
<div id="style-switcher" style="left: -189px;" class="bg-light border p-3 pt-2 pb-2">
  <h3 class="title text-center">Select Your Color</h3>
  <ul class="pattern text-center mb-2">
    <li class="list-inline-item">
      <a class="default" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="green" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="red" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="skyblue" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="skobleoff" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="cyan" href="#"></a>
    </li>
  </ul>

  <h3 class="title text-center pt-3 mb-0 border-top">Theme Option</h3>
  <div class="text-center">
    <a href="#" class="btn btn-sm w-100 btn-primary rtl-version t-rtl-light mt-2">RTL</a>
    <a href="#" class="btn btn-sm w-100 btn-primary ltr-version t-ltr-light mt-2">LTR</a>
    <a href="#" class="btn btn-sm w-100 btn-primary dark-rtl-version t-rtl-dark mt-2">RTL</a>
    <a href="#" class="btn btn-sm w-100 btn-primary dark-ltr-version t-ltr-dark mt-2">LTR</a>
    <a href="#" class="btn btn-sm w-100 btn-dark dark-version t-dark mt-2">Dark</a>
    <a href="#" class="btn btn-sm w-100 btn-dark light-version t-light mt-2">Light</a>
  </div>
  <div class="bottom">
    <a href="https://api.whatsapp.com/send?phone=593989838390&text=Hola,%20quisiera%20mas%20informaci%C3%B3n.%20Gracias" class="whatsapp bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-whatsapp text-primary"></i></a>
  </div>
  <!--<div class="bottom">
    <a href="#" class="linkedin bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-linkedin-in text-primary"></i></a>
  </div>-->
  <div class="bottom">
    <a href="https://www.facebook.com/RoyalticGroupEC/" class="facebook bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-facebook text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="https://www.linkedin.com/company/royalticgroup/" class="twitter bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-linkedin text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="https://www.instagram.com/royalticgroup/" class="instagram bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-instagram text-primary"></i></a>
  </div>
  <!--<div class="bottom">
    <a href="#" class="youtube bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-youtube text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="vk bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-vk text-primary"></i></a>
  </div>-->
</div>
