<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/AplicacionesPWA.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Aplicaciones PWA</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Aplicaciones PWA</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">¿Qué es una PWA? ¿Por qué son tan importantes?</h2>
          <p>Una Progressive Web App o PWA aúna lo mejor de una página web y de una aplicación nativa, ofreciendo grandes beneficios tanto al usuario como a la PYME.
            Su versatilidad y naturaleza progresiva aseguran una experiencia móvil perfecta desde cualquier dispositivo y sistema operativo.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/visibilidad.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-eye-dropper"></i>
            <h4 class="title">Máxima visibilidad</h4>
            <p>Mejora el posicionamiento en buscadores y la visibilidad online de tu negocio con las progressive web apps, logrando las primeras posiciones en los resultados de búsqueda.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/cronometro.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-gavel"></i>
            <h4 class="title">Velocidad de carga</h4>
            <p>Mejora la velocidad de carga de tu negocio online con una PWA. Harás crecer tu empresa, fidelizarás clientes y mejorarás la comunicación con los usuarios.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/click.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-map-marked-alt"></i>
            <h4 class="title">A un clic</h4>
            <p>¡Mobile Friendly! Sin descarga, sin instalación y sin necesidad de markets de apps.</p>
          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>

<!--====== SHOP DETAILS PART START ======-->
<div class="shop-details-area pt-120 pb-115 gray-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="shop-details-content ml-30">
          <div class="review">
            <h4 class="title">Velocidad de carga ultra rápida</h4>
          </div>


          <div class="overview pt-20">
<!--            <h4 class="title">Overview</h4>-->
            <p>Una Progressive Web App es hasta 4 veces más rápida que cualquier web (incluso que la web responsive), asegurando la mejor experiencia para el usuario.</p>
            <p class="pt-10">La velocidad de carga es un factor fundamental para la conversión de los e-commerce. Por cada segundo extra que tarda una web en cargar, se pierden cientos de ventas potenciales.</p>
            <p class="pt-10">Gracias a su PWA, la revista Forbes ha conseguido reducir el tiempo de carga a 2,5 segundos, en lugar de los 6,5 segundos que requería su web móvil. Así han incrementado un 10% las impresiones por visita.</p>

          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="shop-product-area ml-30">
          <div class="shop-active">
            <div class="shop-product-item">
              <img src="assets/images/speed-pwa.jpg" alt="">
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="shop-product-area ml-30">
          <div class="shop-active">
            <div class="shop-product-item">
              <img src="assets/images/det-pwa.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="shop-details-content ml-30">
          <div class="review">
            <h4 class="title">PWA: Sin publicación. Sin descarga. Sin instalación</h4>
          </div>

          <div class="overview pt-20">
            <!--            <h4 class="title">Overview</h4>-->
            <p>Para poder acceder a una PWA no es necesario publicarla en Google Play o en App Store. Así reduces costes y disminuyes la fricción con el usuario.</p>
            <p class="pt-10">¡Bye, bye descargas! Los usuarios acceden a la Progressive Web App al instante haciendo clic en un enlace, mediante un código QR o redireccionado desde la web. Además, los usuarios no tendrán que actualizar la PWA, ya que siempre accederán a la última versión.</p>
            <p class="pt-10">Gracias a la implementación de su Progressive Web App, Trivago ha incrementado en un 150% los usuarios que incluyeron el acceso directo a la PWA en su pantalla de inicio. Así han aumentado en un 97% en el engagement de los usuarios.</p>

          </div>
        </div>
      </div>


    </div>

  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="shop-details-content ml-30">
          <div class="review">
            <h4 class="title">Experiencia perfecta multiplataforma</h4>
          </div>


          <div class="overview pt-20">
            <!--            <h4 class="title">Overview</h4>-->
            <p>Control total de UX desde cualquier dispositivo y sistema operativo.</p>
            <p class="pt-10">Además de ser mucho más cómodo a la hora de crear la aplicación, disponer de una PWA supone una reducción en costes de publicación y cuentas de desarrollador.</p>
            <p class="pt-10">Después de que Pinterest implementara su Progressive Web App, el engagement de los usuarios ha aumentado en un 66%. Además, han registrado un incremento del 40% en el tiempo de permanencia de su aplicación.</p>

          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="shop-product-area ml-30">
          <div class="shop-active">
            <div class="shop-product-item">
              <img src="assets/images/multiplataforma.jpg" alt="">
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--====== SHOP DETAILS PART ENDS ======-->
<div class="why-choose-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="single-choose text-center mt-30">
          <div class="icon-box">
            <span></span><i class="fal fa-laptop-code"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="section-title text-center">
          <h4 class="title">Progressive Web Apps + Espacio + Satisfacción</h4>
          <p class="text-white">Las PWA son extremadamente ligeras. Mientras que una aplicación nativa pesa alrededor de 200MB, las Progressive Web Apps no suelen ocupar más de 0,5MB en el dispositivo móvil.</p>
          <p class="text-white pt-10">Gracias a su PWA, Tinder consiguió reducir en más del 90% el peso de su aplicación nativa.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div data-wow-duration=".5s" data-wow-delay="0s" class="col-lg-4 col-md-6 col-sm-9 wow slideInUp" style="visibility: visible; animation-duration: 0.5s; animation-delay: 0s;">
      </div>
    </div>
  </div>
  <div class="choose-dot">
    <img src="assets/images/choose-dot.png" alt="">
  </div>
  <div class="choose-shape">
    <img src="assets/images/choose-shape.png" alt="">
  </div>
</div>
<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-5 pb-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="shop-details-content ml-30">
          <div class="review">
            <h4 class="title">Funcionalidades nativas y mensajes Push</h4>
          </div>
          <div class="overview pt-20">
            <!--            <h4 class="title">Overview</h4>-->
            <p>Ya no es necesario desarrollar una app nativa para poder acceder a las funcionalidades propias del dispositivo móvil. Las PWA aúnan lo mejor de las webs y las apps nativas, permitiendo las mismas funcionalidades que cualquier otra aplicación móvil.</p>
            <p class="pt-10">Además, también permiten el envío de notificaciones Push personalizadas y geolocalizadas, mejorando increíblemente la comunicación con los usuarios.</p>
            <p class="pt-10">Las funcionalidades de la PWA de Lancome han incrementado en un 17% las conversiones, un 51% las sesiones desde dispositivos móviles y un 53% las sesiones desde dispositivos iOS. Además los mensajes Push han contribuido al aumento del 18% en la tasa de apertura de sus notificaciones.</p>

          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="shop-product-area ml-30">
          <div class="shop-active">
            <div class="shop-product-item">
              <img src="assets/images/nativa.jpg" alt="">
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="shop-product-area ml-30">
          <div class="shop-active">
            <div class="shop-product-item">
              <img src="assets/images/SEOposition.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="shop-details-content ml-30">
          <div class="review">
            <h4 class="title">Las PWA mejoran el posicionamiento SEO</h4>
          </div>


          <div class="overview pt-20">
            <!--            <h4 class="title">Overview</h4>-->
            <p>Las PWA son en esencia páginas web, por lo que facilitan el posicionamiento en buscadores como Google. Gracias a ello, aumenta la visibilidad y presencia online de tu negocio.</p>
            <p class="pt-10">El Sistema de Reseñas Automatizado de la PWA incide directamente en el Google Local Pack, mejorando la influencia online del negocio en su área geográfica y ayudando a conseguir nuevos clientes.</p>
            <p class="pt-10">Alibaba ha aumentado un 76% las conversiones gracias a una Progressive Web App, con un 14% más de usuarios iOS activos y un 30% más de Android.</p>

          </div>
        </div>
      </div>


    </div>

  </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->

<!--====== SERVICES ITEM PART ENDS ======-->
<!--====== TEAM DETAILS PART START ======-->
<!--<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<!--<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<!--<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
