import { Component, OnInit, NgModule  } from '@angular/core';


//import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//import * as $ from 'jquery';


//import { BrowserModule } from '@angular/platform-browser';
//import { FormsModule } from '@angular/forms'; // Importa FormsModule
import Swal from 'sweetalert2';




@Component({
  selector: 'app-innovelabs',
  templateUrl: './innovelabs.component.html',
  styleUrls: ['./innovelabs.component.css']
})


export class InnovelabsComponent implements OnInit {

  //successSubject = new Subject<string>();
  //successMessage = this.successSubject.asObservable();
  msjForm:any
  modalService: any;


  public c_obj: any;


  constructor(private http: HttpClient) {
    this.c_obj = {};
   }

  ngOnInit(): void {
  }


  enviarDatos(obj, modal) {
    const url = 'https://royalticgroup.com/assets/contactil.php';
    //const data = this.formData;

    const data = {
      name: obj['name'],
      email: obj['email'],
      empresa: obj['empresa'],
      telefono: obj['telefono'],
      message: obj['message']
    };

    this.http.post(url, data).subscribe(res => {
      console.log(res)
      if (res['success'] == true) {
        // mostrar el modal de éxito
        this.messageDialog('¡El formulario se ha enviado con éxito!', '', 'success');
        this.cleardata()

      } else{
        this.errorForm()
      }

    });
  }



  public openModal(modal, msj) {
    this.msjForm = msj
    this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'xl', centered:true });
  }

  // Valida campos del formulario
  public validateImputs(){

  }

  /*
  enviarDatos2(obj, modal) {

    // validateImputs()

    const datosFormulario = {
      name: obj['name'],
      email: obj['email'],
      empresa: obj['empresa'],
      telefono: obj['telefono'],
      message: obj['message']
    };


    this.http.post('assets/contactil.php', datosFormulario).subscribe(
        (response) => {
             if (response == "success") {
               this.messageDialog('¡El formulario se ha enviado con éxito!', '', 'success');
               this.cleardata()
             } else{
              this.errorForm()
             }
        },
        (error) => {
             this.errorForm()
        }
    );

  }
  */





  public errorForm(){
    this.messageDialog('¡ERROR: El formulario NO se ha enviado con éxito!, intente nuevamente.', '', 'error');
  }

  public messageDialog(title, text, icon) {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        allowOutsideClick: false
    }).then((result) => {
        if (result.value) {
            Swal.close()
            if (icon != "error") {
                //this.getInitialData();
            }
        }
    })
}


public cleardata(){
  //Clear Imputs
  this.c_obj['name'] = ''
  this.c_obj['email'] = ''
  this.c_obj['empresa'] = ''
  this.c_obj['telefono'] = ''
  this.c_obj['message'] = ''
}






}
