import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comingsoom',
  templateUrl: './comingsoom.component.html',
  styleUrls: ['./comingsoom.component.css']
})
export class ComingsoomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
