<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/Auraquantic.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Auraquantic</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Auraquantic</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">¿Qué es AuraQuantic?</h2>
          <p>AuraQuantic es una plataforma digital diseñada para que los usuarios de negocio puedan crear procesos y aplicaciones ilimitados de manera fácil y rápida para automatizar las operaciones, reduciendo costes y optimizando la productividad.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/teletrabajo.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-eye-dropper"></i>
            <h4 class="title">TELETRABAJO</h4>
            <p>AuraQuantic es una solución de</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/bajo.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-gavel"></i>
            <h4 class="title">LOW-CODE</h4>
            <p>AuraQuantic es una plataforma de creación de aplicaciones</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/automatizacion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-map-marked-alt"></i>
            <h4 class="title">AUTOMATIZACIÓN DE PROCESOS</h4>
            <p>AuraQuantic es una plataforma de gestión y</p>

          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="choose-video-thumb mt-115 text-center">
          <!--<img src="assets/images/choose-video-thumb.jpg" alt="">
          <a href="#"><i class="fal fa-play"></i></a>  width="320" height="240"-->
          <video controls>
            <source src="assets/videos/aura.mp4" type="video/mp4">
            Su navegador no soporta la etiqueta video.
          </video>
        </div>
      </div>
    </div>
  </div> <!-- container -->
</div>

<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-5 pb-1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-standard">
          <div class="single-blog-standard mt-10">
            <div class="blog-dteails-content blog-border">
              <div class="blog-details-top">
                <h2 class="title">¿Por qué elegir AuraQuantic?</h2>

                <p class="mt-20 pb-0">AuraQuantic le ayuda a digitalizar y orquestar todo tipo de procesos de negocio y a crear todas sus aplicaciones, más rápido.</p>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->
<!--====== COUNTER PART START ======-->
<div class="counter-area bg_cover pt-0 pb-15" style="background-image: url(assets/images/video-bg.jpg);">
  <div class="container">

    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">100</span>%</h3>
          <p>Colaboración</p>
          <i class="fal fa-box"></i>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">80</span>%</h3>
          <p>Reducción de tiempos</p>
          <i class="fal fa-users"></i>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">75</span>%+</h3>
          <p>Aumento fidelización del cliente</p>
          <i class="fal fa-globe"></i>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter1">Supere</span> las</h3>
          <p>expectativas del cliente al predecir sus necesidades</p>
          <i class="fal fa-award"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== COUNTER PART ENDS ======-->
<!--====== CHOOSE PART START ======-->
<div class="choose-area pt-5">
  <div class="container">
    <div class="row mt-30">
      <div class="col-lg-12">
        <div class="section-title text-center">

          <h3 class="title">¿Cómo puede AuraQuantic ayudarme?</h3>
        </div>
        <div class="text-center">
          <p>AuraQuantic satisfará las necesidades de su empresa en estos tiempos tan cambiantes:</p>
        </div>
        <div class="choose-cat mt-20">

          <section id="feature" class="feature circle-top pt-30 pb-0">
            <div class="container">
              <div class="row">
                <div class="col-md-6 mb-xs-5">
                  <div class="feature-box text-center">
                    <!-- <i class="ti ti-headphone feature-icon" aria-hidden="true"></i> -->
                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-01.png" alt="QLIK" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
             <span style="color: #303030;">
              <span style="font-size: 18px;">
               <strong>Transformación digital</strong>
              </span>
             </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
             <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Reduce costes y tiempo<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Reduce el control burocrático<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>El conocimiento se queda en la empresa<br>
                        <span style="color: #1785b0;">
              <strong>·</strong>&nbsp;
             </span>Genera menos documentos
                      </p>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 mb-xs-5">
                  <div class="feature-box text-center">
                    <!-- <i class="ti ti-target feature-icon" aria-hidden="true"></i> -->
                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-03.png" alt="QLIK" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
             <span style="color: #303030;">
              <span style="font-size: 18px;">
               <strong>Decisión analítica</strong>
              </span>
             </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
             <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Mejora la toma de decisiones<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Aumento de la capacidad analítica<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Predicción de resultados<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Coherencia en la información
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="feature-box text-center">
                    <!-- <i class="ti ti-music-alt feature-icon" aria-hidden="true"></i> -->
                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-02.png" alt="CAPACITACIONES" width="50" height="50">
                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
              <span style="color: #303030;">
               <span style="font-size: 18px;">
                <strong>Control organizacional</strong>
               </span>
              </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
              <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Acelera la ejecución de tareas<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Optimiza la eficiencia<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Impulsa la productividad<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Mejora la comunicación interna
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="feature-box text-center">
                        <img src="images/icons/meeting.png" alt="CAPACITACIONES" width="50" height="50">
                        <p class="mt-3 mb-3">UX Design Management</p>
                        <h4 class="text-capitalize">SERVICIOS <br> CAPACITACIONES</h4>
                        <span class="hr-line mt-4 mb-4"></span>
                        <a href="#." class="mb-3 mb-lg-0">Read More</a>
                    </div>
                </div> -->

                <div class="col-md-6">
                  <div class="feature-box text-center">
                    <!-- <i class="ti ti-music-alt feature-icon" aria-hidden="true"></i> -->
                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-06.png" alt="SERVICIOS" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
              <span style="color: #303030;">
               <span style="font-size: 18px;">
                <strong>Adaptación al cambio</strong>
               </span>
              </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
              <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Elimina la resistencia al cambio<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Amplía las oportunidades comerciales<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Nuevas estrategias de negocio<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Mejora continua
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="feature-box text-center">
                    <!-- <i class="ti ti-music-alt feature-icon" aria-hidden="true"></i> -->
                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-05.png" alt="SERVICIOS" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
														<span style="color: #303030;">
															<span style="font-size: 18px;">
																<strong>Cumplimiento normativo</strong>
															</span>
														</span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
														<span style="color: #1785b0;">
															<strong>·</strong> &nbsp;
														</span>Elimina errores<br>
                        <span style="color: #1785b0;">
															<strong>·&nbsp;</strong>
														</span>Incrementa la seguridad</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="feature-box text-center">
                    <!-- <i class="ti ti-music-alt feature-icon" aria-hidden="true"></i> -->
                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-04.png" alt="SERVICIOS" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
															<span style="color: #303030;">
																<span style="font-size: 18px;">
																	<strong>Experiencia del cliente</strong>
																</span>
															</span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
															<span style="color: #1785b0;">
																<strong>·</strong> &nbsp;
															</span>Satisface a los clientes<br>
                        <span style="color: #1785b0;">
																<strong>·</strong> &nbsp;
															</span>Supera sus expectativas
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <!--<div class="row">
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".4s">
              <h4 class="title"></h4>
              <p>Estamos comprometidos en proporcionar un crecimiento exponencial al negocio de nuestros valiosos clientes a través de nuestros impecables servicios de Inteligencia de Negocios.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".6s">
              <h4 class="title"></h4>
              <p>Tenemos la actitud resuelta, valiente y ganadora para ofrecer un servicio Analítico de primera calidad. Somos conocidos por utilizar un enfoque integral que ayuda a su empresa a optimizar procesos.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Estamos ejecutando con éxito más de un centenar de proyectos, que también exudan el testimonio de nuestra competencia en el mercado competitivo. Por favor revise todos los testimonios.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Estamos ejecutando con éxito más de un centenar de proyectos, que también exudan el testimonio de nuestra competencia en el mercado competitivo. Por favor revise todos los testimonios.</p>
              <i class="fal fa-check"></i>
            </div>
          </div>-->
        </div>
      </div>

    </div>
    <div class="row mt-30">
      <p>AuraQuantic es la tendencia consolidada e imparable que está cambiando la forma de gestionar la operativa en todo tipo de empresas y organizaciones a nivel mundial, en todas las industrias, haciéndolas mucho más flexibles, altamente automatizadas y extremadamente potentes.</p>
    </div>
  </div>
</div>
<!--====== CHOOSE PART ENDS ======-->
<div class="services-item-area pt-15 pb-10">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Tendencias</h2>
        </div>
        <p>Conocimientos tecnológicos, noticias, consejos, procedimientos y guías que le ayudarán a identificar tecnologías y estrategias para capacitar a sus trabajadores, optimizar los procesos comerciales y preparar su empresa para el futuro.</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/automatizacion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">INFORME GARTNER</h4>
            <p>Vaya más allá del RPA y alcance la hiperautomatización</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/comunicado.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">PEER INSIGHTS</h4>
            <p>4.8 en Enterprise Low-Code Application Platforms</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/pococodigo.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">LOW-CODE</h4>
            <p>El árbol de decisión para las categorías Low-Code</p>
          </div>
        </div> <!-- single services -->
      </div>

      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/computo.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">ANALISTAS</h4>
            <p>¿Qué opinan los analistas de nosotros?</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/hiperautomatizado.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">HIPERAUTOMATIZACIÓN</h4>
            <p>Hiperautomatización y teletrabajo</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/inteligencia.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">INTELIGENCIA ARTIFICIAL</h4>
            <p>Cree aplicaciones inteligentes con Azure AI</p>
          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>
<!--====== SERVICES ITEM PART ENDS ======-->
<!--====== TEAM DETAILS PART START ======-->
<!--<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<!--<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<!--<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
