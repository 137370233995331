<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Nosotros</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Nosotros</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== ABOUT EXPERIENCE PART START ======-->
<div class="about-experience-area pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="experience-item">
                    <span>16 años de experiencia</span>
                    <h2 class="title">no solo creando soluciones. !Mejorando negocios¡.</h2>
                </div> <!-- experience item -->
            </div>
            <div class="col-lg-4 offset-lg-1">
                <div class="experience-item">
                    <p>Nos esforzamos por ayudar a las empresas a maximizar la rentabilidad mediante soluciones que ayudan a optimizar sus procesos.</p>
                  <!--<a routerLink='/about'>Leer más</a>-->
                </div> <!-- experience item -->
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".2s">
                <div class="single-experience mt-30">
                    <img src="assets/images/experience-1.jpg" alt="">
                    <div class="experience-overlay">
                        <h5 class="title">¿Qué hacemos?</h5>
                        <p>Si desea una solución que impulse el crecimiento y la rentabilidad de su negocio, Royaltic es el nombre con el que debe contar.</p>
                        <a routerLink='/service-detail'>Leer más</a>
                        <i class="fal fa-laptop-code"></i>
                    </div>
                </div> <!-- single experience -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                <div class="single-experience mt-30">
                    <img src="assets/images/experience-2.jpg" alt="">
                    <div class="experience-overlay">
                        <h5 class="title">Soporte</h5>
                        <p>The functional goal of technical content is to help people use a product.</p>
                        <a routerLink='/service-detail'>Leer más</a>
                        <i class="fal fa-headphones-alt"></i>
                    </div>
                </div> <!-- single experience -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".6s">
                <div class="single-experience mt-30">
                    <img src="assets/images/experience-3.jpg" alt="">
                    <div class="experience-overlay">
                        <h5 class="title">Digital Marketing</h5>
                        <p>The functional goal of technical content is to help people use a product.</p>
                        <a routerLink='/service-detail'>Leer más</a>
                        <i class="fal fa-analytics"></i>
                    </div>
                </div> <!-- single experience -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOUT EXPERIENCE PART ENDS ======-->
<!--====== ABOUT INTRO PART START ======-->
<div class="about-intro-area pt-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="intro-thumb">
                    <img src="assets/images/about-intro-thumb.jpg" alt="">
                    <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="intri-content">
                    <span>Video introductorio</span>
                    <h2 class="title">Cómo hacemos crecer <br> su negocio.</h2>
                    <p class="text-1">La introducción de tecnologías móviles y en la nube en el software empresarial.</p>
                    <p class="text-2">Ya sea que esté creando un portal web empresarial o un sitio web de última generación, siempre necesita las herramientas modernas adecuadas. Los marcos PHP bien construidos y mantenidos proporcionan esas herramientas en abundancia, lo que permite que los marcos PHP mantenidos proporcionen esas herramientas en abundancia, lo que permite a los desarrolladores ahorrar tiempo, reutilizar el código y optimizar el back-end. Como herramientas de desarrollo de software de forma continua.</p>
                    <a class="main-btn wow fadeInUp" routerLink='/about' data-wow-duration="1s" data-wow-delay=".4s">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== ABOUT INTRO PART ENDS ======-->
<!--====== ABOUT HISTORY PART START ======-->
<div class="about-history-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="section-title text-center">
                    <h2 class="title">Our History</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div> <!-- section title -->
            </div>
        </div> <!-- row -->
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="history-item  pt-240">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <img src="assets/images/history-1.jpg" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <span>1990 - Startup</span>
                        <h4 class="title">Technical content may have per suasive objectives.</h4>
                    </div>
                    <div class="number-box">
                        <span>02</span>
                    </div>
                </div> <!-- history item -->
                <div class="history-item">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <img src="assets/images/history-2.jpg" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <span>1990 - Startup</span>
                        <h4 class="title">Technical content may have per suasive objectives.</h4>
                    </div>
                    <div class="number-box">
                        <span>04</span>
                    </div>
                    <div class="number-box-2">
                        <i class="fal fa-plus"></i>
                    </div>
                </div> <!-- history item -->
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="history-item history-item-2">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <img src="assets/images/history-3.jpg" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".1s">
                        <span>1990 - Startup</span>
                        <h4 class="title">Technical content may have per suasive objectives.</h4>
                    </div>
                    <div class="number-box">
                        <span>01</span>
                    </div>
                </div> <!-- history item -->
                <div class="history-item history-item-2">
                    <div class="history-thumb wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <img src="assets/images/history-4.jpg" alt="history">
                    </div>
                    <div class="history-content wow fadeInUp" data-wow-duration=".1s" data-wow-delay=".2s">
                        <span>1990 - Startup</span>
                        <h4 class="title">Technical content may have per suasive objectives.</h4>
                    </div>
                    <div class="number-box">
                        <span>03</span>
                    </div>
                </div> <!-- history item -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOUT HISTORY PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".2s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".3s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".4s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".5s">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".1.2s" data-wow-delay=".6s">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                    </div>
                </div> <!-- faq accordion -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
