<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/Tigre.jpg); height: 357px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title-item text-center">
          <h2 class="title">Innovation Lab</h2>
          <nav aria-label="breadcrumb">
            <!--<ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
              <li class="breadcrumb-item active" aria-current="page">Innovation Labs</li>
            </ol>-->
            <p class="mt-3 fs-4 fs-sm-7 latter-sp-3 lh-base" style="color: white; font-size: 27px">“No son los grandes los que se comen a los pequeños...
              son los veloces los que se comen a los lentos” </p>
          </nav>
        </div> <!-- page title -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START #bbeaf8======-->
<div class="services-item-area pt-30 pb-30">
  <div class="container">
    <section class="mt-5">
      <!--<div class="container">
        <div class="text-center">
          <div class="p-5 bg-primary rounded-3" style="background-color: #ffffff!important;">
            <div class="py-3">
              <h4 class="opacity-50 ls-2 lh-base fw-medium">Listo para empezar</h4>

            </div>
            <div class="flex-center d-flex">
              <button class="btn btn-info">Inscribirme <span class="fas fa-arrow-right"></span></button>
            </div>
          </div>
        </div>
      </div>-->
      <!-- end of .container-->
    </section>

    <section class="mt-1">

      <div class="container">
        <!--<div class="text-center">
          <p class="fs-2 ls-2">_______</p>
          <h1 class="display-6 fw-semi-bold"></h1>
        </div>
        <div class="row mt-6 flex-center justify-content-xl-evenly px-xl-5">
          <div class="col-md-7 col-lg-6 order-md-1">
            &lt;!&ndash;<img class="w-100" src="assets/images/comment1.png" alt="...">&ndash;&gt;
            <p class="fs-xl-1 ls-3 pe-xl-2" style="font-size: xx-large"> </p>
          </div>
          <div class="col-md-5 col-lg-4">
            <h3 class="fs-xl-7 fs-lg-4 fs-md-3 mt-5 mt-md-0"> </h3>
            <p class="fs-xl-1 ls-3 pe-xl-2" style="font-size: xx-large"> </p>
          </div>
        </div>
        <div class="row mt-6 flex-center justify-content-xl-evenly px-xl-5 mt-50 mb-30">
          <div class="col-md-7 col-lg-6 false">
          </div>
          <div class="col-md-5 col-lg-4">
            <h3 class="fs-xl-7 fs-lg-4 fs-md-3 mt-5 mt-md-0"> </h3>

          </div>
        </div>
        <div class="row mt-6 flex-center justify-content-xl-evenly px-xl-5">
          <div class="col-md-7 col-lg-6 order-md-1"></div>
          <div class="col-md-5 col-lg-4">
            <h3 class="fs-xl-7 fs-lg-4 fs-md-3 mt-5 mt-md-0"> </h3>
            <p class="fs-xl-1 ls-3 pe-xl-2"> </p>
          </div>

          <div class="flex-center d-flex mt-20">
            <a href="https://me-qr.com/bXVrQOpE" class="btn btn-info">Inscribirme <span class="fas fa-arrow-right"></span></a>
          </div>

        </div>-->
      </div>
      <!-- end of .container-->

    </section>


    <section class="cards cards--webappdev-1 pt-10 pb-50 bgcolor--white icons--green">
      <div class="container-fluid">
        <!--<div class="heading">
          <div class="row">
            <div class="col-sm-12 order-sm-1">
              <div class="section-title text-center">
                <h3>PASOS ESENCIALES PARA CONSTRUIR LA SOLUCIÓN WEB ADECUADA</h3>
              </div>
            </div>
          </div>
        </div>-->
        <div class="row cards-row pt-10 pb-50">
          <div class="col-sm-4 text-center">
            <div class="cards-item" style="height: 230px;">
              <div class="cards-wrapper">
                <div class="cards-icon">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="140.5px" height="97.1px" viewBox="0 0 140.5 97.1" enable-background="new 0 0 140.5 97.1" xml:space="preserve" class="icon" alt="">
<path d="M55.1,92.5L30.3,72.4H0V0h118.7v25.7h-1V1H1v70.4h29.6l0.1,0.1l23.3,18.9v-19H61v1h-5.9V92.5z M139.5,26.7H62v55.4h43.5h1v1
	v12L124,82.3l0.3-0.2h0.3h14.9V26.7 M140.5,25.7v57.4h-15.9l-19.1,14v-14H61V25.7H140.5L140.5,25.7z"></path>
</svg>
                </div>
                <div class="cards-name pt-15 pb-15">
                  <h4>¿Qué es?<br></h4>
                  <h4><br></h4>
                </div>
                <div class="cards-text">
                  <p>Un laboratorio de innovación es un espacio dinámico y colaborativo que te ayuda a transformar tus ideas en soluciones reales y
                  disruptivas. Lograras acelerar el proceso de desarrollo de productos o servicios aumentando las posibilidades de éxito.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="cards-item" style="height: 230px;">
              <div class="cards-wrapper">
                <div class="cards-icon">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="111px" height="94.3px" viewBox="0 0 111 94.3" enable-background="new 0 0 111 94.3" xml:space="preserve" class="icon" alt="">
<path d="M111,79H0V0h111V79z M1,78h109V1H1V78z M56,78.8h-1v14h1V78.8L56,78.8z M78.5,93.3h-46v1h46V93.3L78.5,93.3z M49,69.8
	c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5S53.7,69.8,49,69.8z M49,53.8c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5
	s7.5-3.4,7.5-7.5S53.1,53.8,49,53.8z M87.6,42.1L76.2,53.4l0.7,0.7l11.3-11.3L87.6,42.1L87.6,42.1z M76.9,42.1l-0.7,0.7l11.3,11.3
	l0.7-0.7L76.9,42.1L76.9,42.1z M47.5,12.1L36.2,23.4l0.7,0.7l11.3-11.3L47.5,12.1L47.5,12.1z M36.9,12.1l-0.7,0.7l11.3,11.3l0.7-0.7
	L36.9,12.1L36.9,12.1z M27.5,32.1L16.2,43.4l0.7,0.7l11.3-11.3L27.5,32.1L27.5,32.1z M16.9,32.1l-0.7,0.7l11.3,11.3l0.7-0.7
	L16.9,32.1L16.9,32.1z M56.3,57.8c4.1-2.9,9.7-7.9,13-15.6c3.8-8.7-1.3-25.1-1.5-25.8c-0.1-0.2-0.2-0.3-0.4-0.3
	c-0.2,0-0.4,0.1-0.5,0.3l-4.4,9.2l0.9,0.4l3.9-8c1.1,4,4.3,16.8,1.2,23.8c-3.3,7.5-8.7,12.3-12.7,15.1l-0.4,0.3l0.6,0.8L56.3,57.8z
	 M73.9,22.8 M75.4,22.7L67.3,16l-0.6,0.8l8.1,6.7L75.4,22.7z"></path>
</svg>
                </div>
                <div class="cards-name pt-15 pb-15">
                  <h4>¿Cuáles son <br>sus ventajas?</h4>
                </div>
                <div class="cards-text">
                  <p>1. Incrementar la competitividad</p>
                  <p>2. Promover la colaboración</p>
                  <p>3. Aumenta la eficiencia</p>
                  <p>4. Genera oportunidades únicas</p>
                  <p>5. Efectividad y control</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="cards-item" style="height: 230px;">
              <div class="cards-wrapper">
                <div class="cards-icon">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="92px" height="97px" viewBox="0 0 92 97" enable-background="new 0 0 92 97" xml:space="preserve" class="icon" alt="">
<path d="M1,0v97H0V0H1L1,0z M92,0h-1v97h1V0L92,0z M91,0H0v1h91V0L91,0z M92,96H0v1h92V96L92,96z M81,49H35v1h46V49L81,49z M52,56
	H35v1h17V56L52,56z M80,11H13v26h67V11 M81,10v28H12V10H81L81,10z M25,48H13v12h12V48 M26,47v14H12V47H26L26,47z M81,71H35v1h46V71
	L81,71z M52,78H35v1h17V78L52,78z M25,70H13v12h12V70 M26,69v14H12V69H26L26,69z M80.2,11.5l-0.4-0.9l-67,26l0.4,0.9L80.2,11.5z
	 M80.2,36.5l-67-26l-0.4,0.9l67,26L80.2,36.5z"></path>
</svg>
                </div>
                <div class="cards-name pt-15 pb-15">
                  <h4>¿A quién <br>va dirijido? </h4>
                </div>
                <div class="cards-text">
                  <p>A las empresas que quieran innovar de forma estructurada.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info info--webappdev-1 pt-70 bgcolor--white heading--big">
      <div class="heading container">
        <div class="row justify-content-center">
          <div class="col-xs-12">
            <div class="section-title text-center">
              <h3>“En la actualidad, el que no innova va a
                desaparecer y el que innova sin estructura
                no será sostenible”.</h3>
            </div>
          </div>
        </div>
        <div class="row pt-15">
          <div class="col-lg-12 order-lg-1">
            <div class="heading-description text-center">
              <p style="font-size: 27px">¡Participa en nuestra Master Class de
                Innovación donde lograrás entender qué es
                un laboratorio de innovación,cuáles son sus
                objetivos y beneficios en la empresa!</p>
            </div>
          </div>
        </div>



        <!--<div class="row justify-content-center">
          <div class="col-xs-12">
            <div class="section-title text-center">
              <div class="flex-center d-flex mt-20">
                <a href="https://me-qr.com/bXVrQOpE" class="btn btn-lg btn-info">Inscribirme <span class="fas fa-arrow-right"></span></a>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <!--====== GET IN TOUCH PART START ======-->
      <div class="get-in-touch-area get-in-touch-area-2 pt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title text-left">
                <span style="font-size: 27px">Innovation lab masterclass</span>
                <h2 class="title" style="font-size: 37px; line-height: 40px">Sé parte de nuestra Masterclass y construye el futuro de tu empresa. ¡Inscríbete!</h2>
              </div> <!-- section title -->
              <div class="form-area">
                <!-- <form id="contact-form" action="assets/contactil.php" method="post"> -->
                  <form (ngSubmit)="enviarDatos(c_obj, modalform)" #formulario="ngForm" id="contact-form">

                  <div class="input-box mt-45">
                    <!-- <input type="text" name="name" placeholder="Ingrese su nombre"> -->
                    <input type="text" [(ngModel)]="c_obj['name']" name="name" placeholder="Ingrese su nombre">
                    <i class="fal fa-user"></i>
                  </div>
                  <div class="input-box mt-20">
                    <!-- <input type="email" name="email" placeholder="Ingrese su correo"> -->
                    <input type="email" [(ngModel)]="c_obj['email']" name="email" placeholder="Ingrese su correo">
                    <i class="fal fa-envelope"></i>
                  </div>

                  <div class="input-box mt-20">
                    <!-- <input type="text" name="empresa" placeholder="Ingrese su empresa"> -->
                    <input type="text" [(ngModel)]="c_obj['empresa']" name="empresa" placeholder="Ingrese su empresa">

                    <i class="fal fa-building"></i>
                  </div>
                  <div class="input-box mt-20">
                    <!-- <input type="text" name="telefono" placeholder="Ingrese su teléfono"> -->
                    <input type="text" [(ngModel)]="c_obj['telefono']" name="telefono" placeholder="Ingrese su teléfono">
                    <i class="fal fa-phone"></i>
                  </div>

                  <div class="input-box mt-20">
                    <!-- <textarea name="message" id="#" cols="30" rows="10" placeholder="Ingrese su mensaje"></textarea> -->
                    <textarea [(ngModel)]="c_obj['message']" name="message" id="#" cols="30" rows="10" placeholder="Ingrese su mensaje"></textarea>
                    <i class="fal fa-edit"></i>

                    <button class="main-btn" type="submit">Inscribirme</button>
                    <!-- <button class="main-btn" type="button" (click)="sendForm(modalform, )">
                      <i class="fas fa-search">{{'Consult' | translate}}</i>
                    </button> -->
                    <!-- <button type="button" class="btn btn-primary submitBtn" onclick="submitContactForm()">SUBMIT</button> -->

                  </div>

                </form>
                <p class="form-message"></p>
              </div>
            </div>
            <!--<div class="col-lg-6">
              <div class="get-map d-none d-lg-block mt-40">
                <img src="assets/images/contact-thumb.jpg" alt="video play">
                <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <!--====== GET IN TOUCH PART ENDS ======-->
    </section>


  </div> <!-- container -->
</div>

<app-footer4></app-footer4>



<!--Modal Send Formulario-->
<ng-template #modalform let-c="close" let-d="dismiss" let-modal>

  <!--ModalHeader-->
  <div class="modal-header head-modal-bg">
		<h4 class="modal-title">
			{{ "Information" | translate }}
		</h4>

		<button (click)="modal.dismiss('Cross click')" type="button" class="close default-color-text" label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

  <!--ModalBody-->
	<div class="modal-body">
		<form class="form-horizontal">
			<div class="card-body">
				<div class="form-group">
					<label> <h3> ¡Éxito! </h3> </label>
				</div>
			</div>
		</form>
	</div>
</ng-template>




