<app-header3></app-header3>
<!--====== BANNER PART START ======-->
<div class="banner-area">
  <div class="container position-relative">
    <div class="row">
      <div class="col-lg-7">
        <div class="banner-content pt-100">
          <!--<span class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">Promote any poduct, service or online course.</span>
          <h1 class="title wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".5s">Get every it Services Here</h1>-->

          <ngx-typed-js [strings]="['Inteligencia de Negocio', 'Software','Tecnología', 'Capacitación Avanzada','Webapps']" backDelay="3000" typeSpeed="10" loop="true">
            <h1 class="heading mb-3">Somos Lider en Negocios digitales para soluciones de <br><span class="typing text-primary"></span> </h1>
          </ngx-typed-js>

          <p class="para-desc text-muted">CONSTRUYE TU NEGOCIO CON LAS TECNOLOGÍAS DE ROYALTIC<br>
            ¡La mejor empresa de consultoría del Ecuador!.</p>
          <ul>
            <li><a class="fadeInUp wow" data-wow-duration="1s" data-wow-delay="1s" href="#"><span><i class="fal fa-gem"></i></span>
              <p>Productos IT</p>
            </a></li>
            <li><a class="btn-2 fadeInUp wow" data-wow-duration="1s" data-wow-delay="1.5s" href="#"><span><i class="fal fa-bullseye"></i></span>
              <p>Servicios IT</p>
            </a>
            </li>
          </ul>
        </div> <!-- banner content -->
      </div>
    </div> <!-- row -->
    <div class="banner-shape d-none d-lg-block">
      <img src="assets/images/banner-man.png" alt="">
    </div>
  </div> <!-- container -->
</div>
<!--<div class="banner-area-3 bg_cover" style="background-image: url(assets/images/banner-bg-3.jpg);">
    <div class="banner-overlay">
        <img class="item-1" src="assets/images/banner-shape-1.png" alt="">
        <img class="item-2" src="assets/images/banner-shape-2.png" alt="">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="banner-content text-center">
                        <span>Get Quality Item & Goods</span>
                        <h1 class="title">IT Solutions</h1>
                        <p>Make your products with good & professionals</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!--====== BANNER PART ENDS ======-->
<!--====== BRAND PART START ======-->
<div class="brand-area brand-active">
    <div class="brand-item">
        <img src="assets/images/brand-1.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-2.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-5.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-4.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-5.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-4.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-1.png" alt="">
    </div> <!-- brand item -->
    <div class="brand-item">
        <img src="assets/images/brand-2.png" alt="">
    </div> <!-- brand item -->
</div>
<!--====== BRAND PART ENDS ======-->
<!--====== FEATURES PART START ======-->
<div class="features-area pt-120 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="features-item">
                    <strong>Nuestra característica</strong>
                    <h2 class="title">Crear aplicaciones convincentes </h2>
                    <span>que impulsen el crecimiento de su negocio.</span>
                    <p>En Royaltic, trabajamos con varios clientes en una amplia gama de industrias para garantizar que se cubran todas sus necesidades de desarrollo de soluciones empresariales.</p>
                    <ul>
                        <!--<li><a class="main-btn wow fadeInUp" routerLink='/about' data-wow-duration=".5s" data-wow-delay=".4s">Aprenda más</a></li>
                        <li><a class="main-btn main-btn-2 wow fadeInUp" href="#" data-wow-duration="1s" data-wow-delay=".6s"> <i class="fal fa-video"></i>Aprenda más</a></li>-->
                    </ul>
                    <img class="d-none d-lg-block" src="assets/images/features-thumb.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features mt-30">
                    <img src="assets/images/services-1.png" alt="">
                    <h4 class="title">Desarrollo Web</h4>
                    <p>Contamos con profesionales capacitados que tienen experiencia práctica en desarrollo web. Nos enorgullece hacer alarde de su sitio web con estilo. Entonces, ¿por qué esperar? Déjenos su requerimiento hoy.</p>
                    <a routerLink='/service-detail'><i class="fal fa-angle-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-features mt-30">
                    <img src="assets/images/services-2.png" alt="">
                    <h4 class="title">Desarrollo móbil</h4>
                    <p>Nos apasiona desarrollar aplicaciones únicas, escalables y atractivas que se ejecuten con éxito en todas las plataformas digitales. Discuta su proyecto en cualquier momento.</p>
                    <a routerLink='/service-detail'><i class="fal fa-angle-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-sub mt-30">
                    <h5 class="title">Suscríbase ahora</h5>
                    <p>Many aspects of computing and technology and the term is more recognizable than before.</p>
                    <form action="#">
                        <div class="input-box">
                            <input type="text" placeholder="Enter your email....">
                            <button class="main-btn">Suscríbase ahora</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== FEATURES PART ENDS ======-->
<!--====== CHOOSE PART START ======-->
<div class="choose-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title">
                    <span>Quienes somos</span>
                    <h3 class="title">Por qué elegirnos</h3>
                </div>
                <div class="choose-cat">
                    <div class="choose-cat-item mt-40 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".4s">
                        <h4 class="title"></h4>
                        <p>Estamos comprometidos en proporcionar un crecimiento exponencial al negocio de nuestros valiosos clientes a través de nuestros impecables servicios de Inteligencia de Negocios.</p>
                        <i class="fal fa-check"></i>
                    </div>
                    <div class="choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".6s">
                        <h4 class="title"></h4>
                        <p>Tenemos la actitud resuelta, valiente y ganadora para ofrecer un servicio Analítico de primera calidad. Somos conocidos por utilizar un enfoque integral que ayuda a su empresa a optimizar procesos.</p>
                        <i class="fal fa-check"></i>
                    </div>
                    <div class="choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
                        <h4 class="title"></h4>
                        <p>Estamos ejecutando con éxito más de un centenar de proyectos, que también exudan el testimonio de nuestra competencia en el mercado competitivo. Por favor revise todos los testimonios.</p>
                        <i class="fal fa-check"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="choose-thumb-area mt-30">
                    <div class="choose-thumb-1">
                        <img class="item-1" src="assets/images/choose-thumb-1.jpg" alt="">
                    </div>
                    <div class="choose-thumb-2">
                        <img class="item-2" src="assets/images/choose-thumb-2.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="choose-video-thumb mt-115">
                    <img src="assets/images/choose-video-thumb.jpg" alt="">
                    <a href="#"><i class="fal fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== CHOOSE PART ENDS ======-->
<!--====== PORTFOLIO 3 PART START ======-->
<div class="portfolio-3-area pt-115 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="section-title text-center">
                    <span>Portafolio</span>
                    <h2 class="title">Nosotros hemos hecho tantas cosas</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                <div class="single-portfolio mt-30">
                    <div class="portfolio-thumb">
                        <img src="assets/images/portfolio-thumb-1.jpg" alt="">
                    </div>
                    <div class="portfolio-content">
                        <span>Digital Computing</span>
                        <a routerLink='/case-detail'>
                            <h4 class="title">Aspects of computing and technology and the term</h4>
                        </a>
                        <p>Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".4s">
                <div class="single-portfolio mt-30">
                    <div class="portfolio-thumb">
                        <img src="assets/images/portfolio-thumb-2.jpg" alt="">
                    </div>
                    <div class="portfolio-content">
                        <span>it consultant</span>
                        <a routerLink='/case-detail'>
                            <h4 class="title">Today, the term Informati on Technology (IT)</h4>
                        </a>
                        <p>Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.4s" data-wow-delay=".6s">
                <div class="single-portfolio mt-30">
                    <div class="portfolio-thumb">
                        <img src="assets/images/portfolio-thumb-3.jpg" alt="">
                    </div>
                    <div class="portfolio-content">
                        <span>design & development</span>
                        <a routerLink='/case-detail'>
                            <h4 class="title">Has ballooned to encomp ass many aspects</h4>
                        </a>
                        <p>Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".8s">
                <div class="single-portfolio mt-30">
                    <div class="portfolio-thumb">
                        <img src="assets/images/portfolio-thumb-4.jpg" alt="">
                    </div>
                    <div class="portfolio-content">
                        <span>ui/ux</span>
                        <a routerLink='/case-detail'>
                            <h4 class="title">Aspects of computing and technology and the term</h4>
                        </a>
                        <p>Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.8s" data-wow-delay="1s">
                <div class="single-portfolio mt-30">
                    <div class="portfolio-thumb">
                        <img src="assets/images/portfolio-thumb-5.jpg" alt="">
                    </div>
                    <div class="portfolio-content">
                        <span>game design</span>
                        <a routerLink='/case-detail'>
                            <h4 class="title">Today, the term Informati on Technology (IT)</h4>
                        </a>
                        <p>Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.2s">
                <div class="single-portfolio mt-30">
                    <div class="portfolio-thumb">
                        <img src="assets/images/portfolio-thumb-6.jpg" alt="">
                    </div>
                    <div class="portfolio-content">
                        <span>interior design</span>
                        <a routerLink='/case-detail'>
                            <h4 class="title">Has ballooned to encomp ass many aspects</h4>
                        </a>
                        <p>Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== PORTFOLIO 3 PART ENDS ======-->
<!--====== COUNTER PART START ======-->
<div class="counter-area bg_cover pt-80 pb-115" style="background-image: url(assets/images/video-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item mt-30">
                    <h3 class="title"><span class="counter">869</span>+</h3>
                    <p>Project We Have Done</p>
                    <i class="fal fa-box"></i>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item mt-30">
                    <h3 class="title"><span class="counter">100</span>+</h3>
                    <p>People Involved With Us</p>
                    <i class="fal fa-users"></i>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item mt-30">
                    <h3 class="title"><span class="counter">50</span>+</h3>
                    <p>We Are Worldwide</p>
                    <i class="fal fa-globe"></i>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item mt-30">
                    <h3 class="title"><span class="counter">10</span>+</h3>
                    <p>We Got Too Much Awards</p>
                    <i class="fal fa-award"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== COUNTER PART ENDS ======-->
<!--====== FAQ PART START ======-->
<div class="faq-area faq-area-3 pt-115">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 d-none d-sm-block">
                <div class="faq-thumb">
                    <div class="faq-thumb-1 text-right">
                        <img src="assets/images/faq-thumb-1.jpg" alt="">
                    </div>
                    <div class="faq-thumb-2">
                        <img src="assets/images/faq-thumb-2.jpg" alt="">
                    </div>
                </div> <!-- faq thumb -->
            </div>
            <div class="col-lg-6 order-1 order-lg-2">
                <div class="section-title text-left">
                    <span>faq</span>
                    <h2 class="title">Get Every Single Answers Here.</h2>
                </div> <!-- section title -->
                <div class="faq-accordion">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Today, the term Information Technology
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Over the years, a wide range of developments
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    As a result, most of us need to know
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Our knowledge of computers will help us
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- faq accordion -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== FAQ PART ENDS ======-->
<!--====== GET IN TOUCH PART START ======-->
<div class="get-in-touch-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title text-left">
                    <span>Get In Touch</span>
                    <h2 class="title">Estimate For Your Projects.</h2>
                </div> <!-- section title -->
                <div class="form-area">
                    <form action="#">
                        <div class="input-box pt-45 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">
                            <input type="text" placeholder="Enter your name">
                        </div>
                        <div class="input-box mt-20 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".4s">
                            <input type="email" placeholder="Enter your email">
                        </div>
                        <div class="input-box mt-20 wow fadeInLeft" data-wow-duration="1.4s" data-wow-delay=".6s">
                            <textarea name="#" id="#" cols="30" rows="10" placeholder="Enter your message"></textarea>
                        </div>
                        <div class="input-box mt-20">
                            <button class="main-btn wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".2s" type="submit">Submit Now</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="get-map d-none d-lg-block">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993207.564767118!2d-77.9807899414373!3d42.92219345357043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1569310771254!5m2!1sen!2sbd" width="600" height="450" style="border:0;" allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== GET IN TOUCH PART ENDS ======-->
<app-footer4></app-footer4>
<!--  Start Style switcher  -->
<div id="style-switcher" style="left: -189px;" class="bg-light border p-3 pt-2 pb-2">
  <h3 class="title text-center">Select Your Color</h3>
  <ul class="pattern text-center mb-2">
    <li class="list-inline-item">
      <a class="default" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="green" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="red" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="skyblue" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="skobleoff" href="#"></a>
    </li>
    <li class="list-inline-item">
      <a class="cyan" href="#"></a>
    </li>
  </ul>

  <h3 class="title text-center pt-3 mb-0 border-top">Theme Option</h3>
  <div class="text-center">
    <a href="#" class="btn btn-sm w-100 btn-primary rtl-version t-rtl-light mt-2">RTL</a>
    <a href="#" class="btn btn-sm w-100 btn-primary ltr-version t-ltr-light mt-2">LTR</a>
    <a href="#" class="btn btn-sm w-100 btn-primary dark-rtl-version t-rtl-dark mt-2">RTL</a>
    <a href="#" class="btn btn-sm w-100 btn-primary dark-ltr-version t-ltr-dark mt-2">LTR</a>
    <a href="#" class="btn btn-sm w-100 btn-dark dark-version t-dark mt-2">Dark</a>
    <a href="#" class="btn btn-sm w-100 btn-dark light-version t-light mt-2">Light</a>
  </div>
  <div class="bottom">
    <a href="#" class="whatsapp bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-whatsapp text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="linkedin bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-tiktok text-primary"></i></a>
  </div>
  <!--<div class="bottom">
    <a href="#" class="linkedin bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-linkedin-in text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="facebook bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-facebook text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="twitter bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-twitter text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="instagram bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-instagram text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="youtube bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-youtube text-primary"></i></a>
  </div>
  <div class="bottom">
    <a href="#" class="vk bg-white shadow d-block"><i class="mdi mdi-cog ml-1 mdi-24px position-absolute fab fa-vk text-primary"></i></a>
  </div>-->
</div>
