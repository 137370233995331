<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/Qlik.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Qlik</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Qlik</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Gestor de transformación</h2>
          <p>Qlik Sense es una plataforma completa de análisis de datos que establece estándares para una nueva generación de análisis. Con su motor asociativo de análisis único, inteligencia artificial sofisticada y una potente plataforma en la nube, puede brindar a todos en su organización la capacidad de tomar mejores decisiones cada día, creando una empresa verdaderamente basada en datos.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/poder.jpg" alt="">
          <div class="services-overlay">
            <h4 class="title" style="padding-left: 0px;">El poder de la analítica a su alcance.</h4>
            <p>Qlik Sense es para todos: ejecutivos, directivos, analistas... y para usted. Podrá dejar que los usuarios busquen y exploren con libertad para descubrir conocimientos que no encontrarían con herramientas de BI basadas en consultas.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/alfabetizacion.jpg" alt="">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Personal alfabetizado en datos con IA y la interacción conversacional.</h4>
            <p>La inteligencia aumentada mejora la alfabetización de datos de un usuario, ya que sugiere conocimientos y automatiza procesos a la vez que entiende preguntas y responde con un lenguaje natural.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/rendimiento.jpg" alt="">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Experimente un rendimiento inigualable.</h4>
            <p>Escale con confianza con una potente integración de datos, API abiertas y un gobierno flexible. Experimente un rendimiento inigualable, en SaaS corporativo o en su nube.</p>

          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>

<!--====== COUNTER PART START ======-->
<div class="counter-area bg_cover mt-70 pt-50 pb-15" style="background-image: url(assets/images/video-bg.jpg);">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title text-white">Análisis de datos moderno toda su empresa.</h2>
          <p class="text-white">Ofrezca una experiencia analítica espectacular diseñada para transformar todo su negocio.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".2s">
        <div class="single-experience mt-30 bg-white text-center" style="padding: 48px 32px 20px;min-height: 397px;">
          <img src="https://www.qlik.com/es-es/-/media/images/global-us/site-content/products/qlik-sense-2019/icon-explore-without-boundaries-1x.png" alt="">
          <div class="experience-overlay">
            <h5 class="title pt-3 pb-3">Explore sin límites</h5>
            <p>Qlik Sense indexa y comprende todas las relaciones en sus datos para que pueda explorar libremente y en cualquier dirección. Es como tener un visor de rayos X que le revela conocimientos imposibles de observar con herramientas de BI basadas en consultas.</p>
            <!--<a routerLink='/service-detail'>Leer más</a>
            <i class="fal fa-laptop-code"></i>-->
          </div>
        </div> <!-- single experience -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
        <div class="single-experience mt-30 bg-white text-center" style="padding: 48px 32px 20px;min-height: 397px;">
          <img src="https://www.qlik.com/es-es/-/media/images/global-us/site-content/products/qlik-sense-2019/icon-analytics-faster-1x.png" alt="">
          <div class="experience-overlay">
            <br>
            <h5 class="title pt-3 pb-3">Cree analítica mucho más rápido</h5>
            <p>Cargue y combine datos con facilidad, genere visualizaciones inteligentes y use la función arrastrar y soltar para construir apps analíticas potentes aceleradas por las sugerencias y la automatización de la IA.</p>
            <!--<a routerLink='/service-detail'>Leer más</a>
            <i class="fal fa-headphones-alt"></i>-->
          </div>
        </div> <!-- single experience -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".6s">
        <div class="single-experience mt-30 bg-white text-center" style="padding: 48px 32px 20px;min-height: 397px;">
          <img src="https://www.qlik.com/es-es/-/media/images/global-us/site-content/products/qlik-sense-2019/icon-ai-amplify-1x.png" alt="">
          <div class="experience-overlay">
            <h5 class="title pt-3 pb-3">Deje que la IA amplifique su mente</h5>
            <p>La inteligencia aumentada mejora la intuición humana mediante Qlik Sense gracias a los conocimientos sugeridos, los procesos automatizados y la interacción con lenguaje natural.</p>
            <!--<a routerLink='/service-detail'>Leer más</a>
            <i class="fal fa-analytics"></i>-->
          </div>
        </div> <!-- single experience -->
      </div>
    </div> <!-- row -->
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".2s">
        <div class="single-experience mt-30 bg-white text-center" style="padding: 48px 32px 20px;min-height: 397px;">
          <img src="https://www.qlik.com/es-es/-/media/images/global-us/site-content/products/qlik-sense-2019/icon-share-and-go-mobile-1x.png" alt="">
          <div class="experience-overlay">
            <h5 class="title pt-3 pb-3">Para compartir y en versión móvil</h5>
            <p>Mantenga la productividad, ya sea online o sin conexión con análisis móviles totalmente interactivos. Haga descubrimientos, colabore fácilmente y tome decisiones basadas en datos, en cualquier lugar y en cualquier momento.</p>
            <!--<a routerLink='/service-detail'>Leer más</a>
            <i class="fal fa-laptop-code"></i>-->
          </div>
        </div> <!-- single experience -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
        <div class="single-experience mt-30 bg-white text-center" style="padding: 48px 32px 20px;min-height: 397px;">
          <img src="https://www.qlik.com/es-es/-/media/images/global-us/site-content/products/qlik-sense-2019/icon-build-extend-embed-1x.png" alt="">
          <div class="experience-overlay">
            <h5 class="title pt-3 pb-3">Construir, ampliar, integrar</h5>
            <p>Desarrolle cualquier solución analítica que pueda imaginar con un conjunto completo de API abiertas y llévelas a cualquier aplicación, proyecto o proceso con un poderoso soporte de análisis integrado.</p>
            <!--<a routerLink='/service-detail'>Leer más</a>
            <i class="fal fa-headphones-alt"></i>-->
          </div>
        </div> <!-- single experience -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".6s">
        <div class="single-experience mt-30 bg-white text-center" style="padding: 48px 32px 20px;min-height: 397px;">
          <img src="https://www.qlik.com/es-es/-/media/images/global-us/site-content/products/qlik-sense-2019/icon-scale-across-cloud-1x.png" alt="">
          <div class="experience-overlay">
            <h5 class="title pt-3 pb-3">Ampliación en toda la nube</h5>
            <p>Combine fácilmente las opciones de implementación SaaS, multinube híbrida y en las instalaciones con la única arquitectura realmente multinube del sector. La elección es suya.</p>
            <!--<a routerLink='/service-detail'>Leer más</a>
            <i class="fal fa-analytics"></i>-->
          </div>
        </div> <!-- single experience -->
      </div>
    </div> <!-- row -->

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="blog-standard">
          <div class="single-blog-standard mt-10">
            <div class="blog-dteails-content" style="border: unset">
              <div class="blog-details-top">
                <h2 class="title text-white">¿Nuestro ingrediente secreto? El motor asociativo y la inteligencia aumentada</h2>

                <p class="mt-20 pb-0 text-white">Qlik Sense multiplica el poder de la intuición humana combinando la exploración asociativa libre con la automatización y las sugerencias de conocimientos generadas por IA; todo para que los usuarios hagan nuevos descubrimientos y encuentren conocimientos inesperados. No hay ninguna otra tecnología de BI comparable.</p>

              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="blog-standard">
          <div class="single-blog-standard mt-10">
            <div class="blog-dteails-content" style="border: unset">
              <div class="blog-details-top">
                <h2 class="title text-white">La conveniencia de SaaS. La elección de múltiples nubes.</h2>

                <p class="mt-20 pb-0 text-white">Nuestra plataforma de análisis de datos se basa en una arquitectura de múltiples nubes. Permite a las empresas implementar amplias opciones de análisis y admitir números de usuarios y volúmenes de datos de todos los tamaños sin comprometer los estándares de rendimiento, confiabilidad y gobierno.</p>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== COUNTER PART ENDS ======-->
<!--====== NEWS PART START ======-->
<div class="news-area">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-9">
        <div class="section-title-2 text-center pl-25 pr-25">
          <h2 class="title">Cualquier requisito de BI. Una plataforma.</h2>
          <p>¿Le gustaría transformar su empresa? Unifique todos sus datos en una plataforma de análisis de datos, con la que puede implementar cada aplicación y dar soporte a cada empleado, independientemente de su nivel de conocimiento.</p>
        </div> <!-- section title 2 -->
      </div>
    </div> <!-- row -->
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-1.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Autoservicio</a></h5>
            <span>Ya sea que esté creando un portal web empresarial o un sitio web de última generación, siempre necesita las herramientas modernas adecuadas. Los marcos PHP bien construidos y mantenidos proporcionan esas herramientas en abundancia, lo que permite que los marcos PHP mantenidos proporcionen esas herramientas en abundancia, lo que permite a los desarrolladores ahorrar tiempo, reutilizar código y optimizar el back-end. Como herramientas de desarrollo de software de forma continua.</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-2.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Conversacional</a></h5>
            <span>Nuestra analítica conversacional ofrece un método rápido y sencillo para responder preguntas y descubrir conocimientos con el lenguaje natural, tanto desde Qlik Sense como desde otras herramientas populares como Slack, Skype y Microsoft Teams. Con cada pregunta aparecen al instante gráficos y conocimientos importantes, incluidos factores clave, comparaciones, predicciones, etc. Con una analítica tan sencilla, la cantidad de personas que puede tomar decisiones basadas en datos es más grande que nunca antes.</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-3.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Cuadros de mando</a></h5>
            <span>Cree, comparta y distribuya potentes cuadros de mando para ayudar a ejecutivos, líderes empresariales y cualquier persona que necesite mantenerse al día del negocio. Todos los elementos son interactivos y lleva a los usuarios más allá de los los cuadros de mando estáticos del pasado. Cuando algo destaque, podrá explorarlo fácilmente para ver qué está pasando. Las potentes funciones de búsqueda global y selección le permiten centrarse en la información que necesita para entender la situación y así tomar medidas.</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>
    </div> <!-- row -->

    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-4.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Personalizada e integrada</a></h5>
            <span>Qlik Sense es una plataforma de análisis de datos completa con la que puede dominar con éxito incluso desafíos de análisis complejos. El completo conjunto de API abiertas le permite personalizar del todo las soluciones analíticas. Desarrolle con agilidad apps personalizadas, nuevas visualizaciones y extensiones, o integre una analítica totalmente interactiva en las aplicaciones y procesos con los que la gente trabaja cada día.</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-5.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Móvil</a></h5>
            <span>Qlik Sense está diseñado para la movilidad y cuenta de manera nativa con diseño sensible e interacción táctil. Cree y explore analítica con facilidad, y colabore con su equipo desde cualquier dispositivo. Diseñe apps analíticas una vez y úselas donde quiera. Además, dispone de un análisis sin conexión totalmente interactivo en iOS, así como asistencia para las principales plataformas de Gestión de Movilidad Empresarial (EMM).</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-6.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Elaboración de informes</a></h5>
            <span>Diseñe, publique y comparta rápidamente informes profesionales en formatos populares, como Microsoft® Office y PDF de píxel perfecto. Use la función arrastrar y soltar con gráficos, tablas y cualquier objeto de las apps analíticas de Qlik Sense empleando un diseño de gran flexibilidad y controles de formato para crear cualquier informe que sea necesario. Puede programar sus informes fácilmente e incluso personalizarlos de manera individual para enviarlos por correo electrónico.</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>
    </div> <!-- row -->

    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="single-news mt-30">
          <img src="assets/images/qlik-7.jpg" alt="">
          <div class="single-news-overlay">

            <h5 class="title"><a routerLink="#">Alerting</a></h5>
            <span>Ayude a los usuarios a monitorizar proactivamente su negocio y a tomar decisiones rápidas con alertas avanzadas basadas en datos. Notifique a los usuarios los problemas potenciales para que puedan analizarlos de inmediato y tomar decisiones basadas en los conocimientos. Gracias a las alertas de autoservicio y centralizadas, los usuarios pueden gestionar en función de las excepciones y las empresas pueden difundir conocimientos a grupos grandes.</span>
            <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div> <!-- single news -->
      </div>

    </div> <!-- row -->
  </div> <!-- container -->
</div>
<!--====== NEWS PART ENDS ======-->
<!--====== BLOG STANDARD PART START ======-->
<!--<div class="blog-standard-area pt-5 pb-1">
  <div class="container">

  </div>
</div>-->
<!--====== BLOG STANDARD PART ENDS ======-->

<!--====== CHOOSE PART START ======-->
<!--<div class="choose-area pt-5">
  <div class="container">
    <div class="row mt-30">
      <div class="col-lg-12">
        <div class="section-title text-center">

          <h4 class="title">¿Cómo puede AuraQuantic ayudarme?</h4>
        </div>
        <div class="text-center">
          <p>AuraQuantic satisfará las necesidades de su empresa en estos tiempos tan cambiantes:</p>
        </div>
        <div class="choose-cat mt-20">

          <section id="feature" class="feature circle-top pt-30 pb-0">
            <div class="container">
              <div class="row">
                <div class="col-md-6 mb-xs-5">
                  <div class="feature-box text-center">

                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-01.png" alt="QLIK" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
             <span style="color: #303030;">
              <span style="font-size: 18px;">
               <strong>Transformación digital</strong>
              </span>
             </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
             <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Reduce costes y tiempo<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Reduce el control burocrático<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>El conocimiento se queda en la empresa<br>
                        <span style="color: #1785b0;">
              <strong>·</strong>&nbsp;
             </span>Genera menos documentos
                      </p>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 mb-xs-5">
                  <div class="feature-box text-center">

                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-03.png" alt="QLIK" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
             <span style="color: #303030;">
              <span style="font-size: 18px;">
               <strong>Decisión analítica</strong>
              </span>
             </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
             <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Mejora la toma de decisiones<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Aumento de la capacidad analítica<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Predicción de resultados<br>
                        <span style="color: #1785b0;">
              <strong>·</strong> &nbsp;
             </span>Coherencia en la información
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="feature-box text-center">

                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-02.png" alt="CAPACITACIONES" width="50" height="50">
                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
              <span style="color: #303030;">
               <span style="font-size: 18px;">
                <strong>Control organizacional</strong>
               </span>
              </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
              <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Acelera la ejecución de tareas<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Optimiza la eficiencia<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Impulsa la productividad<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Mejora la comunicación interna
                      </p>
                    </div>
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="feature-box text-center">

                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-06.png" alt="SERVICIOS" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
              <span style="color: #303030;">
               <span style="font-size: 18px;">
                <strong>Adaptación al cambio</strong>
               </span>
              </span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
              <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Elimina la resistencia al cambio<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Amplía las oportunidades comerciales<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Nuevas estrategias de negocio<br>
                        <span style="color: #1785b0;">
               <strong>·</strong> &nbsp;
              </span>Mejora continua
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="feature-box text-center">

                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-05.png" alt="SERVICIOS" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
														<span style="color: #303030;">
															<span style="font-size: 18px;">
																<strong>Cumplimiento normativo</strong>
															</span>
														</span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
														<span style="color: #1785b0;">
															<strong>·</strong> &nbsp;
														</span>Elimina errores<br>
                        <span style="color: #1785b0;">
															<strong>·&nbsp;</strong>
														</span>Incrementa la seguridad</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="feature-box text-center">

                    <img src="https://www.auraquantic.com/wp-content/uploads/2020/07/quantum-04.png" alt="SERVICIOS" width="50" height="50">

                    <div class="wpb_wrapper">
                      <p style="font-weight: bold; font-size: 18px; margin-bottom: 10px; color: #303030; text-align: center;">
															<span style="color: #303030;">
																<span style="font-size: 18px;">
																	<strong>Experiencia del cliente</strong>
																</span>
															</span>
                      </p>
                      <p style="font-weight: 400; font-size: 16px; color: #939598; text-align: center;">
															<span style="color: #1785b0;">
																<strong>·</strong> &nbsp;
															</span>Satisface a los clientes<br>
                        <span style="color: #1785b0;">
																<strong>·</strong> &nbsp;
															</span>Supera sus expectativas
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>


        </div>
      </div>

    </div>
    <div class="row mt-30">
      <p>AuraQuantic es la tendencia consolidada e imparable que está cambiando la forma de gestionar la operativa en todo tipo de empresas y organizaciones a nivel mundial, en todas las industrias, haciéndolas mucho más flexibles, altamente automatizadas y extremadamente potentes.</p>
    </div>
  </div>
</div>-->
<!--====== CHOOSE PART ENDS ======-->
<!--<div class="services-item-area pt-15 pb-10">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Tendencias</h2>
        </div>
        <p>Conocimientos tecnológicos, noticias, consejos, procedimientos y guías que le ayudarán a identificar tecnologías y estrategias para capacitar a sus trabajadores, optimizar los procesos comerciales y preparar su empresa para el futuro.</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/automatizacion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">INFORME GARTNER</h4>
            <p>Vaya más allá del RPA y alcance la hiperautomatización</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/comunicado.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">PEER INSIGHTS</h4>
            <p>4.8 en Enterprise Low-Code Application Platforms</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/pococodigo.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">LOW-CODE</h4>
            <p>El árbol de decisión para las categorías Low-Code</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/computo.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">ANALISTAS</h4>
            <p>¿Qué opinan los analistas de nosotros?</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/hiperautomatizado.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">HIPERAUTOMATIZACIÓN</h4>
            <p>Hiperautomatización y teletrabajo</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/inteligencia.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">INTELIGENCIA ARTIFICIAL</h4>
            <p>Cree aplicaciones inteligentes con Azure AI</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--====== SERVICES ITEM PART ENDS ======-->
<!--====== TEAM DETAILS PART START ======-->
<!--<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<!--<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<!--<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
