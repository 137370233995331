<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Our Shop</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Shop</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SHOP PART START ======-->
<div class="shop-area gray-bg pt-100 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="row align-items-center mb-10">
                    <div class="col-lg-4 col-md-4 order-2 order-md-1">
                        <div class="shop-top-bar">
                            <p>Showing 1–09 of 42 Results</p>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 order-1 order-md-1">
                        <div class="shop-top-bar">
                            <form action="#">
                                <div class="input-box">
                                    <input type="text" placeholder="Search Keywords">
                                    <button><i class="fal fa-search"></i></button>
                                </div>
                            </form>
                            <select>
                                <option value="1">Short by</option>
                                <option value="2">Another 1</option>
                                <option value="2">Another 2</option>
                                <option value="2">Another 3</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-1.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-1.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Golden Chair</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-2.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-2.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Blue Table</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-3.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-3.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Amazing Charge Light</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-4.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-4.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Minimal Chair</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-5.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-5.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Light Bulb</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-6.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-6.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Wood Furniture</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-7.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-7.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Wood Chair</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-8.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-8.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Black Mouse Pad</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-9.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-9.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Rolling Black Paper</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-10.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-10.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Minimal Black Chair</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-11.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-11.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Ciramic Round Table</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <div class="shop-item mt-30">
                            <div class="shop-thumb">
                                <img src="assets/images/shop-12.jpg" alt="">
                                <div class="shop-overlay">
                                    <ul>
                                        <li><a href="#"><i class="fal fa-shopping-cart"></i></a></li>
                                        <li><a href="#"><i class="fal fa-heart"></i></a></li>
                                        <li><a class="image-popup" href="assets/images/shop-12.jpg"><i class="fal fa-eye"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="shop-content white-bg text-center">
                                <a routerLink='/shop-detail'>
                                    <h5 class="title">Kedara Black Mantis</h5>
                                </a>
                                <ul>
                                    <li>$45.99</li>
                                    <li>$30.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="mt-40" aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item">
                                    <a class="page-link" href="#" tabindex="-1" aria-disabled="true"><i class="fal fa-arrow-left"></i></a>
                                </li>
                                <li class="page-item"><a class="page-link active" href="#">01</a></li>
                                <li class="page-item"><a class="page-link" href="#">02</a></li>
                                <li class="page-item"><a class="page-link" href="#">03</a></li>
                                <li class="page-item"><a class="page-link" href="#">...</a></li>
                                <li class="page-item"><a class="page-link" href="#">10</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#"><i class="fal fa-arrow-right"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-5 col-sm-7">
                <app-shopsidebar></app-shopsidebar>
            </div>
        </div>
    </div>
</div>
<!--====== SHOP PART ENDS ======-->
<app-footer2></app-footer2>
