<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/InteligenciaArtificial.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Inteligencia Artificial</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Inteligencia Artificial</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-0">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Inteligencia Artificial</h2>
          <p>La inteligencia artificial supone la base de todo el aprendizaje automático y el futuro de todos los procesos complejos de toma de decisiones. La inteligencia artificial, su evolución lógica del aprendizaje automático y el aprendizaje profundo constituyen los cimientos del futuro en la toma de decisiones empresariales. Los alcances de la inteligencia artificial en la actualidad son verdaderamente sorprendentes.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title"></h2>
          <p>.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Tipos de Inteligencia Artificial</h2>
          <p></p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-1.jpg" alt="⦁">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Artificial Narrow Intelligence (ANI)</h4>
            <p style="line-height: 17px;">Inteligencia artificial más inflexible, que no se amolda ni se adapta a los requisitos de un sistema o máquina en particular, su función es centrarse en un trabajo único y dedicar toda su complejidad al mismo, como asistente los asistentes virtuales.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-2.jpg" alt="⦁	">
          <div class="services-overlay">
            <h4 class="title" style="padding-left: 0px;">Inteligencia Artificial General (AGI)</h4>
            <p style="line-height: 17px;">AGI se considera fuerte y profunda, como una máquina capaz de imitar la inteligencia humana y con una vasta capacidad de acción. En su comportamiento, ella puede aprender y, con base en eso, replicar actitudes para resolver diferentes cuestiones. Eso es lo que la convierte en uno de los modelos más versátiles disponibles actualmente.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-3.jpg" alt="⦁	">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Superinteligencia Artificial</h4>
            <p style="line-height: 17px;">Este tipo de inteligencia artificial es considerada la más poderosa, precisamente porque es la máquina capaz de volverse consciente y autónoma. En lugar de simplemente replicar el comportamiento humano, así supera esa capacidad. Incluso se la considera capaz de pensar mejor y de ser más hábil 	que nosotros. Esta es una categoría de inteligencia artificial que aún está en desarrollo y constante mejora, aunque en estado avanzado.</p>

          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
    <div class="row justify-content-center">

      <!-- single services -->
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-4.jpg" alt="⦁	">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Máquinas reactivas</h4>
            <p style="line-height: 17px;">Entre los tipos de inteligencia artificial, las máquinas reactivas son las más simples y 	antiguas que se conocen. Es un modelo introductorio y tiene una capacidad limitada. Su 	función es simple: reproducir el 	comportamiento humano cuando es estimulado, 	o sea, 	solo una acción 	reactiva. No tienen memoria y por ende no tienen capacidad de aprender 	y administrar una base de datos interna para ejecutar lo que absorben.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-5.jpg" alt="⦁	">
          <div class="services-overlay">
            <h4 class="title" style="padding-left: 0px;">Memoria Limitada</h4>
            <p style="line-height: 17px;">Las máquinas de memoria limitada son, como en el ejemplo anterior, completamente reactivas, pero con la ventaja de tener una pequeña cantidad de memoria disponible. Eso las hace más avanzadas, ya que esa función las permite aprender de los datos. Cada vez que se exponen a información son capaces de generar aprendizajes a partir de ese contenido creando bases de datos a partir de sus interacciones a través del tiempo. Ej. Chatbots.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-6.jpg" alt="⦁	">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Teoría de la Mente </h4>
            <p style="line-height: 17px;">La Teoría de la Mente es un tipo de inteligencia artificial en el que se ha trabajado mucho en los últimos años, actualmente se ve como un futuro, la idea es que será uno de los modelos más innovadores jamás vistos. La propuesta es que pueda comprender mejor las interacciones a las que está expuesta. Así, la Teoría de la Mente trata precisamente de las emociones, necesidades y procesos de reflexión que presenta la mente humana.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-ia-7.jpg" alt="⦁	">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Autoconciencia </h4>
            <p style="line-height: 17px;">La autoconciencia es solo una idea, es decir, un concepto que guía el desarrollo de la inteligencia artificial. Por ahora, todavía no hay creaciones concretas, se busca que en el futuro las máquinas serán conscientes de sí mismas. Ese es el nivel de desarrollo más alto que puede alcanzar la inteligencia artificial y, por supuesto, lleva tiempo y muchos esfuerzos en poder comprender todas las emociones, tener las suyas y comprender cada detalle que pasa con quienes interactúan con ella. Por ambicioso que parezca, es innegable que ese es el camino que los científicos tienen como horizonte para alcanzar ese nivel.</p>

          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title"></h2>
          <p></p>
        </div>
      </div>
    </div>
  </div> <!-- container -->
</div>
<!--====== SERVICES ITEM PART ENDS ======-->

<app-footer4></app-footer4>
