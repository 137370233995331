<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Lo que se mide, se gestiona</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Lo que se mide, se gestiona</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="blog-standard">
                    <div class="single-blog-standard  mt-30">
                        <div class="blog-dteails-content blog-border">
                            <div class="blog-details-top">
                                <span>Businese</span>
                                <h2 class="title">Lo que se mide, se gestiona</h2>
                                <ul>
                                    <li><i class="fal fa-eye"></i> 232 Views</li>
                                    <li><i class="fal fa-comments"></i> 35 Comments</li>
                                    <li><i class="fal fa-calendar-alt"></i> 24th March 2019</li>
                                </ul>
                                <p>Muchas empresas han comenzado a incorporar la analítica de datos como un factor determinante frente a su competencia para generar mayor valor a sus productos. La gran realidad es que la mayoría de empresas ya tienen estos datos en algún rincón de su infraestructura o de sus pc, generalmente dispersa. La Analítica de Datos busca y genera información con estos datos, consolidándolos y ofreciendo una vista integrada de estos, incluso con información fuera de su red.</p>
                                <p class="mt-m2">Esto último, es precisamente uno de los retos actuales. El uso de datos externos como información de competencia, organismos de control, registros financieros y redes sociales son también necesarios para tener un conocimiento más amplio del negocio y las empresas apenas están incluyendo estos datos a sus análisis.</p>
                              <p class="mt-m2">Esto último, es precisam¿Como abordamos estos retos actuales? ¿Con Tecnología? Ciertamente que la tecnología ayuda a la consecución de estos retos, sin embargo, la respuesta está en los procesos y la data. La acertada combinación de datos, proceso e Innovación otorgan grandes ventajas a las empresas e industrias. Cuantas más fuentes de datos usen, el conocimiento será más amplio, permitiendo medir permanentemente los principales indicadores de rendimiento y así gestionar y alinear de mejor manera sus procesos.</p>
                            </div>
                          <div class="blog-details-bath">
                            <img src="assets/images/blog-details-thumb.jpg" alt="blog-details">
                          </div>

                            <div class="blog-details-bar d-flex justify-content-between mt-40 mb-50">
                                <div class="blog-tags">
                                    <h4 class="title">Releted Tags</h4>
                                    <ul>
                                        <li><a routerLink='/blog-detail'>Popular</a></li>
                                        <li><a routerLink='/blog-detail'>desgin</a></li>
                                        <li><a routerLink='/blog-detail'>ux</a></li>
                                    </ul>
                                </div>
                                <div class="blog-social text-right">
                                    <h4 class="title">Social Share</h4>
                                    <ul>
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-typo3"></i></a></li>
                                        <li><a href="#"><i class="fab fa-staylinked"></i></a></li>
                                        <li><a href="#"><i class="fab fa-tumblr"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="blog-details-next-prev d-flex justify-content-between pt-45 pb-45">
                                <div class="post-prev">
                                    <a routerLink='/blog-detail'>
                                        <span>Prev Post</span>
                                        <h4 class="title">Tips On Minimalist</h4>
                                    </a>
                                </div>
                                <div class="post-next text-right">
                                    <a routerLink='/blog-detail'>
                                        <span>Next Post</span>
                                        <h4 class="title">Less Is More</h4>
                                    </a>
                                </div>
                                <a href="#"><img src="assets/images/dot-box.png" alt=""></a>
                            </div>
                            <div class="blog-details-releted-post mt-45">
                                <h4 class="title">Releted Post</h4>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="blog-details-releted-item mt-30">
                                            <div class="releted-thumb">
                                                <img src="assets/images/releted-1.jpg" alt="">
                                            </div>
                                            <div class="releted-content">
                                                <span><i class="fal fa-calendar-alt"></i> 24th March 2019</span>
                                                <a routerLink='/blog-detail'>
                                                    <h4 class="title">A series of iOS 7 inspire vector icons sense.</h4>
                                                </a>
                                                <p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="blog-details-releted-item mt-30">
                                            <div class="releted-thumb">
                                                <img src="assets/images/releted-2.jpg" alt="">
                                            </div>
                                            <div class="releted-content">
                                                <span><i class="fal fa-calendar-alt"></i> 24th March 2019</span>
                                                <a routerLink='/blog-detail'>
                                                    <h4 class="title">A series of iOS 7 inspire vector icons sense.</h4>
                                                </a>
                                                <p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="blog-details-written mt-50">
                                <div class="written-area d-flex align-items-center">
                                    <div class="written-thumb">
                                        <img src="assets/images/writter.jpg" alt="">
                                    </div>
                                    <div class="written-content">
                                        <span>Written by</span>
                                        <h4 class="title">Rosalina D. William</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation is enougn for today.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="blog-details-comments mt-45">
                                <h4 class="title">03 Comments</h4>
                                <div class="blog-comments-area">
                                    <div class="d-flex justify-content-between align-items-start">
                                        <div class="blog-left">
                                            <h6 class="title">Rosalina Kelian</h6>
                                            <span>19th May 2018</span>
                                        </div>
                                        <div class="blog-right">
                                            <a href="#"><i class="fal fa-reply"></i> Reply</a>
                                        </div>
                                    </div>
                                    <img src="assets/images/blog-cmnt-1.png" alt="">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                                <div class="blog-comments-area ml-125">
                                    <div class="d-flex justify-content-between align-items-start">
                                        <div class="blog-left">
                                            <h6 class="title">Rosalina Kelian</h6>
                                            <span>19th May 2018</span>
                                        </div>
                                        <div class="blog-right">
                                            <a href="#"><i class="fal fa-reply"></i> Reply</a>
                                        </div>
                                    </div>
                                    <img src="assets/images/blog-cmnt-2.png" alt="">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                </div>
                                <div class="blog-comments-area">
                                    <div class="d-flex justify-content-between align-items-start">
                                        <div class="blog-left">
                                            <h6 class="title">Rosalina Kelian</h6>
                                            <span>19th May 2018</span>
                                        </div>
                                        <div class="blog-right">
                                            <a href="#"><i class="fal fa-reply"></i> Reply</a>
                                        </div>
                                    </div>
                                    <img src="assets/images/blog-cmnt-3.png" alt="">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </div>
                            </div>
                            <div class="blog-details-post-comments">
                                <h4 class="title">Post Comment</h4>
                                <div class="post-comments-area gray-bg">
                                    <form action="#">
                                        <div class="input-box mt-20">
                                            <textarea name="#" id="#" cols="30" rows="10" placeholder="Type your comments...."></textarea>
                                        </div>
                                        <div class="input-box mt-20">
                                            <input type="text" placeholder="Type your name....">
                                        </div>
                                        <div class="input-box mt-20">
                                            <input type="email" placeholder="Type your email....">
                                        </div>
                                        <div class="input-box mt-20">
                                            <input type="text" placeholder="Type your website....">
                                        </div>
                                        <div class="input-box mt-20">
                                            <button class="main-btn"><i class="fal fa-comments"></i>Post Comment</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <app-blogsidebar></app-blogsidebar>
            </div>
        </div>
    </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->
<app-footer2></app-footer2>
