<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Case Details</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Case Details</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== CASE DETAILS PART START ======-->
<div class="case-details-area pt-120 pb-115">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="case-details-thumb">
                    <img src="assets/images/case-details-thumb.jpg" alt="">
                    <div class="case-live">
                        <div class="case-live-item-area d-flex justify-content-between">
                            <div class="case-live-item">
                                <h5 class="title">Sweet Client</h5>
                                <span>Rosalina D. William</span>
                                <i class="fal fa-user"></i>
                            </div>
                            <div class="case-live-item">
                                <h5 class="title">Date</h5>
                                <span>24th May 2019</span>
                                <i class="fal fa-calendar-alt"></i>
                            </div>
                        </div>
                        <div class="case-live-item-area mt-35 d-flex justify-content-between">
                            <div class="case-live-item">
                                <h5 class="title">Website</h5>
                                <span>www.example.com</span>
                                <i class="fal fa-globe"></i>
                            </div>
                            <div class="case-live-item">
                                <h5 class="title">Category</h5>
                                <span>Design, UI/UX</span>
                                <i class="fal fa-tags"></i>
                            </div>
                        </div>
                        <div class="case-live-btn text-center">
                            <a class="main-btn" href="#">Live Preview</a>
                        </div>
                    </div>
                </div>
                <div class="case-details-content mt-50 pb-20">
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​ Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing and technology and the term is more recognizable than ever before. The Information Technology umbrella can be quite large, covering many fields. IT professionals perform a variety of duties that range from installing applications, managing information, to designing complex applications, managing computer networks and designing and managing databases. </p>
                    <div class="case-details-case mt-25">
                        <img src="assets/images/case-content-thumb.jpg" alt="">
                        <p>Over the years, a wide range of developments and innovations in the global IT arena have led to many new IT-enabled devices and services being produced. Moreover, there is need for IT today, not just in urban areas but rural regions as well. Countries and populations that are led by the growing class of high-tech workers and entrepreneurs will be the ones at the forefront of IT development. As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4 col-sm-10">
                <div class="case-details-item mt-30">
                    <img src="assets/images/case-details-item-1.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-10">
                <div class="case-details-item mt-30">
                    <img src="assets/images/case-details-item-2.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-10">
                <div class="case-details-item mt-30">
                    <img src="assets/images/case-details-item-3.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="case-content-2 pt-40 pb-25">
                    <h4 class="title">How it Works?</h4>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​ Today, the term Information Technology (IT) has ballooned to encompass many aspects of computing and technology and the term is more recognizable than ever before. The Information Technology umbrella can be quite large, covering many fields. IT professionals perform a variety of duties that range from installing applications, managing information, to designing complex applications, managing computer networks and designing and managing databases. </p>
                </div>
                <div class="case-video-thumb mt-30">
                    <img src="assets/images/case-video-thumb.jpg" alt="">
                    <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                </div>
                <div class="case-next-prev d-flex justify-content-between pt-50">
                    <div class="case-prev">
                        <a routerLink='/case-detail'>
                            <span>Prev Post</span>
                            <h4 class="title">Tips On Minimalist</h4>
                        </a>
                    </div>
                    <div class="case-next text-right">
                        <a routerLink='/case-detail'>
                            <span>next Post</span>
                            <h4 class="title">Less Is More</h4>
                        </a>
                    </div>
                    <a href="#">
                        <img src="assets/images/dot-box.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== CASE DETAILS PART ENDS ======-->
<app-footer2></app-footer2>
