<app-header2></app-header2>
<!--====== BANNER PART START ======-->
<div class="banner-area">
    <div class="container position-relative">
        <div class="row">
            <div class="col-lg-7">
                <div class="banner-content pt-100">
                    <span class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">Promote any poduct, service or online course.</span>
                    <h1 class="title wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".5s">Get every it Services Here</h1>
                    <ul>
                        <li><a class="fadeInUp wow" data-wow-duration="1s" data-wow-delay="1s" href="#"><span><i class="fal fa-gem"></i></span>
                                <p>IT Product</p>
                            </a></li>
                        <li><a class="btn-2 fadeInUp wow" data-wow-duration="1s" data-wow-delay="1.5s" href="#"><span><i class="fal fa-bullseye"></i></span>
                                <p>IT Services</p>
                            </a>
                        </li>
                    </ul>
                </div> <!-- banner content -->
            </div>
        </div> <!-- row -->
        <div class="banner-shape d-none d-lg-block">
            <img src="assets/images/banner-man.png" alt="">
        </div>
    </div> <!-- container -->
</div>
<!--====== BANNER PART ENDS ======-->
<!--====== ABOUT PART START ======-->
<div class="about-area pt-90 pb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="about-thumb mt-30">
                    <img src="assets/images/about-thumb.jpg" alt="">
                </div> <!-- about thumb -->
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="about-item mt-30">
                    <span>About Us</span>
                    <h3 class="title">You can't use up creativity.</h3>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <div class="about-experience">
                        <h3>30</h3>
                        <span>Years Of <br> Experience</span>
                    </div>
                    <ul>
                        <li><a class="main-btn wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" routerLink='/about'>Learn More</a></li>
                        <li><a class="main-btn main-btn-2 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s" href="#"><i class="fal fa-video"></i> Intro Video</a></li>
                    </ul>
                </div> <!-- about item -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOUT PART ENDS ======-->
<!--====== WHAT WE DO PART START ======-->
<div class="what-we-do-area gray-bg pt-105 pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-7">
                <div class="section-title-2">
                    <h2 class="title">What We Do</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div> <!-- section title 2 -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Web Element Research </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Web Development </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>SEO Service </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Power Redesign </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>UX Design </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>UI Design </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Web Design</h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Game Design</h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Mobile App Design </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Icon Design </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Domain & Hosting </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="what-we-do-item mt-30">
                    <a routerLink='/service-detail'>
                        <h6>Composer Develop </h6><i class="fal fa-long-arrow-right"></i>
                    </a>
                </div> <!-- what we do item -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== WHAT WE DO PART ENDS ======-->
<!--====== INTRO VIDEO PART START ======-->
<div class="intro-video-area bg_cover" style="background-image: url(assets/images/intro-video-bg.jpg);">
    <div class="intro-overlay">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="intro-video-content mt-30">
                        <span>Intro Video</span>
                        <h2 class="title">How we growth our business.</h2>
                        <p class="text-1">The introduction of cloud and mobile technologies into enterprise software.</p>
                        <p class="text-2">hether you are building an enterprise web portal or a state-of-the-art website, you always need the right modern tools. Well-built and maintained PHP frameworks provide those tools in abundance, allowing maintained PHP frameworks provide those tools in abundance, allowing developers to save time, re-use code, and streamline the back end. As software development tools continuously.</p>
                        <a class="main-btn wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s" routerLink='/about'>Learn More</a>
                    </div> <!-- intro video content -->
                </div>
                <div class="col-lg-6">
                    <div class="intro-thumb mt-30">
                        <img src="assets/images/intro-thumb.jpg" alt="">
                        <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                    </div> <!-- intro thumb -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </div>
</div>
<!--====== INTRO VIDEO PART ENDS ======-->
<!--====== LEADERSHIP PART START ======-->
<div class="leadership-area gray-bg pt-105 pb-160">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="section-title-2 text-center pl-25 pr-25">
                    <h2 class="title">Our Leadership</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div> <!-- section title 2 -->
            </div>
        </div> <!-- row -->
        <div class="row leadership-active">
            <div class="col-lg-4">
                <div class="leadership-item mt-30">
                    <img src="assets/images/leadership-1.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Rosalina Devid</h5>
                        <span>Founder</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4">
                <div class="leadership-item mt-30">
                    <img src="assets/images/leadership-2.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Kemina Dvlin</h5>
                        <span>CEO</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4">
                <div class="leadership-item mt-30">
                    <img src="assets/images/leadership-3.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Yellow D. Ballow</h5>
                        <span>Marketer</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4">
                <div class="leadership-item mt-30">
                    <img src="assets/images/leadership-2.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Yellow D. Ballow</h5>
                        <span>Marketer</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== LEADERSHIP PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<div class="Progress-bar-area mt-150 mb-95">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".1s">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".6s">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".9s">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div> <!-- Progress bar item -->
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== QUOTE PART START ======-->
<div class="quote-area bg_cover" style="background-image: url(assets/images/form-bg.jpg);">
    <div class="quote-overlay">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="section-title-2 text-center pl-25 pr-25">
                        <h2 class="title">Get A Free Quote</h2>
                        <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                    </div> <!-- section title 2 -->
                </div>
            </div> <!-- row -->
            <div class="quote-form">
                <form action="#">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="input-box mt-30">
                                <input type="text" placeholder="Enter your name">
                                <i class="fal fa-user"></i>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-box mt-30">
                                <input type="email" placeholder="Enter your email">
                                <i class="fal fa-envelope"></i>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="input-box text-center mt-30">
                                <textarea name="#" id="#" cols="30" rows="10" placeholder="Enter your subject"></textarea>
                                <button class="main-btn wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" type="submit">Get A Free Quote</button>
                                <i class="fal fa-edit"></i>
                            </div>
                        </div>
                    </div>
                </form>
            </div> <!-- quote form -->
        </div> <!-- container -->
    </div>
</div>
<!--====== QUOTE PART ENDS ======-->
<!--====== ANSWERS PART START ======-->
<div class="asnwers-area pt-105 pb-100 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="section-title-2">
                    <h2 class="title">Get Single Answers</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div> <!-- section title 2 -->
                <div class="faq-accordion-2 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".3s">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Does any industry a more audience journey?
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".6s" data-wow-delay=".4s">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Over the years, a wide range of developments
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".7s" data-wow-delay=".5s">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    As a result, most of us need to know
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".8s" data-wow-delay=".6s">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Our knowledge of computers will help us
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card wow fadeInLeft" data-wow-duration=".9s" data-wow-delay=".7s">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Our knowledge of computers will help us
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                    </div>
                </div> <!-- faq accordion -->
            </div>
            <div class="col-lg-6">
                <div class="answers-thumb">
                    <div class="answers-thumb-1 text-right">
                        <img src="assets/images/answers-thumb-1.jpg" alt="">
                    </div>
                    <div class="answers-thumb-2">
                        <img src="assets/images/answers-thumb-2.jpg" alt="">
                    </div>
                </div> <!-- answers thumb -->
            </div>
        </div> <!-- row -->
    </div> <!-- containter -->
</div>
<!--====== ANSWERS PART ENDS ======-->
<!--====== ACTION 2 PART START ======-->
<div class="action-2-area bg_cover" style="background-image: url(assets/images/action-bg.jpg);">
    <div class="action-overlay">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="action-item mt-30">
                        <span>Get Action</span>
                        <h2 class="title">Get A Quote <br>right now.</h2>
                    </div> <!-- action item -->
                </div>
                <div class="col-lg-7">
                    <div class="action-support d-flex mt-30">
                        <div class="action-support-item wow bounceInUp" data-wow-duration=".5s" data-wow-delay=".3s">
                            <p>Mail Us</p>
                            <span>info@webmail.com</span>
                            <i class="fal fa-envelope"></i>
                        </div>
                        <div class="action-support-item action-support-item-2 wow bounceInUp" data-wow-duration=".7s" data-wow-delay=".7s">
                            <p>Make A Call</p>
                            <span>897 876 76 56 4</span>
                            <i class="fal fa-phone"></i>
                        </div>
                    </div> <!-- action support -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </div>
</div>
<!--====== ACTION 2 PART ENDS ======-->
<!--====== NEWS PART START ======-->
<div class="news-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="section-title-2 text-center pl-25 pr-25">
                    <h2 class="title">News Feeds</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div> <!-- section title 2 -->
            </div>
        </div> <!-- row -->
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-1.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/blog-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/blog-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-2.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/blog-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/blog-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-3.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/blog-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/blog-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== NEWS PART ENDS ======-->
<app-footer2></app-footer2>
