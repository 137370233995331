<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Alterix</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Alterix</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-0">
  <div class="container">
    <!----><div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="section-title text-center">
          <h2 class="title">Descubre la tecnología que acelera la transformación digital</h2>
          <p>Alteryx Analytic Process Automation (APA) Platform™ acelera los resultados comerciales impulsados por datos en todas las líneas de negocio de cualquier industria. Desarrolla resultados más rápidos, insights más profundos, mejores decisiones, e incluso, un cambio cultural en el que cualquier persona puede impulsar resultados comerciales a través de la analítica y la ciencia de datos de autoservicio.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-thumb-1.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-eye-dropper"></i>
            <h4 class="title">Prep. de datos y analítica</h4>
            <p>Alteryx empodera a los analistas para que preparen, combinen y analicen datos de manera más rápida con cientos de bloques de creación analíticos de código simple y sin código, que permiten flujos de trabajo altamente configurables y repetibles. Descubre cómo los datos se convierten en insights con la exploración y perfilado de datos.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-thumb-2.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-gavel"></i>
            <h4 class="title">Ciencia de datos y ML</h4>
            <p>Con aprendizaje automático mejorado, quienes trabajan con datos pueden crear rápidamente modelos predictivos sin codificar ni realizar estadísticas complejas. Un proceso guiado y detallado paso a paso o totalmente automatizado te ayudará a crear algoritmos correctamente entrenados y listos para su implementación.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-thumb-3.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-map-marked-alt"></i>
            <h4 class="title">Automatización de analítica</h4>
            <p>Elimina las barreras para el análisis de datos de toda la organización combinando varias herramientas en una sola plataforma. Obtén insights con +70 resultados, incluidos paneles de control, documentos, bots de RPA, sistemas de inteligencia de procesos y aplicaciones de negocios.</p>
          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>

<!--====== BLOG STANDARD PART START ======-->
<!--<div class="blog-standard-area pt-5 pb-1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-standard">
          <div class="single-blog-standard mt-10">
            <div class="blog-dteails-content blog-border">
              <div class="blog-details-top">
                <h2 class="title">¿Qué es la automatización de tareas de escritorio o Robotic Desktop Automation?</h2>

                <p>La automatización de tareas de escritorio (o Robotic Desktop Automation) es una Automatización Robótica de Procesos reducida a un único usuario. Las herramientas de automatización de tareas de escritorio se instalan en una sola máquina para un único usuario y ayudan a las personas a resolver los problemas dentro de su esfera de trabajo. Piense en ello como un software que automáticamente completa tareas de la lista diaria de tareas de un empleado.</p>
                <p class="mt-m2">Las herramientas de automatización de tareas de escritorio tienen robots que interactúan con sitios web, inician sesión en aplicaciones, extraen datos de archivos de Excel, transfieren archivos, generan informes y mucho más.</p>
              </div>
              <div class="blog-details-bath">
                <img src="assets/images/blog-details-thumb.jpg" alt="blog-details">
                <h4 class="title">¿Qué puede hacer la automatización robótica de tareas de escritorio por usted?</h4>
                <p>Los usuarios de Negocio, como los representantes de servicio al cliente, pueden automatizar y así simplificar tareas como la configuración automática de llamadas. Una persona de IT, por su parte, puede automatizar procesos para deshacerse de tareas manuales tediosas y evitar errores.</p>
                <h4 class="title">Funcionalidades listas para usar</h4>
                <p>Un software de automatización de escritorio puede tener una gran variedad de tareas listas para usar y que no requieren programación. Los usuarios de Negocio suelen preferir utilizar funcionalidades tipo drag-and-drop para configurar sus automatizaciones, mientras que los equipos de IT suelen crear flujos de trabajo más potentes con funcionalidades más avanzadas. </p>

                <ul>
                  <li><i class="fal fa-check"></i> AUTOMATIZACIÓN EN SITIOS WEB.</li>
                  <li><i class="fal fa-check"></i> SCRAPING Y EXTRACCIÓN AUTOMÁTICA DE DATOS</li>
                  <li><i class="fal fa-check"></i> GENERACIÓN Y DISTRIBUCIÓN DE REPORTES</li>
                  <li><i class="fal fa-check"></i> AUTOMATIZACIÓN DE TRANSFERENCIA DE ARCHIVOS</li>
                  <li><i class="fal fa-check"></i> AUTOMATIZACIÓN GUI</li>
                  <li><i class="fal fa-check"></i> AUTOMATIZACIÓN DE APLICACIONES MICROSOFT</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>-->
<!--====== BLOG STANDARD PART ENDS ======-->

<!--====== CHOOSE PART START ======-->
<!--<div class="choose-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title">

          <h3 class="title">Beneficios de la automatización de tareas de escritorio</h3>
        </div>
        <div class="choose-cat">
          <div class="row">
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".4s">
              <h4 class="title"></h4>
              <p>Estamos comprometidos en proporcionar un crecimiento exponencial al negocio de nuestros valiosos clientes a través de nuestros impecables servicios de Inteligencia de Negocios.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".6s">
              <h4 class="title"></h4>
              <p>Tenemos la actitud resuelta, valiente y ganadora para ofrecer un servicio Analítico de primera calidad. Somos conocidos por utilizar un enfoque integral que ayuda a su empresa a optimizar procesos.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Estamos ejecutando con éxito más de un centenar de proyectos, que también exudan el testimonio de nuestra competencia en el mercado competitivo. Por favor revise todos los testimonios.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Estamos ejecutando con éxito más de un centenar de proyectos, que también exudan el testimonio de nuestra competencia en el mercado competitivo. Por favor revise todos los testimonios.</p>
              <i class="fal fa-check"></i>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>-->
<!--====== CHOOSE PART ENDS ======-->

<!--<div class="services-item-area pt-15 pb-10">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="section-title text-center">
          <h2 class="title">¿Cuál es la diferencia entre RPA, RDA y BPA?</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-thumb-5.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">RPA</h4>
            <p>La Automatización Robótica de Procesos (Robotic Process Automation) se instala en la red y trabaja con aquellas aplicaciones utilizadas por toda la organización. Varios usuarios con diferentes permisos pueden trabajar a la vez con la herramienta.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-thumb-4.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">RDA</h4>
            <p>La automatización robótica de tareas de escritorio (Robotic Desktop Automation) es para aquellos procesos que solo involucran a una persona, una computadora y las aplicaciones que se ejecutan en ella.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/services-thumb-6.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">BPA</h4>
            <p>La Automatización de Procesos de Negocio (Business Process Automation) puede realizar muchas de las tareas que se puede realizar con una solución de Automatización de Robótica de Procesos de nivel empresarial, pero a mayor escala para organizaciones más grandes.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--====== SERVICES ITEM PART ENDS ======-->
<!--====== TEAM DETAILS PART START ======-->
<!--<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<!--<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<!--<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
