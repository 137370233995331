<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Team Details</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Team Details</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== TEAM DETAILS PART START ======-->
<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div> <!-- Progress bar item -->
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                    </div>
                </div> <!-- faq accordion -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer2></app-footer2>
