import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { Home2Component } from './components/pages/home2/home2.component';
import { Home3Component } from './components/pages/home3/home3.component';
import { Home4Component } from './components/pages/home4/home4.component';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
import { BloggridComponent } from './components/pages/bloggrid/bloggrid.component';
import { BlogstandardComponent } from './components/pages/blogstandard/blogstandard.component';
import { CasedetailsComponent } from './components/pages/casedetails/casedetails.component';
import { CasestudyComponent } from './components/pages/casestudy/casestudy.component';
import { ComingsoomComponent } from './components/pages/comingsoom/comingsoom.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicedetailComponent } from './components/pages/servicedetail/servicedetail.component';
import { ShopdetailComponent } from './components/pages/shopdetail/shopdetail.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamdetailComponent } from './components/pages/teamdetail/teamdetail.component';
import { AnalyticsComponent } from './components/pages/analytics/analytics.component';
import { AutomateComponent } from './components/pages/automate/automate.component';
import { DatascienceComponent } from './components/pages/datascience/datascience.component';
import { MachinelearningComponent } from './components/pages/machinelearning/machinelearning.component';
import { BigdataComponent } from './components/pages/bigdata/bigdata.component';
import { InteligenciaComponent } from './components/pages/inteligencia/inteligencia.component';
import { AuraquanticComponent } from './components/pages/auraquantic/auraquantic.component';
import { AlterixComponent } from './components/pages/alterix/alterix.component';
import { MbiComponent } from './components/pages/mbi/mbi.component';
import { QlikComponent } from './components/pages/qlik/qlik.component';
import { AplComponent } from './components/pages/apl/apl.component';
import { WebComponent } from './components/pages/web/web.component';
import { PwaComponent } from './components/pages/pwa/pwa.component';
import { IsoComponent } from './components/pages/iso/iso.component';
import { ChatbotsComponent } from './components/pages/chatbots/chatbots.component';

import { RpaComponent } from './components/pages/rpa/rpa.component';
import { IndustriaComponent } from './components/pages/industria/industria.component';
import { ExecutionsComponent } from './components/pages/executions/executions.component';
import { VerticaComponent } from './components/pages/vertica/vertica.component';
import { BpmComponent } from './components/pages/bpm/bpm.component';

import { Blogdetails01Component } from './components/pages/blogdetails01/blogdetails01.component';
import { Blogdetails02Component } from './components/pages/blogdetails02/blogdetails02.component';
import { Blogdetails03Component } from './components/pages/blogdetails03/blogdetails03.component';
import {InnovelabsComponent} from "./components/pages/innovelabs/innovelabs.component";

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'home-2', component: Home2Component
  },
  {
    path: 'home-3', component: Home3Component
  },
  {
    path: 'home-4', component: Home4Component
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'blog-detail', component: BlogdetailsComponent
  },
  {
    path: 'blog-grid', component: BloggridComponent
  },
  {
    path: 'blog', component: BlogstandardComponent
  },
  {
    path: 'case-detail', component: CasedetailsComponent
  },
  {
    path: 'case-study', component: CasestudyComponent
  },
  {
    path: 'coming-soon', component: ComingsoomComponent
  },
  {
    path: 'faqs', component: FaqComponent
  },
  {
    path: 'contact-us', component: ContactComponent
  },
  {
    path: 'pricing', component: PricingComponent
  },
  {
    path: 'services', component: ServicesComponent
  },
  {
    path: 'service-detail', component: ServicedetailComponent
  },
  {
    path: 'shop', component: ShopComponent
  },
  {
    path: 'shop-detail', component: ShopdetailComponent
  },
  {
    path: 'team', component: TeamComponent
  },
  {
    path: 'team-detail', component: TeamdetailComponent
  },
  {
    path: 'analytics', component: AnalyticsComponent
  },
  {
    path: 'innovationlab', component: InnovelabsComponent
  },
  {
    path: 'datascience', component: DatascienceComponent
  },
  {
    path: 'machinelearning', component: MachinelearningComponent
  },
  {
    path: 'bigdata', component: BigdataComponent
  },
  {
    path: 'inteligencia', component: InteligenciaComponent
  },
  {
    path: 'auraquantic', component: AuraquanticComponent
  },
  {
    path: 'automate', component: AutomateComponent
  },
  {
    path: 'alterix', component: AlterixComponent
  },
  {
    path: 'mbi', component: MbiComponent
  },
  {
    path: 'qlik', component: QlikComponent
  },
  {
    path: 'apl', component: AplComponent
  },
  {
    path: 'web', component: WebComponent
  },
  {
    path: 'iso', component: IsoComponent
  },
  {
    path: 'chatbots', component: ChatbotsComponent
  },
  {
    path: 'bpm', component: BpmComponent
  },
  {
    path: 'rpa', component: RpaComponent
  },
  {
    path: 'industria', component: IndustriaComponent
  },
  {
    path: 'executions', component: ExecutionsComponent
  },
  {
    path: 'vertica', component: VerticaComponent
  },
  {
    path: 'pwa', component: PwaComponent
  },
  {
    path: 'aprovechar-el-momento', component: Blogdetails01Component
  },
  {
    path: 'inteligencia-artificial', component: Blogdetails02Component
  },
  {
    path: 'lo-que-se-mide-se-gestiona', component: Blogdetails03Component
  },
  {
    path: '**', component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
