import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamdetail',
  templateUrl: './bigdata.component.html',
  styleUrls: ['./bigdata.component.css']
})
export class BigdataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
