import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamdetail',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.css']
})
export class PwaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
