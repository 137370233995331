<!--====== HEADER PART START ======-->
<header class="header-area-2 header-area-3">

  <div class="header-nav">
    <div class="navigation">
      <nav class="navbar navbar-expand-lg navbar-light ">
        <a class="navbar-brand" routerLink='/'><img src="assets/images/logo-3.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <!--<li class="nav-item active"> navbar navbar-expand-lg navbar-light
              <a class="nav-link" href="#">Inicio</a>
              <ul class="sub-menu">
                 <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
              <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
              <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
              <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
              </ul>  sub menu
            </li>-->
            <!--#3 -->
            <li class="nav-item dropdown has-megamenu">
              <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" style="text-transform: unset;pointer-events: none;">{{'services'|translate}}</a>
              <div class="back-area dropdown-menu megamenu" role="menu">
                <div class="row">
                  <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Analítica Avanzada</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" routerLink='/analytics' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Data Analytics</a></li>
                        <li><a class="megamenu-item" routerLink='/datascience' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Ciencia de datos</a></li>
                        <li><a class="megamenu-item" routerLink='/machinelearning' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Machine Learning</a></li>
                        <li><a class="megamenu-item" routerLink='/bigdata' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Big Data</a></li>
                        <li><a class="megamenu-item" routerLink='/inteligencia' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Inteligencia Artificial</a></li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                  <!-- <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Automatización de Procesos</h6>
                      <ul class="list-unstyled">

                        <li><a class="megamenu-item" href="#">Gestión Documental</a></li>
                        <li><a class="megamenu-item" href="#">Integración de datos</a></li>
                        <li><a class="megamenu-item" href="#">Calidad de Datos</a></li>
                        <li><a class="megamenu-item" href="#">Procesos Robotizados</a></li>

                      </ul>
                    </div>
                  </div>end col-3 -->
                  <!--<div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Transformación Digital</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" routerLink='/industria' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Industria 4.0 / IoT</a></li>
                        <li><a class="megamenu-item" routerLink='/rpa' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Robotic Process Automation</a></li>
                        <li><a class="megamenu-item" routerLink='/bpm' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Business Process Management</a></li>
                        <li><a class="megamenu-item" routerLink='/chatbots' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Chatbots</a></li>
                        <li><a class="megamenu-item" routerLink='/iso' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">ISO 56002: Gestión Innovación</a></li>
                      </ul>
                    </div>
                  </div> -->
                  <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Producción de Software</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" routerLink='/pwa' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Aplicaciones PWA</a></li>
                        <li><a class="megamenu-item" routerLink='/web' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Aplicaciones WEB</a></li>
                        <li><a class="megamenu-item" routerLink='/apl' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Aplicaciones Móviles</a></li>


                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                  <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Herramientas</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" routerLink='/qlik' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Qlik</a></li>
                        <!--<li><a class="megamenu-item" routerLink='/mbi' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Microsoft & PowerBI</a></li>-->
                        <!--<li><a class="megamenu-item" routerLink='/alterix' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Alterix</a></li>-->
                        <li><a class="megamenu-item" routerLink='/auraquantic' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">AuraQuantic</a></li>
                        <li><a class="megamenu-item" routerLink='/automate' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Automate RPA</a></li>
                        <!--<li><a class="megamenu-item" routerLink='/vertica' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Vertica</a></li>-->


                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                </div><!-- end row -->
                <!--<div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <h6 class="title">Herramientas</h6>
                      <ul style="list-style-type: none;  margin: 0;  padding: 0;  overflow: hidden;">
                        <li style="float: left;"><a class="megamenu-item" href="#">Qlick</a></li>
                        <li style="float: left;"><a class="megamenu-item" href="#">AuraQuantic</a></li>
                        <li style="float: left;"><a class="megamenu-item" href="#">RPA</a></li>
                        <li style="float: left;"><a class="megamenu-item" href="#">Help System</a></li>
                        <li style="float: left;"><a class="megamenu-item" href="#">Executions</a></li>
                        <li style="float: left;"><a class="megamenu-item" href="#">Microsoft & PowerBI</a></li>
                        <li style="float: left;"><a class="megamenu-item" href="#">Alterix</a></li>
                      </ul>
                    </div>
                  </div>
                </div> col-megamenu.// -->
              </div> <!-- dropdown-mega-menu.// -->
            </li>
            <!--#2 --><!-- sub menu -->
            <!--<li class="nav-item">
              <a class="nav-link" href="#" style="text-transform: unset;">Soluciones</a>
              <ul class="sub-menu">
                <li><a routerLink='/case-study' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Retail</a></li>
                <li><a routerLink='/case-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Talento Humano</a></li>
                <li><a routerLink='/coming-soon' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contable/Financiero</a></li>
                <li><a routerLink='/faqs' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Clientes</a></li>
                <li><a routerLink='/pricing' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Comercial</a></li>
                <li><a routerLink='/shop' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Logística</a></li>
                <li><a routerLink='/shop-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Producción</a></li>
              </ul>
            </li>-->
            <!--#5 -->
            <!--<li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>-->
            <!--<li class="nav-item">
              <a class="nav-link" href="#" style="text-transform: unset;">Academia</a>
              <ul class="sub-menu">

                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Talleres Corporativos</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Talleres Personalizados</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Formación en...</a></li>
              </ul>
            </li> sub menu -->
            <li class="nav-item dropdown has-megamenu">
              <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" style="text-transform: unset; pointer-events: none;">{{'academy'|translate}}</a>
              <div class="back-area dropdown-menu megamenu col-md-6" style="left: unset;" role="menu">
                <div class="row">
                  <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Talleres</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" href="#estimacion">Ciencia de datos</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Machine Learning</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Modelado BPMN</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Data-Driven</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Data Analytics Best Practices</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Monetización de Datos</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Implementaciones QlikSense</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Data Architecture QlikSense</a></li>
                        <li>
                          <a class="megamenu-item" routerLink='/innovationlab' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Innovation Lab</a>
                        </li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div><!-- end col-3 -->
                  <!-- <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Automatización de Procesos</h6>
                      <ul class="list-unstyled">

                        <li><a class="megamenu-item" href="#">Gestión Documental</a></li>
                        <li><a class="megamenu-item" href="#">Integración de datos</a></li>
                        <li><a class="megamenu-item" href="#">Calidad de Datos</a></li>
                        <li><a class="megamenu-item" href="#">Procesos Robotizados</a></li>

                      </ul>
                    </div>
                  </div>end col-3 -->
                  <div class="col">
                    <div class="col-megamenu">
                      <h6 class="title">Cursos/Seminarios</h6>
                      <ul class="list-unstyled">
                        <li><a class="megamenu-item" href="#estimacion">QlikSense</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Power BI</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Auraportal/Auraquantic</a></li>
                        <li><a class="megamenu-item" href="#estimacion">Chatbots</a></li>
                        <li><a class="megamenu-item" href="#estimacion">ISO 56002: Gestión Innovación</a></li>
                      </ul>
                    </div>  <!-- col-megamenu.// -->
                  </div>
                </div><!-- end row -->
              </div> <!-- dropdown-mega-menu.// -->
            </li>
            <li class="nav-item">
              <a routerLink='/blog' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="text-capitalize">Blog</a>
              <!--<a class="nav-link" href="#">Blog</a>
              <ul class="sub-menu">
                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
              </ul>-->
            </li>
            <!--#1
            <li class="nav-item">
              <a class="nav-link" href="#" style="text-transform: unset;">Nosotros</a>
              <ul class="sub-menu">
                <li><a routerLink='/about' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Quienes Somos</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Partners Estratégicos</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Trabaja con Nosotros</a></li>
              </ul>
            </li>-->


            <!--
            <li class="nav-item dropdown submenu mega_menu">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Portfolio
                      </a>
            -->
            <!--<li class="nav-item dropdown submenu mega_menu">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Portafolio de Servicios</a>
              <div class="mega_menu_inner">
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Portfolio Grid</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-grid-2col.html" class="nav-link">Style 1 (2 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-3columns.html" class="nav-link">Style 1 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-4columns.html" class="nav-link">Style 1 (4 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-2col-2.html" class="nav-link">Style 2 (2 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-2col-3.html" class="nav-link">Style 2 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-2col-4.html" class="nav-link">Style 2 (4 columns)</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Portfolio Fluid</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-fullwidth-3columns.html" class="nav-link">Style 1 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-fullwidth-4columns.html" class="nav-link">Style 1 (4 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-fullwidth1.html" class="nav-link">Style 2 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-grid-fullwidth2.html" class="nav-link">Style 2 (4 columns)</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Portfolio Masonry</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-mas-2col.html" class="nav-link">Style 1 (2 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-3col.html" class="nav-link">Style 1 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-4col.html" class="nav-link">Style 1 (4 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-3col-2.html" class="nav-link">Style 2 (3 columns)</a></li>
                      <li class="nav-item"><a href="portfolio-mas-4col-2.html" class="nav-link">Style 2 (4 columns)</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="index.html" class="nav-link">Project details</a>
                    <ul class="dropdown-menu">
                      <li class="nav-item"><a href="portfolio-details-1.html" class="nav-link">Style 1</a></li>
                      <li class="nav-item"><a href="portfolio-details-2.html" class="nav-link">Style 2</a></li>
                      <li class="nav-item"><a href="portfolio-details-3.html" class="nav-link">Style 3</a></li>
                    </ul>
                  </li>
                </ul>
              </div>-->
            <!--<ul class="sub-menu">
                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portafolio de Servicios</a></li>
                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
            </ul>  -->
            <!--</li>-->
            <!--#4 -->
            <!--<li class="nav-item">
              <a class="nav-link" routerLink='/blog' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="text-transform: unset;">Blog</a>
              <ul class="sub-menu">
                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
              </ul>  sub menu
            </li>-->
            <!--<li class="nav-item">
                <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
            </li>-->
          </ul>
        </div> <!-- navbar collapse -->
        <div class="navbar-btn mr-70">
          <a class="main-btn" routerLink='/contact-us'>{{'demo'|translate}}</a>
        </div>

        <div class="country-flag d-none d-lg-block mr-10">
          <a (click)="switchLanguage('es')" class="btn btn-light text-dark">ESP</a>
        </div>
        <div class="country-flag d-none d-lg-block">
          <a (click)="switchLanguage('en')" class="btn btn-light text-dark">ENG</a>
        </div>
        <!--<div class="country-flag d-none d-lg-block mr-10">
          <a href="#"><img src="assets/images/es.svg" alt="flag" height="47" width="47" (click)="switchLanguage('es')"></a>
        </div>
        <div class="country-flag d-none d-lg-block">
          <a href="#"><img src="assets/images/gb.svg" alt="flag" height="47" width="47" (click)="switchLanguage('en')"></a>
        </div>-->
        <!--<div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#">Home</a>
              <ul class="sub-menu">
                <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
                <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
                <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
                <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Services</a>
              <ul class="sub-menu">
                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Pages</a>
              <ul class="sub-menu">
                <li><a routerLink='/case-study' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">case study</a></li>
                <li><a routerLink='/case-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Details</a></li>
                <li><a routerLink='/coming-soon' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">coming soon</a></li>
                <li><a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">faq</a></li>
                <li><a routerLink='/pricing' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">pricing</a></li>
                <li><a routerLink='/shop' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop</a></li>
                <li><a routerLink='/shop-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop Details</a></li>
                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>
              </ul>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
            </li>
          </ul>
        </div>
        <div class="navbar-btn mr-100">
          <a class="main-btn" routerLink='/contact-us'>Get A Quote</a>
        </div>
        <div class="country-flag d-none d-lg-block">
          <a href="#"><img src="assets/images/flag-1.jpg" alt="flag"></a>
        </div>-->
      </nav>
    </div>
  </div>
  <!--<div class="header-nav">
        <div class="navigation">
            <nav class="navbar navbar-expand-lg navbar-light ">
                <a class="navbar-brand" routerLink='/'><img src="assets/images/logo-3.png" alt=""></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Home</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
                                <li><a routerLink='/home-2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
                                <li><a routerLink='/home-3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
                                <li><a routerLink='/home-4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Services</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/services' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                                <li><a routerLink='/service-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Pages</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/case-study' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">case study</a></li>
                                <li><a routerLink='/case-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Details</a></li>
                                <li><a routerLink='/coming-soon' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">coming soon</a></li>
                                <li><a routerLink='/faqs' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">faq</a></li>
                                <li><a routerLink='/pricing' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">pricing</a></li>
                                <li><a routerLink='/shop' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop</a></li>
                                <li><a routerLink='/shop-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">shop Details</a></li>
                                <li><a routerLink='/team' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team</a></li>
                                <li><a routerLink='/team-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">team details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Blog</a>
                            <ul class="sub-menu">
                                <li><a routerLink='/blog' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                                <li><a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li><a routerLink='/blog-detail' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLink='/contact-us' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>
                </div>
                <div class="navbar-btn mr-100">
                    <a class="main-btn" routerLink='/contact-us'>Get A Quote</a>
                </div>
                <div class="country-flag d-none d-lg-block">
                    <a href="#"><img src="assets/images/flag-1.jpg" alt="flag"></a>
                </div>
            </nav>
        </div>
    </div>-->
</header>
<!--====== HEADER PART ENDS ======-->
