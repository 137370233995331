<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Nuestros artículos</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Noticias</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-90 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="blog-standard">
                    <div class="single-blog-standard mt-30">
                        <div class="blog-standard-thumb">
                            <img src="assets/images/blog-standard-1.jpg" alt="blog">
                        </div>
                        <div class="blog-standard-content">
                            <span>Businese</span>
                            <h2 class="title">¡Reinventarse, Aprovechar el Momento!.</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                <li><i class="fal fa-comments"></i> 35 Comentarios</li>
                                <li><i class="fal fa-calendar-alt"></i> 24 Marzo 2020</li>
                            </ul>
                            <p>Quien no sepa adaptarse quedará relegado y condenado a desaparecer. La Transformación digital no es una opción.</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>por</span> Iván Vera</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/aprovechar-el-momento'><i class="fal fa-arrow-right"></i>Leer Más</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-blog-standard mt-30">
                        <div class="blog-standard-thumb">
                            <img src="assets/images/blog-standard-3.jpg" alt="blog">
                            <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                        </div>
                        <div class="blog-standard-content">
                            <span>Businese</span>
                            <h2 class="title">Inteligencia Artificial, Tecnología en Boga.</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                <li><i class="fal fa-comments"></i> 35 Comentarios</li>
                                <li><i class="fal fa-calendar-alt"></i> 24 Marzo 2020</li>
                            </ul>
                            <p>Andreas Kaplan y Michael Haenlein definen la inteligencia artificial como "la capacidad de un sistema para interpretar correctamente datos externos, para aprender de dichos datos y emplear esos conocimientos para lograr tareas y metas concretas a través de la adaptación flexible".</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>por</span> Iván Vera</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/inteligencia-artificial'><i class="fal fa-arrow-right"></i>Leer Más</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-blog-standard mt-30">
                        <div class="blog-standard-thumb">
                            <img src="assets/images/blog-standard-2.jpg" alt="blog">
                        </div>
                        <div class="blog-standard-content">
                            <span>Businese</span>
                            <h2 class="title">Lo que se mide, se gestiona</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                <li><i class="fal fa-comments"></i> 35 Comentarios</li>
                                <li><i class="fal fa-calendar-alt"></i> 24 Marzo 2020</li>
                            </ul>
                            <p>No se puede mejorar lo que no se controla; no se puede controlar lo que no se mide; no se puede medir lo que no se define.</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>por</span> Iván Vera</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/lo-que-se-mide-se-gestiona'><i class="fal fa-arrow-right"></i>Leer Más</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-blog-standard  mt-30">
                        <div class="blog-standard-content blog-border">
                            <span>Businese</span>
                            <h2 class="title">In reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Vistas</li>
                                <li><i class="fal fa-comments"></i> 35 Comments</li>
                                <li><i class="fal fa-calendar-alt"></i> 24th March 2019</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>by</span> Hetmayar</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/blog-detail'><i class="fal fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-blog-standard mt-30">
                        <div class="blog-standard-thumb">
                            <img src="assets/images/blog-standard-3.jpg" alt="blog">
                        </div>
                        <div class="blog-standard-content">
                            <span>Businese</span>
                            <h2 class="title">Magna aliqua. Ut enim ad minim venia m, quis nostrud exercitation ullamco</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Views</li>
                                <li><i class="fal fa-comments"></i> 35 Comments</li>
                                <li><i class="fal fa-calendar-alt"></i> 24th March 2019</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>by</span> Hetmayar</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/blog-detail'><i class="fal fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-blog-standard mt-30">
                        <div class="blog-standard-thumb">
                            <img src="assets/images/blog-standard-2.jpg" alt="blog">
                        </div>
                        <div class="blog-standard-content">
                            <span>Businese</span>
                            <h2 class="title">Magna aliqua. Ut enim ad minim venia m, quis nostrud exercitation ullamco</h2>
                            <ul>
                                <li><i class="fal fa-eye"></i> 232 Views</li>
                                <li><i class="fal fa-comments"></i> 35 Comments</li>
                                <li><i class="fal fa-calendar-alt"></i> 24th March 2019</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
                            <div class="blog-flex">
                                <div class="blog-left">
                                    <p><span>by</span> Hetmayar</p>
                                    <img src="assets/images/blog-girl.png" alt="">
                                </div>
                                <div class="blog-right">
                                    <a routerLink='/blog-detail'><i class="fal fa-arrow-right"></i>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <nav class="mt-60" aria-label="Page navigation example">
                            <ul class="pagination pagination-2 justify-content-center">
                                <li class="page-item">
                                    <a class="page-link d-none d-sm-inline-block" href="#" tabindex="-1" aria-disabled="true"><i class="fal fa-arrow-left"></i></a>
                                </li>
                                <li class="page-item"><a class="page-link active" href="#">01</a></li>
                                <li class="page-item"><a class="page-link" href="#">02</a></li>
                                <li class="page-item"><a class="page-link" href="#">03</a></li>
                                <li class="page-item"><a class="page-link" href="#">...</a></li>
                                <li class="page-item"><a class="page-link" href="#">10</a></li>
                                <li class="page-item">
                                    <a class="page-link d-none d-sm-inline-block" href="#"><i class="fal fa-arrow-right"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <app-blogsidebar></app-blogsidebar>
            </div>
        </div>
    </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->
<app-footer2></app-footer2>
