import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamdetail',
  templateUrl: './rpa.component.html',
  styleUrls: ['./rpa.component.css']
})
export class RpaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
