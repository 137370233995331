<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Coming Soon</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Coming Soon</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== COMIMG SOON PART START ======-->
<div class="error-area pt-120 pb-115">
    <div class="container">
        <div class="row  justify-content-center">
            <div class="col-lg-12">
                <div class="error-item text-center">
                    <div id="simple_timer"></div>
                    <h2 class="title">This Website is Coming <br> Very Soon</h2>
                    <span>Wait for it please</span>
                </div> <!-- error item -->
            </div>
        </div>
    </div>
</div>
<!--====== COMIMG SOON PART ENDS ======-->
<app-footer2></app-footer2>
