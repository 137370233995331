<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/AplicacionesMoviles.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Aplicaciones Móviles</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Aplicaciones Móviles</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<main>

  <section class="info info--webappdev-1 pt-70 bgcolor--white heading--big">
    <div class="heading container">
      <div class="row justify-content-center">
        <div class="col-xs-12">
          <div class="text-center">
            <h1>SERVICIO DE DESARROLLO MOVIL</h1>
          </div>
        </div>
        <div class="row pt-15">
          <div class="col-lg-12 order-lg-1">
            <div class="heading-description">
              <p>Desarrollo de aplicaciones móviles de extremo a extremo para ambientes orientados al consumidor y corporativos.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="info info--webappdev-2 pt-70 pb-50 bgcolor--white lists--checkmarks">
    <div class="container pt-50">
      <div class="row cards2-row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="cards2-item">
            <div class="cards2-name">
              <h4>Móvil Primero</h4>
            </div>

            <div class="cards2-text">
              <p>Involucrar a expertos reconocidos en el desarrollo de aplicaciones móviles para construir, probar y publicar su aplicación móvil de cinco estrellas</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="cards2-item">
            <div class="cards2-name">
              <h4>Web a móvil</h4>
            </div>

            <div class="cards2-text">
              <p>Amplíe el alcance de los portales y servicios web existentes con las aplicaciones móviles centradas en el contenido y las transacciones.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <div class="cards2-item">
            <div class="cards2-name">
              <h4>Movilidad empresarial</h4>
            </div>

            <div class="cards2-text">
              <p>Obtenga una solución de movilidad empresarial B2B, B2E o B2C de primera clase que interactúe de forma segura con cualquier tipo de sistemas corporativos</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="info info--webappdev-2 pt-70 pb-50 bgcolor--white lists--checkmarks">
  <div class="heading container">
    <div class="row justify-content-center">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h3>NO SOLO CREAMOS APLICACIONES. CREAMOS EXPERIENCIAS MÓVILES DE PRIMER NIVEL</h3>
        </div>
      </div>
      <div class="container pt-50">
        <div class="row">
          <div class="col-lg-10 col-lg-push-1 col-md-10 col-md-push-1 col-sm-12 col-xs-12">
            <div class="heading-description">
              <p>Con Royaltic, usted proporciona a sus usuarios externos e internos con soluciones móviles elegantes, productivas y fáciles de usar, y le devuelven la lealtad y el compromiso del mundo real. Para asegurar la cobertura de todas las dimensiones de la calidad de la solución, nuestros desarrolladores de aplicaciones móviles se centran en:</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container pt-50">
    <div class="row infoList-row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <ul class="infoList-list">
          <li class="infoList-item">
            <div class="infoList-title pl-15 pt-3">
              <h4>Diseño de experiencia de usuario</h4>
            </div>

            <div class="infoList-text">
              <p>Ya sea que persiga para construir una conexión emocional, un diseño "tranquilo" o una interfaz de usuario de "efecto wow", Royaltic obtuvo expertos a bordo con las habilidades requeridas para crear la experiencia móvil excepcional que está buscando.</p>
            </div>
          </li>
          <li class="infoList-item">
            <div class="infoList-title pl-15 pt-3">
              <h4>Cobertura multiplataforma</h4>
            </div>

            <div class="infoList-text">
              <p>Entregamos a través de todas las plataformas móviles populares y tecnologías habilitadas para dispositivos móviles, además de ayudar a las empresas con sus estrategias de entrega de aplicaciones móviles para asegurar la cobertura más óptima de todos los públicos objetivo.</p>
            </div>
          </li>
          <li class="infoList-item">
            <div class="infoList-title pl-15 pt-3">
              <h4>Escalabilidad e Interoperabilidad</h4>
            </div>

            <div class="infoList-text">
              <p>Los diseños de la aplicación a crear van a incorporar las consideraciones de escalabilidad y interoperabilidad desde el principio, dejando espacio para los avances tecnológicos que nunca cesan para aumentar el valor de su aplicación en cualquier momento en el futuro.</p>
            </div>
          </li>
          <li class="infoList-item">
            <div class="infoList-title pl-15 pt-3">
              <h4>Seguridad y cumplimiento</h4>
            </div>

            <div class="infoList-text">
              <p>Cada proyecto exige su propio conjunto de consideraciones de seguridad y cumplimiento, y algunas son excepcionalmente exigentes. En Royaltic, abordamos los requisitos de seguridad mejorados o específicos de la industria y los estándares de continuidad empresarial del Día del Proyecto uno.</p>
            </div>
          </li>
          <li class="infoList-item">
            <div class="infoList-title pl-15 pt-3">
              <h4>Entrega acelerada</h4>
            </div>

            <div class="infoList-text">
              <p>Para acomodar rápidamente los cambios en las demandas de los consumidores, Royaltic aplica los devoces y las prácticas de entrega de valor continuo para la entrega rápida de funcionalidad y el lanzamiento acelerado del producto al tiempo que se sujeta la calidad y el rendimiento de la solución de primera clase.</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 hidden-xs">
        <div class="infoList-object ready">
          <img class="lazy loaded" data-src="https://www.iflexion.com/sites/all/themes/iflexion/images/xipads.webp.pagespeed.ic.wI7CfiyKWO.webp" alt="ipads" src="https://www.iflexion.com/sites/all/themes/iflexion/images/xipads.webp.pagespeed.ic.wI7CfiyKWO.webp" data-was-processed="true">
        </div>
      </div>
    </div>
  </div>
</section>

  <section class="info info--page-frontend-4 pt-70 pb-50 heading--big bgcolor--white">
  <div class="heading container">
    <div class="row justify-content-center">
      <div class="col-xs-12">
        <div class="section-title text-center">
          <h3>TECNOLOGÍAS</h3>
        </div>
      </div>
    </div>


    <div class="container pt-20">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div class="row">
            <div class="fullCards1-item fullCards1-item--1">
              <div class="fullCards1-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="132.6px" height="154.7px" viewBox="0 0 132.6 154.7" enable-background="new 0 0 132.6 154.7" xml:space="preserve" class="icon">
<path fill-rule="evenodd" clip-rule="evenodd" fill="#6C82E0" d="M128.1,51.9c-8-9.9-19.1-15.7-29.6-15.7c-13.9,0-19.8,6.6-29.5,6.6
	c-10,0-17.5-6.6-29.6-6.6c-11.8,0-24.4,7.2-32.4,19.5c-11.2,17.3-9.3,49.9,8.9,77.7c6.5,9.9,15.2,21.1,26.6,21.2
	c10.1,0.1,13-6.5,26.7-6.5c13.7-0.1,16.3,6.6,26.4,6.5c11.4-0.1,20.5-12.5,27-22.4c4.7-7.1,6.4-10.7,10-18.8
	C106.3,103.5,102,66.2,128.1,51.9z M87.9,25.1c5.1-6.5,8.9-15.7,7.5-25.1c-8.3,0.6-17.9,5.8-23.6,12.7c-5.1,6.2-9.4,15.5-7.7,24.5
	C73.1,37.5,82.5,32.1,87.9,25.1z"></path>
</svg>
              </div>

              <div class="fullCards1-name">
                <h4>iOS</h4>
              </div>


            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div class="row">
            <div class="fullCards1-item fullCards1-item--2">
              <div class="fullCards1-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="140.6px" height="165px" viewBox="0 0 140.6 165" enable-background="new 0 0 140.6 165" xml:space="preserve" class="icon">
<path fill-rule="evenodd" clip-rule="evenodd" fill="#4B49AD" d="M130.8,115.7c-5.4,0-9.7-4.8-9.7-10.6V64.8
	c0-5.9,4.4-10.6,9.7-10.6c5.4,0,9.7,4.8,9.7,10.6v40.3C140.6,110.9,136.2,115.7,130.8,115.7z M108.3,131.2H97.6v23.2
	c0,5.9-4.4,10.6-9.8,10.6c-5.4,0-9.7-4.8-9.7-10.6v-23.2H62.5v23.2c0,5.9-4.3,10.6-9.7,10.6c-5.4,0-9.7-4.8-9.7-10.6v-23.2H32.2
	c-3.4,0-7.5-4.4-7.5-7.8V55.5h91.1v67.9C115.8,126.8,111.7,131.2,108.3,131.2z M25.1,50.3c0-15.4,9.6-28.9,23.7-35.7L41,1.2h0
	c0,0-0.4-0.7,0.4-1.1c0.7-0.4,1.1,0.3,1.1,0.3l7.9,13.5c6-2.6,12.7-4.1,19.9-4.1c7.1,0,13.9,1.5,19.9,4.1L98,0.4
	c0,0,0.5-0.7,1.2-0.3c0.7,0.4,0.3,1.1,0.3,1.1l-7.8,13.4c14.1,6.9,23.7,20.3,23.7,35.7H70.3H25.1z M48.8,27.1
	c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.2,4.3,4.2c2.4,0,4.3-1.9,4.3-4.2S51.2,27.1,48.8,27.1z M91.8,35.6c2.4,0,4.3-1.9,4.3-4.2
	s-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3S89.4,35.6,91.8,35.6z M9.7,115.7c-5.4,0-9.7-4.8-9.7-10.6V64.8c0-5.9,4.4-10.6,9.7-10.6
	c5.4,0,9.7,4.8,9.7,10.6v40.3C19.5,110.9,15.1,115.7,9.7,115.7z"></path>
</svg>
              </div>

              <div class="fullCards1-name">
                <h4>Android</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div class="row">
            <div class="fullCards1-item fullCards1-item--3">
              <div class="fullCards1-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="149.6px" height="149.6px" viewBox="0 0 149.6 149.6" enable-background="new 0 0 149.6 149.6" xml:space="preserve" class="icon">
<path fill-rule="evenodd" clip-rule="evenodd" fill="#1B519E" d="M68.3,138.3V78.6l81.3,0.3v70.7L68.3,138.3z M68.3,12l81.3-12v71.3
	l-81.3,0.3V12z M0,78.3l61.2,0.3v58.7L0,128.7V78.3z M0,22l61.2-9v58.6L0,71.9V22z"></path>
</svg>
              </div>

              <div class="fullCards1-name">
                <h4>Windows Móvil</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <div class="row">
            <div class="fullCards1-item fullCards1-item--4">
              <div class="fullCards1-icon">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="191px" height="160px" viewBox="0 0 191 160" enable-background="new 0 0 191 160" xml:space="preserve" class="icon">
<path fill-rule="evenodd" clip-rule="evenodd" fill="#9B9B9B" d="M178.5,128.8h-37.4v-4.2v-4.2v-18.7h41.5V12.5
	c0-2.3-1.9-4.2-4.2-4.2H12.5c-2.3,0-4.2,1.9-4.2,4.2v89.3h6.2v18.7v4.2v4.2h-2.1c-6.9,0-12.5-5.6-12.5-12.5V12.5C0,5.6,5.6,0,12.5,0
	h166.1c6.9,0,12.5,5.6,12.5,12.5v103.9C191,123.2,185.4,128.8,178.5,128.8z M22.8,71.7c1.2-0.7,2.6-1.1,4.2-1.1h16.6h2.1h2.1h8.3
	h2.1h6.4c1.5,0,2.8,0.4,4,1c2.5,1.3,4.2,3.9,4.2,7v23.2v18.7v4.2v4.2v10.4v2.1v10.4c0,2.5-1.1,4.7-2.8,6.2c-1.4,1.3-3.3,2.1-5.4,2.1
	H54.2h-5.9H27c-4.6,0-8.3-3.7-8.3-8.3v-22.9v-4.2v-4.2v-18.7V78.6C18.7,75.6,20.4,73.1,22.8,71.7z M44.2,154.1
	c0.4,0.1,0.8,0.2,1.2,0.2c0.4,0,0.7-0.1,1.1-0.2c0.7-0.2,1.3-0.7,1.7-1.3c0.5-0.6,0.7-1.4,0.7-2.3c0-1.1-0.4-2.1-1.1-2.8
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.5-1.2-0.8-1.9-0.8c-0.1,0-0.2,0-0.3,0c-0.6,0-1.2,0.2-1.7,0.5c-0.2,0.1-0.4,0.2-0.5,0.4
	c-0.8,0.7-1.3,1.8-1.3,2.9C41.8,152.1,42.8,153.5,44.2,154.1z M27,101.8v18.7v4.2v4.2v12.5h16.6h2.1h2.1h8.3h8.3v-2.1v-10.4v-4.2
	v-4.2v-18.7V79h-6.2h-2.1h-8.3h-2.1h-2.1H27V101.8z M76.8,143.4h51.9v-12.5v-4.2v-4.2v-18.7V51.9H56.1v14.5h-8.3V49.9
	c0-3.4,3-6.2,6.5-6.2h76.7c3.4,0,6.1,2.8,6.1,6.2v54v18.7v4.2v4.2v21.8c0,3.4-2.6,7.3-6,7.3H73.6c2-2.2,3.2-5.1,3.2-8.3V143.4z
	 M93.1,154.3c2,0,3.5-1.7,3.5-3.8c0-2.1-1.6-3.8-3.5-3.8c-2,0-3.5,1.7-3.5,3.8C89.5,152.6,91.1,154.3,93.1,154.3z M145.3,147.5
	c2.1,6.2,14.5,6.2,14.5,8.3c0,2.1,0,2.1,0,2.1s-12.5,0-18.7,0c0,0,0-5,0-7.3v-1v-4.2C141.2,145.5,143.2,141.3,145.3,147.5z"></path>
</svg>
              </div>

              <div class="fullCards1-name">
                <h4>Multiplataforma</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  <section class="related lazy" data-bg="url(https://www.iflexion.com/sites/all/themes/iflexion/images/related-bg.webp)" data-was-processed="true" style="background-image: url(&quot;https://www.iflexion.com/sites/all/themes/iflexion/images/related-bg.webp&quot;);">
    <div class="related-bg glide glide--ltr glide--slider glide--swipeable">
      <div class="slider glide__track" data-glide-el="track">
        <ul class="related-background glide__slides" style="transition: transform 0ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s; width: 885px; transform: translate3d(0px, 0px, 0px);">
          <li class="related-item glide__slide lazy glide__slide--active" data-bg="url(https://www.iflexion.com/files/styles/case__related__background/public/content/case/background/comprehensive_mobile_application_to_supplement_online_deals_service.jpg?itok=8rrGK0Kh)" style="width: 885px; background-image: url(&quot;https://www.iflexion.com/files/styles/case__related__background/public/content/case/background/comprehensive_mobile_application_to_supplement_online_deals_service.jpg?itok=8rrGK0Kh&quot;);" data-was-processed="true"></li>
        </ul>
      </div>
    </div>
  </section>
  <script type="text/javascript">window.loadCSS('/sites/all/themes/iflexion/css/components.css')</script>
  <script type="text/javascript">window.loadCSS('/sites/all/themes/iflexion/css/pages.css')</script>
  <script type="text/javascript">window.loadCSS('/sites/all/themes/iflexion/css/route.css')</script>
</main>
<!--====== TEAM DETAILS PART START ======-->
<!--<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<!--<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<!--<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
