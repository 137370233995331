<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Case Study</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Case Study</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== NEWS PART START ======-->
<div class="news-area news-area-2">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-1.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-2.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-1.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-2.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-3.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-4.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-5.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-6.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-7.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-8.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-9.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-10.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-11.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-12.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/case-study-2.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink='/case-detail'>A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink='/case-detail'><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-8">
                <div class="case-study-btn text-center mt-40">
                    <a class="main-btn" href="#">Load More +</a>
                </div>
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== NEWS PART ENDS ======-->
<app-footer2></app-footer2>
