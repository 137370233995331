<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">FAQ</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Faq</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<div class="about-faq-area about-faq-area-page pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                    </div>
                </div> <!-- faq accordion -->
            </div>
            <div class="col-lg-6">
                <div class="faq-video-thumb-area">
                    <div class="faq-video-thumb-1 text-right">
                        <img src="assets/images/faq-thumb-item-1.jpg" alt="faq">
                    </div>
                    <div class="faq-video-thumb-2">
                        <img src="assets/images/faq-thumb-item-2.jpg" alt="faq">
                    </div>
                </div>
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOT FAQ PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<div class="about-faq-area pb-100 gray-bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 order-2 order-lg-1">
                <div class="faq-video-thumb">
                    <img src="assets/images/faq-video-thumb.jpg" alt="">
                    <a class="video-popup" href="http://bit.ly/2VuPnrx"><i class="fas fa-play"></i></a>
                </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-2">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample-2">
                        <div class="card">
                            <div class="card-header" id="heading1">
                                <a class="" href="" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapse1" class="collapse show" aria-labelledby="heading1" data-parent="#accordionExample-2">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="heading2">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionExample-2">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="heading3">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordionExample-2">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="heading4">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample-2">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="heading5">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample-2">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                    </div>
                </div> <!-- faq accordion -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== ABOT FAQ PART ENDS ======-->
<!--====== QUOTE PART START ======-->
<div class="quote-area bg_cover" style="background-image: url(assets/images/form-bg.jpg);">
    <div class="quote-overlay">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="section-title-2 text-center pl-25 pr-25">
                        <h2 class="title">Get A Free Quote</h2>
                        <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                    </div> <!-- section title 2 -->
                </div>
            </div> <!-- row -->
            <div class="quote-form">
                <form action="#">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="input-box mt-30">
                                <input type="text" placeholder="Enter your name">
                                <i class="fal fa-user"></i>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-box mt-30">
                                <input type="email" placeholder="Enter your email">
                                <i class="fal fa-envelope"></i>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="input-box text-center mt-30">
                                <textarea name="#" id="#" cols="30" rows="10" placeholder="Enter your subject"></textarea>
                                <button class="main-btn" type="submit">Get A Free Quote</button>
                                <i class="fal fa-edit"></i>
                            </div>
                        </div>
                    </div>
                </form>
            </div> <!-- quote form -->
        </div> <!-- container -->
    </div>
</div>
<!--====== QUOTE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="section-title text-center">
                    <h2 class="title">Releted Questions</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services-item mt-30">
                    <img src="assets/images/services-thumb-1.jpg" alt="">
                    <div class="services-overlay">
                        <i class="fal fa-eye-dropper"></i>
                        <h4 class="title">UI/UX Design</h4>
                        <p>The functional goal of technical content is to help people use a product successfully. The business goal must tie the content.</p>
                    </div>
                </div> <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services-item mt-30">
                    <img src="assets/images/services-thumb-2.jpg" alt="">
                    <div class="services-overlay">
                        <i class="fal fa-gavel"></i>
                        <h4 class="title">IT Consultancy</h4>
                        <p>The functional goal of technical content is to help people use a product successfully. The business goal must tie the content.</p>
                    </div>
                </div> <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services-item mt-30">
                    <img src="assets/images/services-thumb-3.jpg" alt="">
                    <div class="services-overlay">
                        <i class="fal fa-map-marked-alt"></i>
                        <h4 class="title">Technology Prof.</h4>
                        <p>The functional goal of technical content is to help people use a product successfully. The business goal must tie the content.</p>
                    </div>
                </div> <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services-item mt-30">
                    <img src="assets/images/services-thumb-5.jpg" alt="">
                    <div class="services-overlay">
                        <i class="fal fa-hurricane"></i>
                        <h4 class="title">Web Development</h4>
                        <p>The functional goal of technical content is to help people use a product successfully. The business goal must tie the content.</p>
                    </div>
                </div> <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services-item mt-30">
                    <img src="assets/images/services-thumb-4.jpg" alt="">
                    <div class="services-overlay">
                        <i class="fal fa-bezier-curve"></i>
                        <h4 class="title">App Development</h4>
                        <p>The functional goal of technical content is to help people use a product successfully. The business goal must tie the content.</p>
                    </div>
                </div> <!-- single services -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="single-services-item mt-30">
                    <img src="assets/images/services-thumb-6.jpg" alt="">
                    <div class="services-overlay">
                        <i class="fal fa-umbrella"></i>
                        <h4 class="title">Game Design</h4>
                        <p>The functional goal of technical content is to help people use a product successfully. The business goal must tie the content.</p>
                    </div>
                </div> <!-- single services -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== SERVICES ITEM PART ENDS ======-->
<app-footer2></app-footer2>
