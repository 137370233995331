<app-header4></app-header4>
<!--====== BANNER PART START ======-->
<!--<div class="banner-area">
  <div class="container position-relative">
    <div class="row">
      <div class="col-lg-7">
        <div class="banner-content pt-100">


          <ngx-typed-js [strings]="['Inteligencia de Negocio', 'Software','Tecnología', 'Capacitación Avanzada','Webapps']" backDelay="3000" typeSpeed="10" loop="true">
            <h1 class="heading mb-3">Somos Lider en Negocios digitales para soluciones de <br><span class="typing text-primary"></span> </h1>
          </ngx-typed-js>

          <p class="para-desc text-muted">CONSTRUYE TU NEGOCIO CON LAS TECNOLOGÍAS DE ROYALTIC<br>
            ¡La mejor empresa de consultoría del Ecuador!.</p>
          <ul>
            <li><a class="fadeInUp wow" data-wow-duration="1s" data-wow-delay="1s" href="#"><span><i class="fal fa-gem"></i></span>
              <p>Productos IT</p>
            </a></li>
            <li><a class="btn-2 fadeInUp wow" data-wow-duration="1s" data-wow-delay="1.5s" href="#"><span><i class="fal fa-bullseye"></i></span>
              <p>Servicios IT</p>
            </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="banner-shape d-none d-lg-block">
      <img src="assets/images/banner-man.png" alt="">
    </div>
  </div>
</div>-->
<div class="banner-area-3 bg_cover" style="background-image: url(assets/images/banner-bg-3.jpg);">
    <div class="banner-overlay">
      <!--<img class="item-1" src="assets/images/banner-shape-1.png" alt="">-->
      <!--<img class="item-2" src="assets/images/banner-shape-2.png" alt="">-->
        <div class="container" style="margin-top: -70px;">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="banner-content text-center">
                        <span style="font-size: 40px">{{'digital'|translate}}</span>
                        <h1 class="title">{{'data'|translate}}</h1>

                    </div>
                  <ngx-typed-js [strings]="['Big Data', 'Ciencia de datos', 'Data-Driven', 'Inteligencia Artificial','Machine Learning','Business Process Management']" backDelay="100" typeSpeed="10" loop="true">
                    <br><p style="font-size: 40px" class="heading mb-3 text-center text-white"> <span class="typing"></span> </p>
                  </ngx-typed-js>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== BANNER PART ENDS ======-->
<!--====== BRAND PART START ======-->
<div class="brand-area brand-active">
  <div class="brand-item">
    <img src="assets/images/brand-1.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-2.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-3.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-4.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-5.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-6.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-7.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-8.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-9.png" alt="">
  </div> <!-- brand item -->
  <div class="brand-item">
    <img src="assets/images/brand-10.png" alt="">
  </div> <!-- brand item -->
</div>
<!--====== BRAND PART ENDS ======-->
<!--====== FEATURES PART START ======-->
<div class="features-area pt-120 pb-120">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="features-item">
          <strong>{{'our_strength'|translate}}</strong>
          <h2 class="title">{{'create'|translate}} </h2>
          <span>{{'that'|translate}}</span>
          <p class="text-justify">{{'the_objective'|translate}}</p>
          <ul>
            <!--<li><a class="main-btn wow fadeInUp" routerLink='/about' data-wow-duration=".5s" data-wow-delay=".4s">Aprenda más</a></li>
            <li><a class="main-btn main-btn-2 wow fadeInUp" href="#" data-wow-duration="1s" data-wow-delay=".6s"> <i class="fal fa-video"></i>Aprenda más</a></li>-->
          </ul>
          <img class="d-none d-lg-block" src="assets/images/features-thumb.jpg" alt="">
          <!--nuevo-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-features mt-0" style="padding-top: 0px;">
          <!--<img src="assets/images/qlik.png" alt="">-->
          <h4 class="title">{{'data'|translate}}</h4>
          <p class="text-justify">{{'tools'|translate}}</p>
          <a routerLink='/analytics'><i class="fal fa-angle-right"></i> {{'know_more'|translate}}</a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-features mt-0" style="padding-top: 0px;">
          <!--<img src="assets/images/bpm.png" alt="">-->
          <h4 class="title">Big Data</h4>
          <p class="text-justify">{{'we_enhance'|translate}}</p>
          <a routerLink='/bigdata'><i class="fal fa-angle-right"></i> {{'know_more'|translate}}</a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-features mt-0" style="padding-top: 0px;">
          <!--<img src="assets/images/meeting.png" alt="">-->
          <h4 class="title">Machine Learning</h4>
          <p class="text-justify">{{'the_basic'|translate}}</p>
          <a routerLink='/machinelearning'><i class="fal fa-angle-right"></i> {{'know_more'|translate}}</a>
        </div>
      </div>
      <!--<div class="col-lg-4 col-md-6">
        <div class="features-sub mt-30">
          <h5 class="title">Suscríbase ahora</h5>
          <p>Many aspects of computing and technology and the term is more recognizable than before.</p>
          <form action="#">
            <div class="input-box">
              <input type="text" placeholder="Introduzca su correo electrónico....">
              <button class="main-btn">Suscríbase ahora</button>
            </div>
          </form>
        </div>
      </div>-->
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-features mt-0" style="padding-top: 0px;">
          <!--<img src="assets/images/coding.png" alt="">-->
          <h4 class="title">{{'a_i'|translate}}</h4>
          <p class="text-justify">{{'we_are'|translate}}</p>
          <a routerLink='/inteligencia'><i class="fal fa-angle-right"></i> {{'know_more'|translate}}</a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-features mt-0" style="padding-top: 0px;">
          <!--<img src="assets/images/services-1.png" alt="">-->
          <h4 class="title">{{'data_science'|translate}}</h4>
          <p class="text-justify">{{'the'|translate}}</p>
          <a routerLink='/datascience'><i class="fal fa-angle-right"></i> {{'know_more'|translate}}</a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-features mt-30">
          <!--<img src="assets/images/services-2.png" alt="">-->
          <h4 class="title">{{'software_production'|translate}}</h4>
          <p class="text-justify">{{'we_have'|translate}}</p>
          <a routerLink='/pwa'><i class="fal fa-angle-right"></i> {{'know_more'|translate}}</a>
        </div>
      </div>
      <!--<div class="col-lg-4 col-md-6">
        <div class="features-sub mt-5">
          <h5 class="title">Suscríbase ahora</h5>
          <p>Recibirá nuestras promociones, noticias de informática y tecnología.</p>
          <form action="#">
            <div class="input-box">
              <input type="text" placeholder="Introduzca su correo electrónico....">
              <button class="main-btn">Suscríbase ahora</button>
            </div>
          </form>
        </div>
      </div>-->
    </div>
  </div>
</div>
<!--====== FEATURES PART ENDS ======-->
<!--====== CHOOSE PART START ======-->
<div class="choose-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title">
          <span>{{'about'|translate}}</span>
          <h3 class="title">{{'why'|translate}}</h3>
        </div>
        <div class="choose-cat">
          <div class="choose-cat-item mt-40 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".4s">
            <h4 class="title"></h4>
            <p>{{'we_are_committed'|translate}}</p>
            <i class="fal fa-check"></i>
          </div>
          <div class="choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".6s">
            <h4 class="title"></h4>
            <p>{{'we_have_the'|translate}}</p>
            <i class="fal fa-check"></i>
          </div>
          <div class="choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
            <h4 class="title"></h4>
            <p>{{'we_are_successfully'|translate}}</p>
            <i class="fal fa-check"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <img class="d-none d-lg-block" src="assets/images/elegirnos.jpg" alt="">
        <!--<div class="choose-thumb-area mt-30">
          <div class="choose-thumb-1">
            <img class="item-1" src="assets/images/choose-thumb-1.jpg" alt="">
          </div>
          <div class="choose-thumb-2">
            <img class="item-2" src="assets/images/choose-thumb-2.jpg" alt="">
          </div>
        </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="choose-video-thumb mt-115">
          <video src="assets/video/DATAANALYTICS.mp4" class="col-md-12" controls muted loop></video>
          <!--<img src="assets/images/choose-video-thumb.jpg" alt="">
          <a href="#"><i class="fal fa-play"></i></a>-->
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== CHOOSE PART ENDS ======-->
<!--====== PORTFOLIO 3 PART START ======-->
<div class="portfolio-3-area pt-115 pb-120">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title text-center">
          <span>Portafolio</span>
          <h2 class="title">{{'we_have_done'|translate}}</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
        <div class="single-portfolio mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio-thumb-1.jpg" alt="">
          </div>
          <div class="portfolio-content">
            <span>Intelligent Center</span>
            <a routerLink='/case-detail'>
              <h4 class="title">{{'integrated'|translate}}</h4>
            </a>
            <p class="text-justify">{{'iot'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".4s">
        <div class="single-portfolio mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio-thumb-2.jpg" alt="">
          </div>
          <div class="portfolio-content">
            <span>{{'software_production'|translate}}</span>
            <a routerLink='/case-detail'>
              <h4 class="title">{{'industrial'|translate}}</h4>
            </a>
            <p class="text-justify">{{'solve'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.4s" data-wow-delay=".6s">
        <div class="single-portfolio mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio-thumb-3.jpg" alt="">
          </div>
          <div class="portfolio-content">
            <span>{{'software_production'|translate}}</span>
            <a routerLink='/case-detail'>
              <h4 class="title">{{'profitability'|translate}}</h4>
            </a>
            <p class="text-justify">{{'tool_that'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".8s">
        <div class="single-portfolio mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio-thumb-4.jpg" alt="">
          </div>
          <div class="portfolio-content">
            <span>{{'data_streaming'|translate}}</span>
            <a routerLink='/case-detail'>
              <h4 class="title">{{'lab'|translate}}</h4>
            </a>
            <p class="text-justify">{{'tool'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="1.8s" data-wow-delay="1s">
        <div class="single-portfolio mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio-thumb-5.jpg" alt="">
          </div>
          <div class="portfolio-content">
            <span>Supply Chain- Logistica</span>
            <a routerLink='/case-detail'>
              <h4 class="title">{{'order'|translate}}</h4>
            </a>
            <p class="text-justify">{{'management'|translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.2s">
        <div class="single-portfolio mt-30">
          <div class="portfolio-thumb">
            <img src="assets/images/portfolio-thumb-6.jpg" alt="">
          </div>
          <div class="portfolio-content">
            <span>Data Analytics</span>
            <a routerLink='/case-detail'>
              <h4 class="title">{{'business'|translate}}</h4>
            </a>
            <p class="text-justify">{{'bi'|translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== PORTFOLIO 3 PART ENDS ======-->
<!--====== WHO WE ARE PART START ======-->
<div class="who-we-are-area pt-20 pb-20">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0s">
        <div class="section-title">
          <span>{{'testimonials'|translate}}</span>
          <h2 class="title" style="text-transform: none;">{{'our_customers_say_so'|translate}}</h2>
        </div> <!-- section title -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 wow slideInUp" data-wow-duration=".5s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <img alt="" src="assets/images/duragas.png" height="100px">
          <h5 class="title">Fernando Bajaña M.</h5>
          <h6>Duragas – Abastible</h6>
          <p>{{'development'|translate}}</p>

        </div> <!-- what we do item -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="1s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <img alt="" src="assets/images/unicol.png" height="100px">
          <h5 class="title">Ing. Carlos Lagos U.</h5>
          <h6>Gerente de TI <br> Unicol S.A.</h6>
          <p>{{'thanks'|translate}}</p>

        </div> <!-- what we do item -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="1.5s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <img alt="" src="assets/images/acc.png" height="100px">
          <h5 class="title">Alvaro Rodriguez M.</h5>
          <h6>Director de Inteligencia de Negocios <br> ACC</h6>
          <p>{{'qlik'|translate}}</p>

        </div> <!-- what we do item -->
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 wow slideInUp" data-wow-duration="2s" data-wow-delay="0s">
        <div class="what-we-do-item text-center mt-30">
          <img alt="" src="assets/images/hivimar.png" height="100px">
          <h5 class="title">Fabrizzio Santos G.</h5>
          <h6>Coordinador de E-Business<br> HIVIMAR</h6>
          <p>{{'qliksense'|translate}}</p>

        </div> <!-- what we do item -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
  <div class="what-we-are-shape-1">
    <img src="assets/images/what-we-are-shape-1.png" alt="shape">
  </div>
  <div class="what-we-are-shape-2">
    <img src="assets/images/what-we-are-shape-2.png" alt="shape">
  </div>
</div>
<!--====== WHO WE ARE PART ENDS ======-->
<!--====== COUNTER PART START ======-->
<div class="counter-area bg_cover pt-80 pb-115" style="background-image: url(assets/images/video-bg.jpg);">
  <div class="container">

    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">329</span>+</h3>
          <p>{{'fulfilled'|translate}}</p>
          <i class="fal fa-box"></i>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">24</span>+</h3>
          <p>{{'our_worldwide'|translate}}</p>
          <i class="fal fa-users"></i>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">12</span>+</h3>
          <p>{{'we_are_global'|translate}}</p>
          <i class="fal fa-globe"></i>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="counter-item mt-30">
          <h3 class="title"><span class="counter">7</span>+</h3>
          <p>{{'awards'|translate}}</p>
          <i class="fal fa-award"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<!--====== COUNTER PART ENDS ======-->
<!--====== FAQ PART START ======-->
<div class="faq-area faq-area-3 pt-115">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-2 order-lg-1 d-none d-sm-block">
        <div class="faq-thumb">
          <div class="faq-thumb-1 text-right">
            <img src="assets/images/faq-thumb-1.jpg" alt="">
          </div>
          <div class="faq-thumb-2">
            <img src="assets/images/faq-thumb-2.jpg" alt="">
          </div>
        </div> <!-- faq thumb -->
      </div>
      <div class="col-lg-6 order-1 order-lg-2">
        <div class="section-title text-left">
          <span>{{'about_us'|translate}}</span>
          <h2 class="title">{{'our_strategic'|translate}}</h2>
        </div> <!-- section title -->
        <div class="faq-accordion">
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{'our_company'|translate}}
                </a>
              </div>
              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                  <p class="text-justify">{{'we'|translate}} </p>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  {{'mission'|translate}}
                </a>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <p class="text-justify">{{'help'|translate}} </p>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  {{'vision'|translate}}
                </a>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">
                  <p class="text-justify">{{'to'|translate}} </p>
                </div>
              </div>
            </div>
            <!--<div class="card">
              <div class="card-header" id="headingFour">
                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Our knowledge of computers will help us
                </a>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                <div class="card-body">
                  <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                </div>
              </div>
            </div> faq accordion -->
          </div>
        </div>
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>
<!--====== FAQ PART ENDS ======-->
<!--====== GET IN TOUCH PART START ======-->
<!--<div class="get-in-touch-area" id="estimacion">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title text-left">
          <span>Cotice con nosotros sin ningún compromiso</span>
          <h2 class="title">Estimación CERO COSTO para sus proyectos.</h2>
        </div>
        <div class="form-area">
          <form action="#">
            <div class="input-box pt-45 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">
              <input type="text" placeholder="Ingrese su nombre">
            </div>
            <div class="input-box mt-20 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".4s">
              <input type="email" placeholder="Ingrese su correo">
            </div>
            <div class="input-box mt-20 wow fadeInLeft" data-wow-duration="1.4s" data-wow-delay=".6s">
              <textarea name="#" id="#" cols="30" rows="10" placeholder="Ingrese su mensaje"></textarea>
            </div>
            <div class="input-box mt-20">
              <button class="main-btn wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".2s" type="submit">Inicie Ahora</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="get-map d-none d-lg-block">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3986.980022057889!2d-79.92013255013397!3d-2.161303898416852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d6d8814c1292d%3A0x5367f9a1942ab55e!2sRoyalticgroup!5e0!3m2!1ses!2sec!4v1598648664666!5m2!1ses!2sec" width="600" height="450" style="border:0;" allowfullscreen=""></iframe>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--====== GET IN TOUCH PART ENDS ======-->
<!--====== Blog START ======-->
<section class="latest-news section-gap">
  <div class="container">
    <div class="section-title text-center mb-30 wow fadeInUp" data-wow-delay="0.3s"
         style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
      <span class="title-tag">Data Driven Innovation</span>
      <h2 class="title"> {{'our_content'|translate}} </h2>
    </div>
    <div class="row justify-content-center">
      <!---->
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-delay="0.7s"
           style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
        <div class="latest-news-box mt-30">
          <div class="post-thumb">
            <img src="assets/images/blog-grid-1.jpg" alt="How Performance Visiblety With GitLab CI And Hood"></div>
          <div class="post-content">
            <ul class="post-meta">
              <!---->
              <li><a href="/demo/angular/omnivus/blog/author/1">Por Jimmy Murillo,</a></li>
              <li><a href="/demo/angular/omnivus/blog-details/1">1 Ago 2022</a></li>
            </ul>
            <h4 class="title"><a href="/demo/angular/omnivus/blog-details/1">Revolución tecnológica potenciada por chatbots</a></h4><a class="read-more-btn"
                                                 href="/aprovechar-el-momento">{{'read_more'|translate}}
            <i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-delay="0.7s"
           style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
        <div class="latest-news-box mt-30">
          <div class="post-thumb">
            <img src="assets/images/blog-grid-2.jpg" alt="Inspired Design Decisions With Max Huber Turne"></div>
          <div class="post-content">
            <ul class="post-meta">
              <!---->
              <li><a href="/demo/angular/omnivus/blog/author/2">Por Jimmy Murillo,</a></li>
              <li><a href="/demo/angular/omnivus/blog-details/2">26 Jul 2022</a></li>
            </ul>
            <h4 class="title"><a href="/demo/angular/omnivus/blog-details/2">¿Qué tan importante es la transformación digital en tu empresa y por qué?</a></h4><a class="read-more-btn"
                                                        href="/inteligencia-artificial">{{'read_more'|translate}}
            <i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp" data-wow-delay="0.7s"
           style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
        <div class="latest-news-box mt-30">
          <div class="post-thumb">
            <img src="assets/images/blog-grid-3.jpg" alt="Mirage JS Deep Dive Understanding"></div>
          <div class="post-content">
            <ul class="post-meta">
              <!---->
              <li><a href="/demo/angular/omnivus/blog/author/3">By Yellow D. Ballow,</a></li>
              <li><a href="/demo/angular/omnivus/blog-details/3">20 Jan 2021</a></li>
            </ul>
            <h4 class="title"><a href="/demo/angular/omnivus/blog-details/3">Mirage JS Deep Dive
              Understanding</a></h4><a class="read-more-btn"
                                       href="/demo/angular/omnivus/blog-details/3">{{'read_more'|translate}}
            <i class="fal fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Blog ENDS ======-->
<!--====== Contact START ======-->
<section class="call-to-action-four section-gap" style="background-image: url(assets/images/cta-bg-4.jpg);">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-lg-5">
        <div class="cta-content"><span class="title-tag">{{'locations'|translate}}</span>
          <h3 class="title">{{'where_we_are'|translate}}</h3>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="cta-btns">
          <div class="cta-btn author d-flex align-items-center">
            <div class="photo"><img src="assets/images/Ecuador.png" alt="Rosalina Devid"></div>
            <div class="desc">
              <p><a class="fal fa-map-marker-alt"></a> Guayaquil, Ecuador</p><b class="text-white"><a class="fal fa-phone-square"></a> 042938500 </b><p><u class="fal fa-envelope"></u> info@royalticgroup.com</p><i class="fal fa-globe-americas"></i>
            </div>
          </div>
          <!--<div class="cta-btn cta-btn-2 author d-flex align-items-center">
            <div class="photo"><img src="assets/images/Estados-Unidos.png" alt="Rosalina Devid"></div>
            <div class="desc">
              <p style="color: #0c59db"><a class="fal fa-map-marker-alt"></a> Miami, Florida, USA</p><b style="color: #0c59db"><a class="fal fa-phone-square"></a> 897 876 76 56 4</b><p style="color: #0c59db"><u class="fal fa-envelope"></u> info@royalticgroup.com</p><i class="fal fa-globe-americas"></i>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</section>
<!--====== Contact ENDS ======-->
<app-footer4></app-footer4>
