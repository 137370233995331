<app-header4></app-header4>
<!--====== PAGE TITLE PART START ======-->

<div class="page-title-area" style="background-image: url(assets/Pancarta/DataScience.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Ciencia de datos</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Inicio </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Ciencia de datos</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES ITEM PART START ======-->
<div class="services-item-area pt-105 pb-0">
  <div class="container">
    <!----><div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Ciencia de datos</h2>
          <p>Data Science o ciencia de datos es la aplicación empresarial de machine learning, inteligencia artificial (I.A.) y otros campos como la estadística, las matemáticas y la visualización. La ciencia de los datos utiliza ciertos métodos computacionales especializados para descubrir estructuras significativas y útiles dentro de un conjunto de datos.</p>
        </div>
      </div>
    </div>
  </div> <!-- container -->
</div>

<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-0 pb-1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-standard">
          <div class="single-blog-standard mt-5">
            <div class="blog-dteails-content blog-border">
              <div class="blog-details-top">
                <h2 class="title">Extrayendo patrones significativos</h2>

                <p>La ciencia de datos realiza inferencias e iteraciones de distintas hipótesis permitiendo generar varios patrones a partir del conjunto de datos. La generalización debe ser válida, no sólo para el conjunto de datos utilizado para observar el patrón, sino también para nuevos datos no vistos. Al final se deberá encontrar conclusiones que sean potencialmente útiles para los usuarios.</p>

              </div>
              <div class="blog-details-bath">
                <!--<img src="assets/images/blog-details-thumb.jpg" alt="blog-details">-->
                <h4 class="title">Construyendo modelos representativos</h4>
                <p>En la ciencia de datos la construcción de un modelo es un proceso en el cual se busca mostrar información representativa a partir del conjunto de datos seleccionado. Un modelo puede utilizarse tanto para aplicaciones predictivas como explicativas.</p>
                <h4 class="title">Combinación de estadística, machine learning y computación</h4>
                <p>La ciencia de datos utiliza de técnicas computacionales de estadística, machine learning y base de datos. Todo esto ya que la ciencia de datos almacena, procesa y calcula grandes conjuntos de datos, y el uso de estas técnicas combinadas le permite obtener más información sobre los patrones y relaciones de datos de los Datasets con los que trabaja.</p>
                <h4 class="title">Algoritmos de aprendizaje</h4>
                <p>La aplicación de sofisticados algoritmos de aprendizaje para extraer patrones útiles de los datos diferencia a la ciencia de datos de las técnicas tradicionales de análisis de datos. Muchos de estos algoritmos se han desarrollado en las últimas décadas y forman parte de machine learning y la inteligencia artificial (I.A.). En función del problema, la ciencia de los datos se clasifica en tareas como la clasificación, el análisis de asociación, la agrupación y la regresión. Cada tarea de la ciencia de datos utiliza algoritmos de aprendizaje específicos, como los árboles de decisión, las redes neuronales, k-vecinos más próximo (k-NN) y el clustering de k-means, entre otros.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->

<!--====== CHOOSE PART START ======-->
<div class="choose-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="section-title">

          <h3 class="title">Campos asociados</h3>
        </div>
        <div class="choose-cat">
          <div class="row">
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration=".5s" data-wow-delay=".4s">
              <h4 class="title"></h4>
              <p>Estadística descriptiva: Se utilizan en la etapa de exploración del proceso de ciencia de datos. Información como cálculo de media, desviación estándar entre otras estadísticas descriptivas son esenciales para entender cualquier Dataset, su estructura y relaciones dentro del mismo.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".6s">
              <h4 class="title"></h4>
              <p>Visualización exploratoria: Forma parte de las etapas de pre y postprocesamiento de ciencia de datos. Permite encontrar patrones y relaciones en los datos y comprender grandes Datasets.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Corte dimensional: Con una estructura de base de datos bien definida, es fácil filtrar datos, que pueden ser hechos o medidas cuantitativas, en función de dimensiones. Por ejemplo, dimensiones puede ser por región, producto o categoría, mientras que la medida va a ser ingresos o cantidades. Con el corte dimensional se puede visualizar partes seleccionadas del Dataset.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Prueba de hipótesis: En general, la ciencia de los datos es un proceso en el que se generan y prueban muchas hipótesis sobre base de datos observacionales. En el análisis confirmatoria de datos, se recogen muestras de datos para comprobar si una hipótesis tiene evidencias para ser apoyada. Y como los algoritmos usados en ciencia de datos son iterativos las soluciones se pueden refinar en cada paso.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Ingeniería de datos: La ingeniería de datos ayuda a originar y preparar los algoritmos de aprendizaje de la ciencia de datos. En el proceso de la ingeniería de datos se obtiene, organiza, ensambla, almacena, distribuye, carga y transforma datos para analizar o usar de manera eficaz.</p>
              <i class="fal fa-check"></i>
            </div>
            <div class="col-lg-6 choose-cat-item mt-40 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay=".8s">
              <h4 class="title"></h4>
              <p>Inteligencia de negocios: Inteligencia de negocios o inteligencia empresarial ayuda a las organizaciones a consumir datos de forma eficaz. La inteligencia empresarial se especializa en la entrega segura de información a los roles adecuados y en la distribución de información a escala. El BI puede mantener y distribuir los resultados de la ciencia de datos.</p>
              <i class="fal fa-check"></i>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--====== CHOOSE PART ENDS ======-->

<div class="services-item-area pt-15 pb-10">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Clasificación de la ciencia de datos</h2>
          <p>.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="section-title text-center">
          <h2 class="title">Clasificación por tipo de ciencia de datos</h2>
          <p></p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/DataScienceSupervisada.jpg" alt="⦁">
          <div class="services-overlay">

            <h4 class="title" style="padding-left: 0px;">Ciencia de datos supervisada</h4>
            <p style="line-height: 17px;">La ciencia de datos supervisada o dirigida trata de inferir una función o relación basada en datos de entrenamiento etiquetados y utiliza esta función para asignar nuevos datos no etiquetados. Las técnicas supervisadas predicen el valor de las variables de salida basándose en un conjunto de variables de entrada.</p>

          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/DataScienceNOsupervidasa.jpg" alt="⦁	">
          <div class="services-overlay">
            <h4 class="title" style="padding-left: 0px;">Ciencia de datos no supervisada</h4>
            <p style="line-height: 17px;">La ciencia de datos no supervisada descubre patrones ocultos en los datos no etiquetados. El objetivo de esta clase de técnicas de ciencia de datos, es encontrar patrones en los datos basados en la relación entre los puntos de datos mismos.</p>

          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="section-title text-center">
          <h2 class="title">Clasificación por tarea de ciencia de datos</h2>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/Clasificacion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">Clasificación</h4>
            <p>Predice si un punto de datos pertenece a una de las clases predefinidas. La predicción se basará en el aprendizaje de un conjunto de datos conocido.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/Regresion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">Regresión</h4>
            <p>Predice la etiqueta numérica objetivo de un punto de datos. La predicción se basará en el aprendizaje de un conjunto de datos conocido.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/DeteccionAnomalias.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">Detección de anomalías</h4>
            <p>Predice si un punto de datos es una anomalía en comparación con otros puntos de datos del conjunto de datos.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/PrediccionSeriesTemp.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-hurricane"></i>
            <h4 class="title">Predicción de series temporales</h4>
            <p>Predice el valor de la variable objetivo para un marco temporal futuro basándose en valores históricos.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/Clustering.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-bezier-curve"></i>
            <h4 class="title">Clustering</h4>
            <p>Identificar los clusters naturales dentro del conjunto de datos basándose en las propiedades heredadas dentro del conjunto de datos.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/AnalisisAsociacion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">Análisis de asociación</h4>
            <p>Identificar la relación dentro de un conjunto de artículos basándose en los datos de las transacciones.</p>
          </div>
        </div> <!-- single services -->
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8">
        <div class="single-services-item mt-30">
          <img src="assets/images/MotoresRecmendacion.jpg" alt="">
          <div class="services-overlay">
            <i class="fal fa-umbrella"></i>
            <h4 class="title">Motores de recomendación</h4>
            <p>Predicen las preferencias de un artículo para un usuario.</p>
          </div>
        </div> <!-- single services -->
      </div>
    </div> <!-- row -->
  </div> <!-- container -->
</div>
<!--====== SERVICES ITEM PART ENDS ======-->
<!--====== BLOG STANDARD PART START ======-->
<div class="blog-standard-area pt-5 pb-1">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="blog-standard">
          <div class="single-blog-standard mt-10">
            <div class="blog-dteails-content blog-border">
              <div class="blog-details-top">
                <h2 class="title">Algoritmos de ciencia de datos</h2>

                <p>Un algoritmo es un procedimiento lógico para resolver un problema paso a paso. En la ciencia de los datos, es el esquema de cómo se resuelve un problema de datos concreto.  Muchos de los algoritmos de aprendizaje son recursivos, algunos incluso usan variables de salida. Va a depender del profesional de ciencia de datos el decidir que algoritmo o algoritmos usar basándose en el dataset, la estructura de los datos, entre otros factores a considerar. Y se sabe que cada etapa de ciencia de datos puede tener más de un algoritmo para poder sacar el mayor rendimiento.</p>
                <p class="mt-m2">La tarea de implementar un algoritmo toma bastante tiempo y se lo realiza con un programa de Desarrollo personalizado. Para poder ahorrar tiempo se recomienda aprovechar las herramientas de ciencia de datos o programas estadisticos como R, Phyton, SAS Enterprise Miner, entre otros, que ofrecen una biblioteca de algoritmos como funciones para su fácil implementación e interconexión.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--====== BLOG STANDARD PART ENDS ======-->
<!--====== TEAM DETAILS PART START ======-->
<!--<div class="team-details-area pt-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="team-details-thumb">
                    <img src="assets/images/team-details-thumb.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-details-content">
                    <h4 class="title">Rosalina D. Williamson</h4>
                    <span>Founder</span>
                    <p class="pb-15">Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <p>As a result, most of us need to know how to use computers. Our knowledge of computers will help us to tap into challenging career opportunities and enhance the quality of our lives. It is imperative that quality students be encouraged and motivated to study computers and become capable and responsible IT professionals. The education model needs to align itself with dynamically changing technology to meet the growing need for skilled IT manpower and deliver state-of-the-art, industry relevant and technology ready programs.​</p>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-behance"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--====== TEAM DETAILS PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<!--<div class="Progress-bar-area mt-90 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== PROGRESS BAR PART ENDS ======-->
<!--====== ABOT FAQ PART START ======-->
<!--<div class="about-faq-area pb-100 bg_cover" style="background-image: url(assets/images/faq-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="about-faq-content">
                    <span>FAQ</span>
                    <h2 class="title">Get Every answer from here.</h2>
                </div>
                <div class="faq-accordion-3 mt-30">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a class="" href="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a class="collapsed" href="" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <i class="fal fa-magic"></i>Technical content may have persuasive objectives
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                <div class="card-body">
                                    <p>Today, the term Information Technology (IT) has ballooned to encompass is real. Today, the term Information Technology (IT) has ballooned to encompass is real.Today, the term Information Technology (IT) has ballooned to encompass is real. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  container -->
<!--====== ABOT FAQ PART ENDS ======-->
<app-footer4></app-footer4>
