<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Our Plans</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Pricing</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES PLANS PART START ======-->
<div class="services-plans-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="services-title mb-50">
                    <span>plans</span>
                    <h2 class="title">Here is plans</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="plans-tab-btn d-flex justify-content-md-end justify-content-start">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Monthly</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Yearly</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Early Birds</b>
                            <h3 class="title">$ <span>9</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Team</b>
                            <h3 class="title">$ <span>32</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Personal</b>
                            <h3 class="title">$ <span>69</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Plutinum</b>
                            <h3 class="title">$ <span>99</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Early Birds</b>
                            <h3 class="title">$ <span>56</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Team</b>
                            <h3 class="title">$ <span>99</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Personal</b>
                            <h3 class="title">$ <span>133</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Plutinum</b>
                            <h3 class="title">$ <span>599</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== SERVICES PLANS PART ENDS ======-->
<!--====== SERVICES PLANS PART START ======-->
<div class="services-plans-area services-plans-dark-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="services-title mb-50">
                    <span>plans</span>
                    <h2 class="title">Here is plans</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="plans-tab-btn d-flex justify-content-md-end justify-content-start">
                    <ul class="nav nav-pills mb-3" id="pills-tab-2" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-monthly-tab" data-toggle="pill" href="#pills-monthly" role="tab" aria-controls="pills-monthly" aria-selected="true">Monthly</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-yearly-tab" data-toggle="pill" href="#pills-yearly" role="tab" aria-controls="pills-yearly" aria-selected="false">Yearly</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tab-content" id="pills-tabContent-2">
            <div class="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Early Birds</b>
                            <h3 class="title">$ <span>9</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b class="item-2">Team</b>
                            <h3 class="title">$ <span>32</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b class="item-3">Personal</b>
                            <h3 class="title">$ <span>69</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b class="item-4">Plutinum</b>
                            <h3 class="title">$ <span>99</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-yearly" role="tabpanel" aria-labelledby="pills-yearly-tab">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Early Birds</b>
                            <h3 class="title">$ <span>56</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b class="item-2">Team</b>
                            <h3 class="title">$ <span>99</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b class="item-3">Personal</b>
                            <h3 class="title">$ <span>133</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b class="item-4">Plutinum</b>
                            <h3 class="title">$ <span>599</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== SERVICES PLANS PART ENDS ======-->
<app-footer2></app-footer2>
