<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Shop Details</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Shop Details</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SHOP DETAILS PART START ======-->
<div class="shop-details-area pt-120 pb-115 gray-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="shop-product-area ml-30">
                    <div class="shop-active">
                        <div class="shop-product-item">
                            <img src="assets/images/shop-product.jpg" alt="">
                        </div>
                        <div class="shop-product-item">
                            <img src="assets/images/shop-product.jpg" alt="">
                        </div>
                        <div class="shop-product-item">
                            <img src="assets/images/shop-product.jpg" alt="">
                        </div>
                    </div>
                    <div class="shop-product-thumb mt-30">
                        <div class="row shop-thumb-active">
                            <div class="col-lg-4">
                                <img src="assets/images/shop-product-4.jpg" alt="">
                            </div>
                            <div class="col-lg-4">
                                <img src="assets/images/shop-product-1.jpg" alt="">
                            </div>
                            <div class="col-lg-4">
                                <img src="assets/images/shop-product-2.jpg" alt="">
                            </div>
                            <div class="col-lg-4">
                                <img src="assets/images/shop-product-3.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="shop-details-content ml-30">
                    <div class="review">
                        <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><span>23 Reviews</span></li>
                        </ul>
                        <span>Accesssories</span>
                        <h4 class="title">Audio System Multiroom 360</h4>
                    </div>
                    <div class="price">
                        <ul>
                            <li>$460</li>
                            <li>$689</li>
                        </ul>
                    </div>
                    <div class="list d-flex">
                        <ul class="list-1">
                            <li>Brands:</li>
                            <li>Product Code:</li>
                            <li>Rewards Points:</li>
                            <li>Stock:</li>
                        </ul>
                        <ul class="list-2">
                            <li>Limonda</li>
                            <li>Likaoli D12 </li>
                            <li>100</li>
                            <li>In Stock</li>
                        </ul>
                    </div>
                    <div class="overview">
                        <h4 class="title">Overview</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip. Excepteur sint occaecat cupida...</p>
                        <form action="#">
                            <div class="list-2 d-sm-flex d-block">
                                <div class="product-quantity d-flex" id="quantity">
                                    <button type="button" id="sub" class="sub">-</button>
                                    <input type="text" id="1" value="0" />
                                    <button type="button" id="add" class="add">+</button>
                                </div>
                                <ul>
                                    <li><a href="#"><i class="fas fa-heart"></i></a></li>
                                    <li><a href="#"><i class="fas fa-location-arrow"></i></a></li>
                                    <li><a href="#"><i class="fas fa-share-square"></i></a></li>
                                </ul>
                            </div>
                            <button class="main-btn">Add to cart</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="shop-tab mt-80 d-flex justify-content-center">
                    <img src="assets/images/shop-border.png" alt="">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Item review</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Descriptions</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Methods</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="shop-descriptions-area">
                            <h3 class="title">Technical Specifications</h3>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Brand</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Apple 18</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item HeightItem Width</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Millimeters 31.4</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Screen Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Centimeters 3 Inches</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item Weight</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>1.6 Kg </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Product Dimensions</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>21.9 x 31.4 x 1.8 cm </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item model number</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>MF841HN/A </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Brand</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Intel</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Type</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Core i5 </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Speed</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>2.9 GHz </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>RAM Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>8 GB</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Hard Drive Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>512 GB</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Hard Disk Technology</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Solid State </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list border-0 d-flex">
                                <ul class="shop-list-1">
                                    <li>Graphics Coprocessor</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Drive Intel Integrated Graphics</li>
                                </ul>
                            </div>
                            <h3 class="title">Product Descriptions</h3>
                            <p class="pb-20">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                            <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncove many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like) Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)..</p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="shop-descriptions-area">
                            <h3 class="title">Technical Specifications</h3>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Brand</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Apple 18</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item HeightItem Width</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Millimeters 31.4</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Screen Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Centimeters 3 Inches</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item Weight</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>1.6 Kg </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Product Dimensions</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>21.9 x 31.4 x 1.8 cm </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item model number</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>MF841HN/A </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Brand</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Intel</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Type</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Core i5 </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Speed</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>2.9 GHz </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>RAM Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>8 GB</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Hard Drive Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>512 GB</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Hard Disk Technology</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Solid State </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list border-0 d-flex">
                                <ul class="shop-list-1">
                                    <li>Graphics Coprocessor</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Drive Intel Integrated Graphics</li>
                                </ul>
                            </div>
                            <h3 class="title">Product Descriptions</h3>
                            <p class="pb-20">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                            <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncove many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like) Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)..</p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div class="shop-descriptions-area">
                            <h3 class="title">Technical Specifications</h3>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Brand</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Apple 18</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item HeightItem Width</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Millimeters 31.4</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Screen Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Centimeters 3 Inches</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item Weight</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>1.6 Kg </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Product Dimensions</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>21.9 x 31.4 x 1.8 cm </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Item model number</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>MF841HN/A </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Brand</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Intel</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Type</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Core i5 </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Processor Speed</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>2.9 GHz </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>RAM Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>8 GB</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Hard Drive Size</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>512 GB</li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list d-flex">
                                <ul class="shop-list-1">
                                    <li>Hard Disk Technology</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Solid State </li>
                                </ul>
                            </div>
                            <div class="shop-descriptions-list border-0 d-flex">
                                <ul class="shop-list-1">
                                    <li>Graphics Coprocessor</li>
                                </ul>
                                <ul class="shop-list-2">
                                    <li>Drive Intel Integrated Graphics</li>
                                </ul>
                            </div>
                            <h3 class="title">Product Descriptions</h3>
                            <p class="pb-20">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                            <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncove many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like) Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)..</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== SHOP DETAILS PART ENDS ======-->
<app-footer2></app-footer2>
