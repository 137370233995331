<div class="shop-side-bar">
    <div class="shop-sidebar-area mt-30 white-bg">
        <div class="sidebar-title text-center">
            <h5 class="title">Category</h5>
        </div>
        <div class="sidebar-list">
            <ul>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>Accessories</a></li>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>Clothing</a></li>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>Decor</a></li>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>Hoodies</a></li>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>Music</a></li>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>T-shirts</a></li>
                <li><a routerLink='/shop-detail'><i class="fal fa-angle-right"></i>Uncategorized</a></li>
            </ul>
        </div>
    </div>
    <div class="shop-sidebar-area mt-30 white-bg">
        <div class="sidebar-title text-center">
            <h5 class="title">Keyword</h5>
        </div>
        <div class="sidebar-tag">
            <ul>
                <li><a routerLink='/shop-detail'>Cleaning</a></li>
                <li><a routerLink='/shop-detail'>Business</a></li>
                <li><a routerLink='/shop-detail'>IT</a></li>
            </ul>
            <ul>
                <li><a routerLink='/shop-detail'>Car</a></li>
                <li><a routerLink='/shop-detail'>Agency</a></li>
                <li><a routerLink='/shop-detail'>Laptop</a></li>
            </ul>
            <ul>
                <li><a routerLink='/shop-detail'>Washing</a></li>
                <li><a routerLink='/shop-detail'>Paper</a></li>
                <li><a routerLink='/shop-detail'>Pen</a></li>
            </ul>
            <ul>
                <li><a routerLink='/shop-detail'>Tea</a></li>
                <li><a routerLink='/shop-detail'>Coffee</a></li>
                <li><a routerLink='/shop-detail'>Keyboard</a></li>
            </ul>
        </div>
    </div>
    <div class="shop-sidebar-area mt-30 white-bg">
        <div class="sidebar-title text-center">
            <h5 class="title">Subscribe</h5>
        </div>
        <div class="sidebar-subscribe">
            <p>Lorem ipsum dolor sit asecte is tur adipisicing elit.</p>
            <form action="#">
                <div class="input-box">
                    <input type="text" placeholder="Enter email">
                    <button class="main-btn">Subscribe Now</button>
                </div>
            </form>
        </div>
    </div>
    <div class="sidebar-add mt-30">
        <img src="assets/images/sidebar-ad.jpg" alt="">
        <span>Add Banner</span>
    </div>
</div>
