<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">News Grid</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">News</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== BLOG GRID PART START ======-->
<div class="blog-grid-area pt-90 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-1.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-2.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-3.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-4.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-5.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-6.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-7.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-8.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-9.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-10.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-11.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-8">
                <div class="blog-grid-item mt-30">
                    <img src="assets/images/blog-grid-12.jpg" alt="">
                    <div class="blog-grid-overlay">
                        <span>Design/SEO</span>
                        <a routerLink='/blog-detail'>
                            <h5 class="title">A series of iOS 7 inspired vector icons.</h5>
                        </a>
                        <ul>
                            <li><i class="fal fa-calendar-alt"></i> <span>23rd January 2019</span></li>
                            <li><i class="fal fa-heart"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <nav class="mt-40" aria-label="Page navigation example">
                    <ul class="pagination pagination-2 justify-content-center">
                        <li class="page-item">
                            <a class="page-link d-none d-sm-inline-block" href="#" tabindex="-1" aria-disabled="true"><i class="fal fa-arrow-left"></i></a>
                        </li>
                        <li class="page-item"><a class="page-link active" href="#">01</a></li>
                        <li class="page-item"><a class="page-link" href="#">02</a></li>
                        <li class="page-item"><a class="page-link" href="#">03</a></li>
                        <li class="page-item"><a class="page-link" href="#">...</a></li>
                        <li class="page-item"><a class="page-link" href="#">10</a></li>
                        <li class="page-item">
                            <a class="page-link d-none d-sm-inline-block" href="#"><i class="fal fa-arrow-right"></i></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
<!--====== BLOG GRID PART ENDS ======-->
<app-footer2></app-footer2>
