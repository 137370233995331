import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogdetails',
  templateUrl: './blogdetails02.component.html',
  styleUrls: ['./blogdetails02.component.css']
})
export class Blogdetails02Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
