<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Services Details</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Services Details</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== SERVICES OPTIMIZATION PART START ======-->
<div class="services-optimization-area pt-80 pb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 order-lg-1 order-2">
                <div class="services-optimization-thumb mt-40">
                    <img src="assets/images/services-op-1.jpg" alt="">
                </div>
                <div class="services-optimization-thumb-2">
                    <img src="assets/images/services-op-2.jpg" alt="">
                </div>
            </div>
            <div class="col-lg-6 order-lg-2 order-1">
                <div class="services-optimization-content mt-40">
                    <span>Search Enging Optimization</span>
                    <h3 class="title">Nothing to do anything.</h3>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology? Consider the number of people who influence a sale, the length of the decision-making cycle, the competing interests of the people who purchase, implement, manage, and use the technology. It’s a lot meaningful content here.</p>
                    <div class="action-support-item">
                        <p>Make A Call</p>
                        <span>897 876 76 56 4</span>
                        <i class="fal fa-phone"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== SERVICES OPTIMIZATION PART ENDS ======-->
<!--====== INTRO VIDEO PART START ======-->
<div class="intro-video-area  bg_cover" style="background-image: url(assets/images/intro-video-bg.jpg);">
    <div class="intro-overlay">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="intro-video-content  mt-30">
                        <span class="bar">Intro Video</span>
                        <h2 class="title">How we growth our business.</h2>
                        <p class="text-1">The introduction of cloud and mobile technologies into enterprise software.</p>
                        <p class="text-2">hether you are building an enterprise web portal or a state-of-the-art website, you always need the right modern tools. Well-built and maintained PHP frameworks provide those tools in abundance, allowing maintained PHP frameworks provide those tools in abundance, allowing developers to save time, re-use code, and streamline the back end. As software development tools continuously.</p>
                        <a class="main-btn" routerLink="/about">Learn More</a>
                    </div> <!-- intro video content -->
                </div>
                <div class="col-lg-6">
                    <div class="intro-thumb mt-30">
                        <img src="assets/images/intro-thumb.jpg" alt="">
                        <a class="video-popup" routerLink="/"><i class="fas fa-play"></i></a>
                    </div> <!-- intro thumb -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </div>
</div>
<!--====== INTRO VIDEO PART ENDS ======-->
<!--====== SERVICES PLANS PART START ======-->
<div class="services-plans-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="services-title mb-50">
                    <span>plans</span>
                    <h2 class="title">Here is plans</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="plans-tab-btn d-flex justify-content-md-end justify-content-start">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Monthly</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Yearly</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Early Birds</b>
                            <h3 class="title">$ <span>9</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Team</b>
                            <h3 class="title">$ <span>32</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Personal</b>
                            <h3 class="title">$ <span>69</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Plutinum</b>
                            <h3 class="title">$ <span>99</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Early Birds</b>
                            <h3 class="title">$ <span>56</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Team</b>
                            <h3 class="title">$ <span>99</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Personal</b>
                            <h3 class="title">$ <span>133</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-8 mt-30">
                        <div class="plans-item bg_cover text-center" style="background-image: url(assets/images/plans-thumb.jpg);">
                            <b>Plutinum</b>
                            <h3 class="title">$ <span>599</span></h3>
                            <span>Per Month</span>
                            <p>The functional goal of technical content is to help people use a product successfully.</p>
                            <a class="main-btn main-btn-2" href="#">Purchase Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== SERVICES PLANS PART ENDS ======-->
<!--====== NEWS PART START ======-->
<div class="news-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-9">
                <div class="section-title-2 text-center pl-25 pr-25">
                    <h2 class="title">News Feeds</h2>
                    <p>Does any industry face a more complex audience journey and marketing sales process than B2B technology.</p>
                </div> <!-- section title 2 -->
            </div>
        </div> <!-- row -->
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-1.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink="/blog-detail">A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-2.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink="/blog-detail">A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9">
                <div class="single-news mt-30">
                    <img src="assets/images/news-3.jpg" alt="">
                    <div class="single-news-overlay">
                        <span>Design/SEO</span>
                        <h5 class="title"><a routerLink="/blog-detail">A series of iOS 7 inspired vector icons.</a></h5>
                        <a routerLink="/blog-detail"><i class="fal fa-long-arrow-right"></i></a>
                    </div>
                </div> <!-- single news -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== NEWS PART ENDS ======-->
<app-footer2></app-footer2>