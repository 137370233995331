import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { BlogsidebarComponent } from './components/layouts/blogsidebar/blogsidebar.component';
import { ShopsidebarComponent } from './components/layouts/shopsidebar/shopsidebar.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { Header2Component } from './components/layouts/header2/header2.component';
import { Header3Component } from './components/layouts/header3/header3.component';
import { Header4Component } from './components/layouts/header4/header4.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { Footer2Component } from './components/layouts/footer2/footer2.component';
import { Footer3Component } from './components/layouts/footer3/footer3.component';
import { Footer4Component } from './components/layouts/footer4/footer4.component';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
import { BloggridComponent } from './components/pages/bloggrid/bloggrid.component';
import { BlogstandardComponent } from './components/pages/blogstandard/blogstandard.component';
import { CasedetailsComponent } from './components/pages/casedetails/casedetails.component';
import { CasestudyComponent } from './components/pages/casestudy/casestudy.component';
import { ComingsoomComponent } from './components/pages/comingsoom/comingsoom.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeComponent } from './components/pages/home/home.component';
import { Home2Component } from './components/pages/home2/home2.component';
import { Home3Component } from './components/pages/home3/home3.component';
import { Home4Component } from './components/pages/home4/home4.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicedetailComponent } from './components/pages/servicedetail/servicedetail.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { ShopdetailComponent } from './components/pages/shopdetail/shopdetail.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamdetailComponent } from './components/pages/teamdetail/teamdetail.component';
import { AnalyticsComponent } from './components/pages/analytics/analytics.component';
import { AutomateComponent } from './components/pages/automate/automate.component';
import { DatascienceComponent } from './components/pages/datascience/datascience.component';
import { MachinelearningComponent } from './components/pages/machinelearning/machinelearning.component';
import { BigdataComponent } from './components/pages/bigdata/bigdata.component';
import { InteligenciaComponent } from './components/pages/inteligencia/inteligencia.component';
import { AuraquanticComponent } from './components/pages/auraquantic/auraquantic.component';

import { AlterixComponent } from './components/pages/alterix/alterix.component';
import { MbiComponent } from './components/pages/mbi/mbi.component';
import { QlikComponent } from './components/pages/qlik/qlik.component';
import { AplComponent } from './components/pages/apl/apl.component';
import { WebComponent } from './components/pages/web/web.component';
import { PwaComponent } from './components/pages/pwa/pwa.component';
import { IsoComponent } from './components/pages/iso/iso.component';
import { ChatbotsComponent } from './components/pages/chatbots/chatbots.component';

import { RpaComponent } from './components/pages/rpa/rpa.component';
import { IndustriaComponent } from './components/pages/industria/industria.component';
import { ExecutionsComponent } from './components/pages/executions/executions.component';
import { VerticaComponent } from './components/pages/vertica/vertica.component';
import { BpmComponent } from './components/pages/bpm/bpm.component';
import { Blogdetails01Component } from './components/pages/blogdetails01/blogdetails01.component';
import { Blogdetails02Component } from './components/pages/blogdetails02/blogdetails02.component';
import { Blogdetails03Component } from './components/pages/blogdetails03/blogdetails03.component';
import {NgxTypedJsModule} from 'ngx-typed-js';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { InnovelabsComponent } from './components/pages/innovelabs/innovelabs.component';

import { FormsModule } from '@angular/forms';
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha"; // Importa FormsModule

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    BlogsidebarComponent,
    ShopsidebarComponent,
    HeaderComponent,
    Header2Component,
    Header3Component,
    Header4Component,
    FooterComponent,
    Footer2Component,
    Footer3Component,
    Footer4Component,
    AboutComponent,
    BlogdetailsComponent,
    BloggridComponent,
    BlogstandardComponent,
    CasedetailsComponent,
    CasestudyComponent,
    ComingsoomComponent,
    ContactComponent,
    ErrorComponent,
    FaqComponent,
    HomeComponent,
    Home2Component,
    Home3Component,
    Home4Component,
    PricingComponent,
    ServicesComponent,
    ServicedetailComponent,
    ShopComponent,
    ShopdetailComponent,
    TeamComponent,
    TeamdetailComponent,
    AnalyticsComponent,
    DatascienceComponent,
    MachinelearningComponent,
    BigdataComponent,
    InteligenciaComponent,
    AuraquanticComponent,
    AutomateComponent,
    AlterixComponent,
    MbiComponent,
    QlikComponent,
    AplComponent,
    WebComponent,
    PwaComponent,
    IsoComponent,
    ChatbotsComponent,
    RpaComponent,
    IndustriaComponent,
    ExecutionsComponent,
    VerticaComponent,
    BpmComponent,
    Blogdetails01Component,
    Blogdetails02Component,
    Blogdetails03Component,
    InnovelabsComponent
  ],
  imports: [
    BrowserModule,
    NgxTypedJsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule, // Agrega FormsModule a la lista de módulos importados
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
