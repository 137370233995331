<app-header2></app-header2>
<!--====== PAGE TITLE PART START ======-->
<div class="page-title-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item text-center">
                    <h2 class="title">Team</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a routerLink='/'>Home </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Team</li>
                        </ol>
                    </nav>
                </div> <!-- page title -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PAGE TITLE PART ENDS ======-->
<!--====== LEADERSHIP PART START ======-->
<div class="leadership-area padding gray-bg pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-1.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Rosalina Devid</h5>
                        <span>Founder</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-2.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Kemina Dvlin</h5>
                        <span>CEO</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-3.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Yellow D. Ballow</h5>
                        <span>Marketer</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-4.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Rosalina Devid</h5>
                        <span>Founder</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-5.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Kemina Dvlin</h5>
                        <span>CEO</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-6.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Yellow D. Ballow</h5>
                        <span>Marketer</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-7.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Rosalina Devid</h5>
                        <span>Founder</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-8.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Kemina Dvlin</h5>
                        <span>CEO</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="leadership-item mt-50">
                    <img src="assets/images/leadership-9.jpg" alt="">
                    <div class="leadership-content">
                        <h5 class="title">Yellow D. Ballow</h5>
                        <span>Marketer</span>
                    </div>
                </div> <!-- leadership item -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== LEADERSHIP PART ENDS ======-->
<!--====== TEAM JOIN PART START ======-->
<div class="team-join">
    <div class="container">
        <div class="join-bg">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="team-join-title">
                        <span>Join With us</span>
                        <h3 class="title">Let’s make some noise with us.</h3>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="team-join-btn text-lg-right text-left">
                        <a class="main-btn" routerLink='/contact-us'>Apply Now</a>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="team-join-thumb">
                        <img src="assets/images/team-join-thumb.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--====== TEAM JOIN PART ENDS ======-->
<!--====== PROGRESS BAR PART START ======-->
<div class="Progress-bar-area mt-95 mb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle1">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-map"></i>
                        <h3 class="title"><span class="counter">280</span></h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle2">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-bar"></i>
                        <h3 class="title"><span class="counter">782</span></h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle3">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-chart-pie"></i>
                        <h3 class="title"><span class="counter">9</span>m</h3>
                    </div>
                </div> <!-- Progress bar item -->
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="Progress-bar-item mt-30" id="circle4">
                    <div class="Progress-bar-content text-center">
                        <i class="fal fa-user-friends"></i>
                        <h3 class="title"><span class="counter">100</span></h3>
                    </div>
                </div>
            </div> <!-- Progress bar item -->
        </div> <!-- row -->
    </div> <!-- container -->
</div>
<!--====== PROGRESS BAR PART ENDS ======-->
<app-footer2></app-footer2>
